.login-link {
  text-decoration: none;
  color: var(--primary);
  cursor: pointer;
  cursor: pointer;
}
.signup-link {
  text-decoration: none;
  color: var(--primary);
  float: left;
  cursor: pointer;
  font-weight: 500 !important;
}

.new-folder {
  height: 24px;
  width: 170px;
  margin-top: 0px;
  border-radius: 0px;
  color: #c59c37;
  font-family: var(--jen-font-sans-serif);
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
}

.Resend-otp {
  color: var(--primary);
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
.skip-link {
  text-decoration: none;
  color: var(--primary);
  float: right;
  margin-bottom: 20px;
  padding: 20px 0px 20px 80px;
  cursor: pointer;
}

.login-link:hover,
.skip-link:hover,
.Resend-otp:hover,
.signup-link:hover {
  text-decoration: none;
  color: #3cb371;
  cursor: pointer;
}
.login-link:hover,
.signup-link:hover,
.Resend-otp:hover,
.skip-link:hover {
  color: var(--primary);
}
