.counter {
  margin-bottom: 32px;
}
.counter p {
  margin-bottom: 0;
  color: var(--black-russian);
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
  display: block;
}
.counter p span {
  font-weight: 700;
}
.otp-field-wrap {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 22px;
  padding-bottom: 24px;
  padding: 22px 20px 30px;
}
.otp-field {
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  flex-direction: row;
  border-radius: 4px;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border: 1px solid var(--black-russian);
  color: var(--primary);
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-family: var(--jen-font-sans-serif);
}
.otp-field::-webkit-input-placeholder {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: var(--black-russian) !important;
  opacity: 1 !important;
}
.otp-field:-ms-input-placeholder {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: var(--black-russian) !important;
  opacity: 1 !important;
}
.otp-field::placeholder {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: var(--black-russian) !important;
  opacity: 1 !important;
}

.otp-field:focus,
.otp-field.filled {
  background: rgba(19, 191, 108, 0.08);
  border: 0;
  outline: none;
  color: var(--primary);
}

::-webkit-input-placeholder {
  font: 100px;
}

:-ms-input-placeholder {
  font: 100px;
}

::placeholder {
  font: 100px;
}

.filled {
  display: block;
}

@media (max-width: 676px) {
  .counter {
    margin-bottom: 40px;
  }
}
