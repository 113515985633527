.win-prizes {
  background-color: #ffffff;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.win-prizes h3 {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 24px;
}
.win-prizes .enroll-text {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 64px;
}
.win-prizes .ribbon {
  margin-bottom: 24px;
}
.prizes-block fieldset {
  border: 1px solid #9aa9c7;
}
.prizes-block fieldset legend {
  font-size: 20px;
}
.prizes-block .p-block:first-child {
  width: 40%;
}
.prizes-block .p-block:last-child {
  width: 60%;
}
.prize-image-block {
  flex: 1;
  min-height: 128px;
  min-width: 128px;
  /* max-height: 128px; */
  max-width: 156px;
  margin-bottom: 5px;
  display: inline-block;
  margin-top: 32px;
}
.prize-image-block img {
  max-width: 100%;
  max-height: 100%;
}
.win-prizes > .p-block {
  border: 1px solid #9aa9c7;
}
.win-prizes > .p-block .prize-image-block {
  max-width: inherit;
}
.empty-block {
  width: 128px;
  height: 128px;
  background-color: rgba(0, 0, 0, 0.5);
}
.card-block {
  flex: 1;
  margin: 0 8px;
}
.card-block h5 {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
}
.card-block span {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
}
.card-block p {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
}
fieldset {
  background: #ffffff;
  border: 1px solid #d8b091;
  border-radius: 8px;
  position: relative;
  text-align: center;
}
fieldset > legend {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  background: #ffffff;
  width: fit-content;
  margin: auto;
  margin-top: -30px;
  text-align: center;
  display: inline-block;
  padding: 0 15px;
}
@media (min-width: 1200px) and (max-width: 1439px) {
  fieldset > legend {
    font-size: 17px;
    padding: 0 10px;
  }
}
@media (max-width: 1024px) {
  .win-prizes {
    padding: 60px 30px 80px;
  }
}

@media (max-width: 767px) {
  .win-prizes {
    padding: 40px 24px;
    display: block;
  }
  .win-prizes .ribbon {
    margin-bottom: 40px;
  }
  .win-prizes .ribbon img {
    max-width: 100%;
  }
  .win-prizes .prizes-block {
    flex-direction: column;
  }
  .prize-image-block {
    margin-bottom: 14px;
  }
  .card-block:nth-of-type(4) {
    margin-bottom: 24px;
  }
  .card-block p {
    max-width: 200px;
  }
  .win-prizes .sign-up-button-new {
    margin-bottom: 0 !important;
    height: 56px;
    width: 100% !important;
  }
  .win-prizes .prizes-block fieldset {
    padding-bottom: 32px !important;
  }
  .win-prizes .prizes-block fieldset:first-child {
    margin-bottom: 40px !important;
  }
  .win-prizes .prizes-block fieldset > legend {
    font-size: 16px;
    line-height: 28px;
  }
  .win-prizes h3 {
    display: none;
  }
  .win-prizes .enroll-text {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .prizes-block .p-block:first-child {
    width: 83%;
  }
  .prizes-block .p-block:last-child {
    width: 83%;
  }
  .mobility-page .win-prizes .ribbon {
    margin-top: 24px;
    margin-bottom: 32px;
  }
}
