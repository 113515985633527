.hero-section {
  max-height: 576px;
  margin-bottom: 30px !important;
}
.hero-section .here-banner {
  width: 480px;
  max-width: 100%;
}
.hero-text-block {
  flex: 1;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #5e848d;
  min-width: 50%;
}
.hero-text-block h2 {
  font-family: var(--jen-font-title);
  font-size: 38px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: var(--white);
}
.hero-text-block p {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: var(--white);
}
.hero-text-block h4 {
  color: var(--white);
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
}
.hero-section .hero-text-block .offers-box .sign-up-button-new {
  margin: 20px 0 0 !important;
  letter-spacing: 0.9px;
  min-height: 56px;
  padding: 12px 24px;
  color: #14142b;
}
.hero-section .vid-wrapper img {
  object-fit: cover;
  min-height: 576px;
  max-height: 576px;
}

@media (max-width: 1024px) {
  .hero-text-block {
    padding: 40px 24px;
  }
}

@media (max-width: 767px) {
  .hero-section {
    flex-direction: column;
  }
  .hero-text-block {
    padding: 32px;
  }
  .hero-text-block p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
  }
  .hero-section img {
    display: none;
  }
}
