.lead-text-image-block {
  display: flex;
  background-color: var(--white);
  padding: 104px 80px;
}
.lead-text-image-block .lead-text-image-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lead-text-image-block > div {
  width: 50%;
}
.lead-text-image-block img {
  object-fit: cover;
}
.lead-text-image-block h3 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.lead-text-image-block .description {
  padding-right: 40px;
}

.lead-text-image-block .description h4 {
  font-size: 20px;
  line-height: 32px;
  margin: 20px 0;
}

.lead-text-image-block .offers-box {
  margin-top: 40px;
}

.lead-text-image-block .lead-text-image-div h3 {
  margin-bottom: 20px;
}

.lead-text-image-block .offers-box fieldset .text-wrapper {
  letter-spacing: -0.3px;
  font-size: 16px;
}

@media screen and (max-width: 1024px) {
  .lead-text-image-block {
    padding: 48px 24px 10px;
    flex-direction: column;
  }
  .lead-text-image-block .offers-box {
    justify-content: start;
    margin-bottom: 20px;
  }
  .lead-text-image-block .lead-text-image-div,
  .lead-text-image-block > div {
    width: 100%;
  }
  .lead-text-image-block .offers-box .sign-up-button-new {
    margin: 0 0 0 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .lead-text-image-block {
    flex-direction: column;
  }

  .lead-text-image-block .lead-text-image-div,
  .lead-text-image-block > div {
    width: 100%;
  }

  .lead-text-image-block .offers-box .sign-up-button-new {
    margin: 20px 0 0 0 !important;
  }
}
