.w_75 {
  width: 75%;
}
.assessment .assessment-block {
  flex: 0 0 calc(33.333% - 40px);
  width: calc(33.333% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  cursor: pointer;
}

.block-lists .selfAssessment-block,
.selfAssessment-block {
  width: 100%;
  flex: 0 0 100%;
  margin-right: 0px;
  cursor: pointer;
  min-height: 360px;
  background-color: #fff;
}
.s_a_r {
  min-height: 180px;
}
.self-asess-title {
  font-family: var(--jen-font-title);
  font-size: 32px;
  line-height: 48px;
  color: var(--black-russian);
  margin-top: 40px;
  margin-bottom: 40px;
  width: 50%;
}
@media (max-width: 1024px) {
  .assessment .assessment-block {
    margin-left: 16px;
    margin-right: 16px;
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
  }
  .self-asess-title {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 32px;
  }
  .w_75 {
    width: 100%;
  }

  .selfAssessment-block .video-box .content .best2 p{
    font-size: 17px;
  }
}
@media (max-width: 992px) {
  .self-asess-title {
    padding-left: 30px;
  }
  .assessment {
    padding-left: 16px;
    padding-right: 16px;
  }
  .slef-asess-title {
    padding-left: 16px;
    padding-right: 16px;
  }

  .assessment .assessment-block {
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
  }
}
@media (max-width: 767px) {
  .assessment .assessment-block {
    flex: 0 0 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .self-asess-title {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .mxp {
    padding-left: 32px;
    padding-right: 32px;
  }
  .s_a_r .day_plan {
    padding-left: 0;
  }
}

@media (max-width: 360px) {
  .assessment {
    padding-left: 0px;
    padding-right: 0px;
  }
  .self-asess-title {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 14px;
    line-height: 20px;
  }
}
