.survey-gif {
  margin-bottom: 10px;
  border-radius: 10px;
  width: 220px;
  height: 159px !important;
  margin: auto;
  display: block;
}

.survey-modal .modal-content:before {
  content: '';
  background-color: #ede8dd;
  background-image: url(../../../../static/images/WaiListBack.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 48%;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0px 0px;
  z-index: -1;
}
.survey-modal .modal-header button {
  position: absolute;
  background-color: transparent;
  padding: 0;
  border: 0;
  right: 16px;
  top: 14px;
  z-index: 2;
  cursor: pointer;
}
.survey-modal .modal-header button span:last-child {
  display: none;
}
.survey-modal .modal-header button span:first-child {
  display: block;
  font-size: 40px;
  line-height: 26px;
  vertical-align: top;
  color: #000;
  font-family: var(--jen-font-sans-serif);
}
.survey-modal .modal-body {
  padding: 1.8rem 4rem !important;
}
.survey-modal .modal-body h3 {
  font-family: var(--jen-font-title);
  font-size: 24px;
}
.survey-modal .modal-body h2 {
  font-family: var(--jen-font-title);
  font-weight: bold;
  font-size: 32px;
}
.survey-modal .modal-body p {
  font-size: 15px;
  font-weight: 700;
  font-family: var(--jen-font-sans-serif);
}
.survey-gift-img {
  float: right;
  width: 30px;
}
.success-model-body {
  min-height: 300px;
}

@media (max-width: 480px) {
  .survey-modal .modal-body {
    padding: 1.2rem 2rem 1.5rem !important;
  }
}

@media (max-width: 360px) {
  .survey-modal .modal-body {
    padding: 0 !important;
  }
  .survey-modal .modal-body h3 {
    font-size: 18px;
  }
  .survey-modal .modal-header button {
    right: -1px;
    top: -2px;
  }
}
