.lead-steps-section {
  padding: 100px 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
}
.lead-steps-item .count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--white);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background-color: var(--white);
  margin-right: 8px;
}
.lead-steps-section h3 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 48px;
}
.lead-steps-section p {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
}
.lead-steps-item .heading {
  color: var(--white);
  padding: 8px 12px;
  width: fit-content;
  border-radius: 0px 100px 100px 0px;
  margin: -26px 0 25px;
  position: relative;
}
.low-back-pain-page .lead-steps-section {
  padding: 100px 20px 20px;
  width: 1140px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .lead-steps-section h3 {
    line-height: 36px;
  }
  .lead-steps-section .lead-steps-item h3 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .lead-steps-section {
    padding: 50px 24px;
  }
}
