.App {
  overflow-x: hidden;
  height: inherit;
  width: inherit;
}

.main {
  width: 100%;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

.App-text {
  max-width: 500px;
}

.jen-navbar.navbar.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 5px);
  animation: headerAnimation 0.5s ease;
  z-index: 1001;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.main-body-pd {
  padding-top: 80px;
}

.row.row-margin-zero {
  margin: 0 !important;
}

.zIndex-minus-one {
  z-index: -1 !important;
}
@keyframes headerAnimation {
  0% {
    transform: translateY(-130px);
  }
  100% {
    transform: translateY(0);
  }
}
