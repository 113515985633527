.video-wrapper {
  padding: 56px 20px 70px;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.video-iframe {
  height: 592px;
  width: 100%;
}
.full-body-video-h2 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 32px;
}
.video-wrapper .sign-up-button-new {
  margin-top: 32px;
}
.offer-mob-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

@media (max-width: 767px) {
  .video-wrapper {
    padding: 56px 0 0 0;
  }
  .video-iframe {
    height: 328px;
  }
  .full-body-video-h2 {
    font-size: 28px;
    line-height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
  }
  .video-wrapper .sign-up-button-new {
    max-width: 311px;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
  }
  .offer-mob-div {
    display: block;
    padding: 10px 32px 32px 32px;
  }
}
