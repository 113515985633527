.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(210, 210, 210, 0.5);
  }

  100% {
    background-color: rgba(210, 210, 210, 1);
  }
}

.white {
  background-color: #ffffff;
}

.light {
  background-color: #f2f2f2;
  border-radius: 16px;
}

.dark {
  background-color: #808080;
  border-radius: 16px;
}

.self-skeleton {
  background-color: #696969;
  border-radius: 4px;
  margin-bottom: 23px;
}

.banner-skeleton {
  background-color: #696969;
}

.faq-skeleton-wrapper {
  border-radius: 16px;
}

.folder-skeleton-wrapper {
  margin-bottom: 30px;
  border-radius: 12px 6px 6px 12px;
  position: relative;
  min-height: 136px;
  padding-top: 32px;
}

.detailed-folder-skeleton-wrapper {
  width: 100%;
  margin-bottom: 30px;
}

.plans-skeleton-wrapper {
  border-radius: 0px 18px 18px 18px;
}

.search-skeleton-wrapper {
  border-radius: 6px;
}

.quick-tips-skeleton-wrapper {
  border-radius: 4px;
}

.curated-self-skeleton-wrapper {
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  width: calc(33.3333% - 30px);
  margin-bottom: 30px;
}

.plan-skeleton {
  background-color: #696969;
  border-radius: 4px;
}

.challenge-skeleton {
  background-color: #696969;
  border-radius: 4px;
}

.search-skeleton {
  background-color: #696969;
}

.folder-skeleton {
  background-color: #696969;
}

.detailed-folder-skeleton {
  background-color: #ccc;
}

.faq-skeleton {
  background-color: #696969;
  border-radius: 16px;
}

.quick-tips-skeleton {
  background-color: #696969;
  border-radius: 4px;
}

.curated-self-skeleton {
  background-color: #696969;
  border-radius: 4px;
  margin-bottom: 10px;
}

.filter-box {
  width: 103px;
  height: 40px;
  background-color: #696969;
  border-radius: 40px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.my-plans-skeleton {
  background-color: #696969;
  border-radius: 4px;
}

.text {
  width: 100%;
  height: 12px;
  margin-top: 15px;
}

.title {
  width: 70%;
  height: 20px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.search-title {
  width: 30%;
  height: 38px;
  margin-left: 20px;
}

.plan-title {
  width: 100%;
  min-height: 48px;
  margin-bottom: 10px;
}

.plan-duration {
  width: 100px;
  border-radius: 100px;
  margin-bottom: 8px;
  height: 28px;
}

.challenge-title {
  width: 20%;
  margin-left: 24px;
  margin-top: 24px;
  border-radius: 8px;
  min-height: 48px;
  margin-bottom: 8px;
  background: #808080 !important;
}

.quick-tips-detailed-title {
  width: 90%;
  height: 32px;
}

.plan-title-2 {
  width: 80%;
  height: 24px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.challenge-title-2 {
  width: 80%;
  height: 48px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 24px;
  background: #808080 !important;
}

.plan-text {
  width: 90%;
  height: 20px;
  margin-top: 14px;
  margin-bottom: 10px;
}

.plan-text-2 {
  width: 90%;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.curated-title {
  background: #696969;
  width: 100%;
  height: 76px;
}

.browse-title {
  background: #696969;
  height: 32px;
  border-radius: 3px;
}

.search-navbar {
  background: #696969;
  width: 100%;
  height: 28px;
}

.my-plans-title {
  background: #696969;
  width: 30%;
  height: 28px;
}

.curated-text-top {
  background: #696969;
  width: 100%;
  height: 18px;
  margin-top: -65px;
}

.curated-text {
  background: #696969;
  width: 100%;
  height: 18px;
  margin-top: 0px;
}

.curated-text-issues {
  background: #696969;
  width: 100%;
  height: 18px;
  margin-top: 0px;
}

.curated-text-issues-bottom {
  background: #696969;
  width: 100%;
  height: 18px;
  margin-top: 0px;
  margin-bottom: 50px !important;
}

.self-thumbnail {
  width: 100%;
  height: 175px;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
}

.banner-thumbnail {
  width: 100%;
  height: 576px;
}

.challenge-thumbnail {
  width: 100%;
  max-height: 224px;
  min-height: 224px;
  border-radius: 8px;
  overflow: hidden;
}

.search-thumbnail {
  width: 36%;
  height: 126px;
  -webkit-clip-path: circle(99.4% at 100% 50%);
  clip-path: circle(99.4% at 100% 50%);
  overflow: hidden;
  margin-left: 133px;
}

.quick-tips-thumbnail {
  background: #696969;
  width: 100%;
  height: 160px;
  border-radius: 4px;
  overflow: hidden;
}

.search-quick-tips-thumbnail {
  background: #696969;
  width: 100%;
  height: 124px;
  border-radius: 4px;
  overflow: hidden;
}

.quick-tips-title {
  width: 100%;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.quick-tips-title-2 {
  width: 50%;
  height: 18px;
  margin-top: -10px;
  margin-bottom: 5px;
  margin-left: 0px;
}

.self-text {
  width: 80%;
  height: 12px;
  margin-top: 15px;
  margin-left: 20px;
}

.self-title {
  width: 60%;
  height: 20px;
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.date-skeleton {
  background: var(--bg-floral);
  width: 850px;
  height: 106px;
  border-radius: 16px;
  margin-left: -95px;
  margin-bottom: -50px;
}

.faq-title {
  width: 50%;
  height: 24px;
  margin-top: 25px;
  margin-bottom: 10px;
  margin-left: -10px;
  float: right;
}

.faq-title-2 {
  width: 50%;
  height: 24px;
  margin-top: 0px;
  margin-bottom: 15px;
  float: right;
}

.faq-thumbnail {
  width: 40%;
  height: 144px;
  border-radius: 4px;
  overflow: hidden;
  float: left;
  margin-top: 5%;
}

.folders-title {
  border-radius: 4px;
  width: 15%;
  height: 32px;
  margin-left: 48px;
}

.folder-video-text {
  border-radius: 4px;
  width: 10%;
  height: 18px;
  margin-left: 48px;
  margin-top: 24px;
}

.folders-side-strip {
  width: 15px;
  height: 100%;
  position: absolute;
  left: 1px;
  top: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.detailed-folder-thumbnail {
  width: 140px;
  height: 120px;
  border-radius: 7px;
  overflow: hidden;
  float: left;
  margin-top: 0%;
}

.detailed-folder-text-top {
  width: 100%;
  height: 24px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.detailed-folder-text {
  width: 90%;
  height: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.detailed-folder-text-2 {
  width: 90%;
  height: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.detailed-folder-title {
  width: 90%;
  height: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.search-result-wrapper_ .search-self-assessment-skeleton-box {
  min-width: 30%;
  max-width: 30%;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
}

.login-search .search-self-assessment-skeleton-box {
  min-width: 60%;
  max-width: 60%;
}

.search-result-wrapper_ .search-plan-skeleton-box {
  min-width: 311px;
  max-width: 311px;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
}

.quick-tips-search-wrapper .quicktip-skeleton-box {
  max-width: 220px;
  min-width: 220px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 32px;
  cursor: pointer;
}

.s-b-s-p,
.p-b-s-b {
  padding: 24px;
}

.c-p-s-b {
  padding: 12px;
}

.e-s-s-p {
  padding-bottom: 24px;
}

.browse-text-skeleton {
  width: 250px;
}

.challenge-box-skeleton {
  max-width: 352px;
  min-width: 352px;
  margin-right: 32px;
  margin-bottom: 32px;
}

.deal-skeleton {
  border-radius: 8px;
}

.deal-skeleton .light {
  position: relative;
  overflow: hidden;
}

.deal-skeleton .light::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: -100%;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    left: 100%;
  }
}

.deal-skeleton .deal-img {
  width: 128px;
  height: 160px;
  border-radius: 8px;
}

.deal-skeleton .deal-info {
  width: 288px;
}

.deal-skeleton .deal-info .deal-head {
  height: 48px;
  border-radius: 8px;
}

.deal-para {
  height: 40px;
  margin-bottom: 14px;
  border-radius: 8px;
}

.deal-list {
  height: 18px;
  border-radius: 4px;
  margin: 2px 0;
}

.deal-price {
  width: 59px;
  height: 16px;
  margin-top: 22px;
  border-radius: 4px;
}

.deal-off {
  width: 65px;
  height: 16px;
  margin-top: 22px;
  border-radius: 4px;
}

.deal-add-btn {
  margin-left: 40px;
  width: 80px;
  height: 44px;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .search-navbar {
    background: #696969;
    margin-left: auto;
    width: 100% !important;
    height: 80px;
  }

  .quick-tips-detailed-title {
    width: 90%;
    height: 28px;
  }

  .plan-text-2 {
    margin-bottom: 0px;
  }

  .curated-self-skeleton-wrapper {
    flex: 0 0 100%;
    margin-right: 0px;
    margin-left: 0px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 30px;
  }

  .my-plans-skeleton {
    flex: 0 0 100%;
    margin-right: 0px;
    margin-left: 0px;
    cursor: pointer;
    width: 50%;
  }

  .curated-title {
    background: #696969;
    margin-left: auto;
    width: 100% !important;
    height: 40px;
  }

  .search-navbar {
    background: #696969;
    margin-left: auto;
    width: 100% !important;
    height: 18px;
  }

  .curated-text-top {
    background: #696969;
    margin-left: auto;
    width: 100%;
    height: 12px;
    margin-top: 20px;
  }

  .curated-text {
    background: #696969;
    margin-left: auto;
    width: 100%;
    height: 12px;
    margin-top: 0px;
  }

  .curated-text-issues {
    margin-left: auto;
    width: 100%;
    height: 12px;
  }

  .curated-text-issues-bottom {
    margin-left: auto;
    width: 100%;
    height: 12px;
    margin-bottom: 55px !important;
  }

  .curated-text-mobile {
    background: #696969;
    margin-left: auto;
    width: 100%;
    height: 12px;
    margin-top: 0px;
  }

  .plan-title-2 {
    display: none;
  }

  .plan-text {
    margin-top: 14px;
  }

  .plan-text-2 {
    margin-top: 5px;
  }

  .folder-skeleton-wrapper {
    min-height: 88px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .folders-side-strip {
    width: 10px;
  }

  .folders-title,
  .folder-video-text {
    margin-left: 35px;
  }

  .folders-title {
    height: 22px;
  }

  .folder-video-text {
    height: 16px;
    margin-top: 14px;
  }

  .detailed-folder-thumbnail {
    width: 80px;
    height: 80px;
  }

  .detailed-folder-text-top {
    width: 80%;
    height: 18px;
  }

  .detailed-folder-title {
    height: 18px;
    margin: 0;
    margin-bottom: 5px;
    width: 80%;
  }

  .detailed-folder-text {
    height: 12px;
    margin-top: 17px;
    width: 70%;
    margin-bottom: 5px;
  }

  .detailed-folder-text-2 {
    height: 12px;
    margin-top: 3px;
    width: 70%;
    margin-bottom: 0px;
  }

  .quick-tips-thumbnail {
    height: 115px;
  }

  .filter-box {
    width: 74px;
    height: 32px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .self-assessment-search-wrapper .search-self-assessment-skeleton-box {
    margin-bottom: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .plans-search-wrapper .search-plan-skeleton-box {
    margin-bottom: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .quick-tips-search-wrapper .quicktip-skeleton-box {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 22px);
    width: calc(50% - 22px);
    margin-left: 11px;
    margin-right: 11px;
    margin-bottom: 22px;
    cursor: pointer;
  }

  .challenge-box-skeleton {
    max-width: 311px !important;
    min-width: 311px !important;
    margin-right: 0;
    margin-bottom: 32px;
    height: unset !important;
  }

  .quick-tips-search-wrapper .quicktip-skeleton-box {
    max-width: 145px;
    min-width: 145px;
  }

  .banner-thumbnail {
    height: 436px;
  }

  .search-result-wrapper_ .search-self-assessment-skeleton-box {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}

@media (max-width: 360px) {
  .challenge-box-skeleton {
    max-width: 256px !important;
    min-width: 256px !important;
  }
}

@media (max-width: 280px) {
  .quick-tips-search-wrapper .quicktip-skeleton-box {
    max-width: 115px;
    min-width: 115px;
  }

  .self-assessment-search-wrapper .search-self-assessment-skeleton-box {
    margin-bottom: 16px;
    margin-left: 12px;
    margin-right: 12px;
    min-width: 240px;
    max-width: 240px;
  }

  .plans-search-wrapper .search-plan-skeleton-box {
    min-width: 240px;
    max-width: 240px;
    margin-bottom: 16px;
    margin-left: 12px;
    margin-right: 12px;
  }
}