.sign-in-h3 {
  color: #14142b;
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.head3 {
  color: #14142b;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.signin-bottom-row {
  margin-top: 24px;
}

.sign-in-agreement {
  margin-top: 14px;
}

.sign-in-agreement h4 {
  font-family: var(--jen-font-sans-serif);
  font-size: 10.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 0;
}

.sign-in-agreement h3 {
  font-family: var(--jen-font-sans-serif);
  font-size: 10.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: 0;
}

.sign-in-agreement a {
  font-family: var(--jen-font-sans-serif);
  font-size: 10.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  text-decoration: underline;
  margin-left: 0.1px;
  margin-right: 0.1px;
  cursor: pointer;
  color: #14142b;
}

.new-sign-in-para {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .new-sign-in-para {
    margin-top: 32px;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .sign-in-agreement {
    margin-top: 20px;
  }

  .signin-bottom-row {
    margin-top: 0;
  }

  .sign-in-agreement h4 {
    font-size: 15px;
    line-height: 20px;
    flex: 0 0 100%;
    font-weight: 400;
  }

  .sign-in-agreement h3 {
    font-size: 15px;
    font-weight: 400;
  }

  .sign-in-agreement a {
    font-size: 15px;
  }

  .head3 {
    line-height: 24px;
  }

  .form-h h1,
  .form-h p {
    display: none;
  }

  .new-sign-in-para {
    display: block !important;
  }

  .form-h {
    padding-top: 38px;
  }

  .new-sign-in-para {
    margin-top: 16px;
    margin-bottom: 0;
  }
}

@media (max-width: 360px) {
  .head3 {
    font-size: 16px;
    line-height: 20px;
  }

  .sign-in-agreement a {
    font-size: 14px;
  }
}