.join {
  color: var(--primary);
  /* width: 220px; */
  height: 40px;
  border: unset;
  border-radius: 4px;
  background-color: var(--white);
  font-family: var(--jen-font-sans-serif);
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.join:first-child {
  margin-right: 24px;
}

.logo {
  width: 50px;
}

.logo-text {
  font-family: var(--jen-font-title);
  line-height: 32px;
  cursor: pointer;
  margin-right: 20px;
}

.nav-item {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  cursor: pointer;
}

.nav-item:active {
  font-weight: bold;
}

.logo-header {
  /* width: 25%; */
  align-items: center;
}

.banner-section {
  background-color: #ecfffe;
  margin-bottom: 96px;
}

.section-title-home {
  font-family: var(--jen-font-title);
  color: #2c3659;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}

.section-para {
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  font-size: 17px;
  color: var(--black-russian);
  line-height: 28px;
}

.avator-img {
  width: 104px;
  height: 104px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  padding-top: 8px;
}

.block- {
  height: 100%;
  border-radius: 20px;
  padding: 0px 20px 18px;
  text-align: left;
  background-color: var(--white);
  box-shadow: 0px 42px 174px 9px rgba(0, 0, 0, 0.05),
    0px 8.4px 28.275px 9px rgba(0, 0, 0, 0.025);
}

.block- .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.block- .content p {
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  margin-top: 30px;
  color: #14142b;
}

.block- h3 {
  font-family: var(--jen-font-sans-serif);
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
}

.bg-Bridesmaid {
  background-color: #fae7e0;
}

.bg-Alabaster {
  background-color: #f4f2ea;
  position: relative;
  z-index: 99;
}

.bg-FloralWhite {
  background-color: #fffcf5;
}

.bg-OysterBay {
  background-color: #d4edec;
}

.h-3 {
  font-size: 32px;
  color: #2c3659;
  font-family: var(--jen-font-title);
  line-height: 48px;
  margin-bottom: 24px;
  font-weight: 400;
}

.cal.video-1:before,
.cal.video-1:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 10px;
  background: #fffcf5;
  top: 0;
  left: 0;
  z-index: 9;
}

.cal.video-1:after {
  top: auto;
  bottom: -1px;
}

.cal.video-1 .position-relative:after {
  content: '';
  background: url(../../static/images/video-shadow.jpg);
  width: calc(100% + 108px);
  height: 110px;
  position: absolute;
  bottom: -100px;
  left: -59px;
  background-size: 100% 100%;
  z-index: 99;
}

.cal.video-1 .position-relative video {
  height: 476px;
  object-fit: cover;
}

.block- p {
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  font-size: 22px;
  color: var(--black-russian);
}

.doctors-advice {
  padding-top: 50px;
  padding-bottom: 50px;
}

.doctors-advice:before {
  content: '';
  width: 50vw;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  right: 100%;
  z-index: 2;
}

.doctors-advice:after {
  content: '';
  width: 50vw;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 100%;
  z-index: 2;
}

.doctors-advice ul li {
  padding-left: 15px;
  padding-right: 15px;
}

.doctors-advice .item {
  background-color: var(--white);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  padding: 32px;
  border-radius: 16px;
}

.block- .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.bg-Bridesmaid {
  background-color: #fae7e0;
}

.bg-Alabaster {
  background-color: #f4f2ea;
}

.bg-FloralWhite {
  background-color: #fffcf5;
}

.bg-OysterBay {
  background-color: #d4edec;
}

.block- {
  margin-bottom: 0;
}

.block- p {
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  font-size: 22px;
  color: var(--black-russian);
}

.doctors-advice {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: inherit;
  /* position: static; */
}

.doctors-advice .react-multi-carousel-dot-list {
  display: none;
}

.body-parts-box {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background: #ffffff;
}

.home-row {
  padding-top: 72px;
  padding-bottom: 72px;
}

.home-row-2 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.home-vid-wrap:before {
  content: '';
  width: 123px;
  height: 123px;
  background-image: url(../../static/images/dotted-green.png);
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  bottom: -30px;
  left: -30px;
  z-index: 0;
}

.home-vid {
  z-index: 1;
  border-radius: 16px;
}

.cal {
  width: auto;
  display: inline-block;
  position: relative;
  margin-bottom: -181px;
}

.self {
  position: absolute;
  top: 40px;
  left: 35%;
}

.date-time {
  width: 137px;
  border-radius: 16px;
  background: linear-gradient(113.19deg, #cd9f52 -9.93%, #c59c37 110.63%);
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: normal;
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  line-height: 28px;
  color: var(--white);
  left: 35%;
  bottom: -20px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
}

.home-row-2-h1 {
  font-family: var(--jen-font-title);
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #125b58;
  margin-bottom: 40px;
}

.home-row-2-h4 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #2c3659;
  margin-bottom: 60px;
}

.left-div h1 {
  font-family: var(--jen-font-title);
  text-align: left;
  align-items: center;
  padding: 0px 0px 0px 0px;
  margin-bottom: 24px;
  font-style: normal;
  font-size: 40px;
  font-weight: 400;
  line-height: 55px;
  letter-spacing: -0.02rem;
  color: #2c3659;
}

.left-div p {
  font-size: 20px;
  margin-bottom: 32px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  line-height: 32px;
}

.testimonial-home {
  padding-top: 54px;
  padding-bottom: 72px;
}

.testimonial-home {
  overflow: inherit;
}

.testimonial-home ul li {
  padding-left: 15px;
  padding-right: 15px;
}

.testimonial-home .react-multi-carousel-dot-list {
  display: none;
}

.testimonial-home .react-multiple-carousel__arrow {
  display: none;
}

.react-multi-carousel-list {
  overflow: visible;
}

.w-j-t {
  color: #125b58;
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  margin-bottom: 30px;
}

.w-j-p li {
  font-size: 16px;
  color: var(--black-russian);
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.w-j-p li:before {
  content: '';
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  background-image: url(../../static/images/tick-mint.png);
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  background-size: 100%;
  margin-right: 10px;
  margin-top: 4px;
}

.login-in-button {
  margin-top: 26px;
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: transparent;
  font-family: var(--jen-font-sans-serif);
  padding: 12px 32px;
}

.sign-with-button {
  margin-top: 16px;
  color: #14142b;
  border: 1px solid #14142b;
  background-color: transparent;
  font-family: var(--jen-font-sans-serif);
  padding: 12px 30px;
  width: 100%;
  border-radius: 4px;
  font-size: 17px;
  line-height: 24px;
}

.sign-with-button:hover {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: #fff;
}

.sign-with-button img {
  width: 16px;
}

.google-btn {
  background: #4285f4 !important;
  color: white;
  letter-spacing: 0.2px;
  border: none;
  max-width: 270px;
  padding: 12px 24px;
  height: 56px;
  margin-left: 3rem;
  margin-right: 3rem;
  border: 1px solid var(--primary);
}

.apple-btn {
  max-width: 270px;
  padding: 12px 24px;
  height: 56px;
  margin-left: 3rem;
  margin-right: 3rem;
}

.apple-btn:hover {
  background: #e4d4d4ba;
  color: black;
  border: 1px solid #dad0d0;
}

.google-btn:active {
  background: #1669f2;
}

.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}

.google-btn img {
  width: 28px;
  height: 28px;
  background-color: #fff;
}

.app-store-btn {
  margin-top: 32px;
  cursor: pointer;
}

.app-store-btn img {
  width: 156px;
}

.save-cost-text {
  font-family: var(--jen-font-title) !important;
  font-size: 40px !important;
  font-weight: 400;
  line-height: 55px !important;
  letter-spacing: -0.02em !important;
  text-align: center;
  color: #06bab2;
}

.faq-section {
  background-color: #f8f6f0 !important;
}

.accordion-wrapper {
  padding: 16px;
  border-bottom: 1px solid #a1a4b2;
  text-align: left;
  cursor: pointer;
  width: 768px;
  max-width: 100%;
  margin: 0 auto;
}

.accordion-wrapper .collapse ul {
  padding-left: 1.2rem;
}

.accordion-wrapper .collapsing ul {
  padding-left: 1.2rem;
}

.accordion-toggler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0;
}

.accordion-toggler>p {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #2c3659;
  letter-spacing: -0.02em;
}

.accordion-toggler>img {
  width: 19px;
  height: 10px;
}

.accordion-wrapper>div>div {
  font-size: 17px;
  line-height: 28px;
  color: #2c3659;
}

.free-trial-box {
  background: #ffffff;
  border: 3px solid #13bfb8;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 608px;
  margin: 20px auto;
  box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
}

.free-trial-header {
  background: #f9f7f1;
  border-radius: 8px 8px 0px 0px;
  padding: 24px 32px;
}

.free-trial-body {
  padding: 32px;
}

.step-icons::before {
  display: none;
}

.vl {
  border-left: 3px solid #a0a3b1;
  position: absolute;
  left: calc(50% - 1.5px);
  top: 40px;
}

.subscription-selector-wrapper {
  max-width: 700px;
  margin: 0 auto;
}

.free-trial-header h5 {
  font-family: var(--jen-font-sans-serif);
  color: #14142b;
  font-size: 24px;
  line-height: 24px;
  text-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  font-weight: 700;
}

.free-trial-header span {
  text-align: left;
}

.free-div .free-trial-body p {
  font-size: 15px;
  line-height: 20px;
  color: #14142b;
  margin-bottom: 0;
}

.save-cost-text.active {
  animation: fadeIn 1s ease;
}

.w-216 {
  width: 216px;
  max-width: 100%;
}

.why-jen-neck {
  padding-top: 30px;
  padding-bottom: 15px;
  background: #fffcf5;
}

.home-testimonial {
  padding-top: 80px;
  padding-bottom: 48px;
}

.jen-offer {
  background: var(--primary);
  max-height: 680px;
  padding-top: 96px;
}

.jen-offer .section-title {
  margin-bottom: 24px !important;
}

.jen-offer-para {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  margin-left: 280px;
  margin-right: 280px;
}

.steps-images {
  max-height: 170px;
  min-height: 170px;
  width: auto;
}

.steps-h5 {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.steps-para {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.body-card {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 20.7976px 86.1615px 4.45663px rgba(0, 0, 0, 0.05),
    0px 4.15952px 14.0013px 4.45663px rgba(0, 0, 0, 0.025);
  max-height: 216px;
  min-height: 216px;
}

.payment-page .banner-section .left-div h1,
.payment-page .banner-section .left-div p {
  width: 530px;
  max-width: 100%;
}

.payment-page .banner-section .subscription-selector .subscription-detail-boxs {
  position: relative;
  z-index: 99;
}

.logo-header .nav-item {
  height: 48px;
  padding: 10px 16px;
  border-radius: 4px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.logo-header .nav-item.active {
  background: rgba(7, 188, 180, 0.2);
}

.logo-header .nav-item img {
  vertical-align: middle;
  margin-right: 4px;
  width: 20px;
}

@media (max-width: 1320px) {

  .payment-page .banner-section .left-div h1,
  .payment-page .banner-section .left-div p {
    width: 450px;
  }
}

@media (min-width: 1200px) {
  .screen-wrap {
    position: relative;
    padding-top: 3vw;
    width: 940px;
    margin-bottom: -4.4vw;
    margin-right: -311px;
    margin-left: auto;
  }

  .screen-wrap video {
    vertical-align: top;
  }
}

@media (min-width: 1680px) {
  .screen-wrap {
    width: 960px;
  }
}

@media (min-width: 1920px) {
  .screen-wrap {
    width: 1060px;
  }
}

@media (max-width: 1400px) {
  .jen-offer-para {
    margin-right: 208px;
    margin-left: 208px;
  }
}

.desktop-screen {
  margin-left: 6.4rem;
}

.mobile-screen {
  position: absolute;
  top: 9vw;
  margin-left: 1vw;
  width: 13vw;
}

.home-footer {
  padding-top: 67px;
  padding-bottom: 42px;
}

.home-footer h3 {
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: -0.02em;
  color: #2c3659;
  margin-bottom: 15px;
}

.home-footer p {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 23px;
  color: var(--black-russian);
  margin-bottom: 20px;
}

.why-jen {
  padding-top: 72px;
  padding-bottom: 72px;
  background-color: #f4f2ea;
  margin-bottom: 56px;
}

.why-jen p.text-center {
  font-size: 24px;
  line-height: 40px;
  color: #2c3659;
  font-family: var(--jen-font-title);
}

.why-jen .sign-up-button-new {
  font-size: 20px;
  line-height: 24px;
  font-weight: 800;
}

.community-image-wrapper {
  margin-top: -66px;
  margin-bottom: -66px;
}

.react-multi-carousel-list.testimonial-home {
  overflow: visible;
}

.go-back-block {
  position: absolute;
  display: flex;
  top: 20px;
  left: 10px;
  cursor: pointer;
}

.go-back-block>img {
  height: 25px;
}

.new-home-header {
  padding: 20px 0;
}

.banner-section .banner-left {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.banner-section .banner-left:before {
  content: '';
  background: linear-gradient(to right, #ecfffe 0%, transparent 80%);
  position: absolute;
  height: 100%;
  width: 150px;
  right: -150px;
  z-index: 9;
}

.banner-section .left-div h1 {
  font-size: 27px;
  line-height: 38px;
  margin-bottom: 32px;
}

.jen-offer .doctors-advice:before,
.jen-offer .doctors-advice::after {
  display: none;
}

.jen-offer-row {
  padding-bottom: 56px;
}

.jen-offer-pic {
  height: 112px;
  margin-bottom: 24px;
}

.jen-offer-row p {
  margin-bottom: 0;
}

.onboarding-modal {
  overflow-y: hidden;
}

@media (min-width: 1200px) {
  .screen-wrap {
    position: relative;
    padding-top: 3vw;
    width: 940px;
    margin-bottom: -4.4vw;
    margin-right: -311px;
    margin-left: auto;
  }

  .screen-wrap video {
    vertical-align: top;
  }
}

@media (min-width: 1680px) {
  .screen-wrap {
    width: 960px;
  }
}

@media (min-width: 1920px) {
  .screen-wrap {
    width: 1060px;
  }
}

@media (max-width: 1024px) {
  .ipad-view {
    display: flex;
    align-items: center;
  }

  .payment-page .ipad-view {
    display: block;
  }

  .left-div {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 824px;
    margin: 0 auto;
    padding-right: 40px;
  }

  .left-div h1 {
    font-size: 48px;
    line-height: 65.81px;
    margin-top: 54px;
  }

  .left-div p {
    font-size: 32px;
    line-height: 40px;
  }

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wrapper-header {
    background-color: #f9f7f1;
    padding: 40px 32px 48px 32px;
  }

  .wrapper-header h1 {
    color: var(--black-russian);
    font-family: var(--jen-font-title);
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
    line-height: 48px;
    margin-bottom: 0;
  }

  .wrapper-header p {
    font-family: var(--jen-font-sans-serif);
    color: var(--black-russian);
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    line-height: 28px;
  }

  .wrapper-body {
    background-color: #fff;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    box-sizing: border-box;
    padding: 16px 32px 0 32px;
    flex: 1;
    margin-top: -32px;
    position: relative;
  }

  .community-image-wrapper {
    margin-left: 0;
  }

  .section-title-home {
    font-size: 28px;
    line-height: 38.39px;
  }

  .h-3 {
    font-size: 24px;
    line-height: 32px;
  }

  .h-3.sec-head {
    font-size: 32px;
    line-height: 48px;
    color: #14142b;
  }

  .doctors-advice .react-multiple-carousel__arrow {
    display: none;
  }

  .doctors-advice .react-multi-carousel-dot button,
  .testimonial-home .react-multi-carousel-dot button {
    background: transparent;
    border: 5px solid transparent;
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .doctors-advice .react-multi-carousel-dot button:before,
  .testimonial-home .react-multi-carousel-dot button:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #c4c4c4;
    border-radius: 50%;
  }

  .doctors-advice .react-multi-carousel-dot--active button,
  .testimonial-home .react-multi-carousel-dot--active button {
    background: rgba(197, 156, 55, 0.2);
    width: 20px;
    height: 20px;
  }

  .doctors-advice ul li.react-multi-carousel-dot,
  .testimonial-home ul li.react-multi-carousel-dot {
    padding-right: 0px;
    padding-left: 0px;
  }

  footer h3 {
    font-size: 24px;
    line-height: inherit;
  }

  .home-vid-wrap:before {
    width: 93px;
    height: 93px;
    bottom: 20px;
    left: -20px;
    display: none;
  }

  .cal {
    margin-bottom: -90px;
    margin-left: -16px;
  }

  .self {
    top: 3%;
    left: 45%;
    width: 57%;
  }

  .community-image {
    width: 95px;
    height: 185px;
  }

  .community-image-wrapper {
    margin-bottom: 0;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .community-image-wrapper .community-images {
    margin-right: 15px;
  }

  .community-image-wrapper .community-images:nth-child(2) {
    transform: translateY(-25px);
  }

  .community-image-wrapper .community-images:nth-child(3) {
    transform: translateY(-50px);
  }

  .doctors-advice .react-multi-carousel-dot-list {
    display: flex;
  }

  .testimonial-home .react-multi-carousel-dot-list {
    display: flex;
  }

  .banner-section {
    margin-bottom: 64px;
  }

  .home-row-2,
  .home-row,
  .home-footer,
  .why-jen {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .date-time {
    width: 86px;
    border-radius: 8px;
    font-size: 13px;
    line-height: 20px;
    left: 61%;
    bottom: -11px;
  }

  .community-image-wrapper .community-images:nth-child(3):before {
    right: -80px;
  }

  .home-footer h3 {
    font-size: 40px;
    line-height: 55px;
  }

  .footer-btn {
    font-size: 24px;
    line-height: 30px;
  }

  .doctors-advice .item .itemInner .name {
    margin-bottom: 0;
  }

  .screen-wrap {
    display: none;
  }

  .desktop-screen,
  .mobile-screen {
    display: none;
  }

  .community-image-wrapper {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .w-j-t {
    font-size: 40px;
    line-height: 64px;
  }

  .w-j-t+.sign-up-button-new {
    margin-right: 0;
  }

  .h-3 {
    line-height: 48px;
    margin-bottom: 18px;
  }

  .cal {
    margin-bottom: -70px;
  }

  .home-footer {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .accordion-toggler>p {
    font-size: 24px;
    line-height: 31px;
  }

  .avator-img {
    width: 95px;
    height: 95px;
  }

  .block- .content p {
    font-size: 17px;
    margin-top: 24px;
  }

  .app-store-btn {
    margin-top: 0;
  }

  .w-j-p {
    margin-bottom: 40px;
  }

  .w-j-t {
    font-size: 32px;
    line-height: 44px;
  }

  .footer-btn {
    font-size: 20px;
    line-height: 24px;
  }

  .video-1 video {
    height: 296px;
  }

  .banner-section .banner-left {
    padding-top: 0;
    padding-bottom: 0;
  }

  .jen-offer {
    max-height: 2110px;
  }

  .jen-offer-para {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 38px;
  }

  .jen-offer-row .sign-up-button-new {
    margin-top: 32px;
  }

  .home-testimonial .react-multi-carousel-list.doctors-advice {
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  .banner-section .left-div .ipad-view {
    align-items: flex-start;
    flex-direction: column;
  }

  .banner-section .left-div .ipad-view .login-in-button {
    margin-top: 25px;
  }

  .logo-header .logo-text {
    margin-right: 20px;
  }

  .logo-header .nav-item {
    margin-inline: 0px;
  }
}

@media (max-width: 767px) {
  .ipad-view {
    display: block;
  }

  .auth-link {
    margin-right: 6px;
  }

  .left-div h1 {
    font-size: 28px;
    line-height: 38.39px;
    margin-top: 0;
  }

  .left-div p {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }

  .left-div {
    padding-left: 0;
    padding-right: 0;
    padding-top: 48px;
    padding-bottom: 12px;
    max-width: 100%;
  }

  .join {
    height: 28px;
    font-size: 14px;
    line-height: 24px;
  }

  .join:first-child {
    margin-right: 13px;
  }

  .save-cost-text {
    font-size: 24px !important;
    line-height: 32px !important;
    margin-bottom: 0 !important;
  }

  .free-trial-header,
  .free-trial-body {
    padding: 24px;
  }

  .free-trail-work .free-div h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .free-trial-box {
    margin: 0 auto 20px;
    border-width: 1px;
  }

  .free-trial-box .free-trial-header h5 {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
  }

  .vl {
    top: 36px;
  }

  .subscription-selector-wrapper .subscription-selector {
    margin-bottom: 36px;
  }

  .faq-section {
    padding-bottom: 80px;
  }

  .section-title-home {
    font-size: 28px;
    margin-bottom: 88px;
    letter-spacing: -0.02em;
  }

  .block- .content p {
    font-weight: 500;
    font-size: 17px;
    margin-top: 18px;
  }

  .block- h3 {
    font-size: 20px;
    margin-bottom: 6px;
  }

  .block- {
    margin-bottom: 95px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .block-:last-of-type {
    margin-bottom: 76px;
  }

  .app-store-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .new-home-header {
    max-height: 64px;
  }

  .w-j-p li {
    font-size: 16px;
    line-height: 24px;
  }

  .why-jen {
    padding-top: 40px;
    margin-bottom: 40px;
  }

  .w-j-t {
    margin-bottom: 32px;
  }

  .home-footer h3 {
    line-height: 40px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 24px;
    font-size: 32px;
  }

  .home-footer p {
    margin-bottom: 24px;
  }

  .h-3 {
    font-size: 24px;
    line-height: 32px;
  }

  .h-3 {
    margin-bottom: 28px;
  }

  .section-para {
    font-weight: 500;
  }

  .h-3.sec-head {
    font-size: 32px;
    line-height: 38px;
    color: #14142b;
  }

  .why-jen p.text-center {
    font-size: 20px;
    line-height: 28px;
  }

  .jen-offer .section-title {
    margin-bottom: 32px !important;
  }

  .jen-offer {
    max-height: 2034px;
    padding-top: 80px;
  }

  .row.jen-offer-row {
    padding-bottom: 28px;
    margin-left: -8px !important;
    margin-right: -8px !important;
    height: 470px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .row.jen-offer-row.body-cards-row {
    height: 870px;
  }

  .jen-offer-pic {
    height: 58px;
    margin-bottom: 12px;
  }

  .body-card {
    max-height: 111px;
    min-height: 107px;
  }

  .row.jen-offer-row .col-4 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .jen-offer-row .sign-up-button-new {
    margin-top: 7px;
  }

  .steps-row .section-title {
    margin-bottom: 8px !important;
  }

  .steps-para {
    margin-bottom: 24px;
  }

  .banner-section {
    margin-bottom: 16px;
  }

  .sign-with-button:nth-of-type(1) {
    margin-bottom: 10px;
  }

  .google-btn {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
  }

  .free-trial-how-works {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }

  .payment-page .banner-left .left-div .ipad-view .sign-up-button-new {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .app-store-btn img {
    width: 200px;
  }

  .banner-section .left-div .ipad-view .login-in-button {
    margin-bottom: 25px;
  }

  .new-home-header {
    max-height: inherit;
    align-items: flex-start !important;
    padding-block: 16px;
  }

  .logo-header {
    width: auto;
    margin-bottom: 7px;
  }

  .new-home-header>div:nth-child(2) {
    align-self: center;
  }

  .wrapper-header {
    padding: 22px 32px 48px 42px;
  }

  .wrapper-body .form-h {
    padding-top: 20px
  }

  .apple-btn {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
  }
}

@media (max-width: 360px) {
  .wrapper-body {
    padding: 16px 16px 0 16px;
  }

  .wrapper-header {
    padding: 40px 16px 48px 16px;
  }

  .join {
    font-size: 10px;
  }

  .join:first-child {
    margin-right: 0;
  }

  .h-3 {
    font-size: 17px;
    line-height: 20px;
  }

  .home-footer p {
    font-size: 14px;
    line-height: 18px;
  }

  .section-title-home {
    font-size: 22px;
    line-height: 32px;
  }

  .block- .content p {
    font-size: 14px;
    line-height: 18px;
  }

  .avator-img {
    width: 80px;
    height: 80px;
  }

  .w-j-p li {
    font-size: 14px;
    line-height: 18px;
  }

  .w-j-t {
    font-size: 20px;
    line-height: 24px;
  }

  .home-footer h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .left-div h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .left-div p {
    font-size: 17px;
    line-height: 20px;
  }

  .body-card {
    max-height: 123px;
    min-height: 123px;
  }

  .jen-offer-para {
    font-size: 14px;
    line-height: 28px;
  }

  .row.jen-offer-row {
    height: 515px;
  }

  .row.jen-offer-row.body-cards-row {
    height: 960px;
  }
}

@media (max-width: 360px) {
  .google-btn {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 280px) {
  .h-3.sec-head {
    font-size: 24px;
    line-height: 28px;
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
