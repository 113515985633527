.cart-icon {
  position: relative;
  background-color: transparent !important;
}

.cart-icon .items-count {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--primary);
  color: var(--white);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  font-size: 15px;
  line-height: 20px;
  font-weight: 900;
}