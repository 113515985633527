:root {
  --primary: #06bab2;
  --white: #ffffff;
  --in-progress: #e14d53;
  --completed: #06bab2;
  --not-started: #c49c37;
  --bg-floral: #f9f7f1;
  --bg-pale-rose: #ebd4d9;
  --radical-red: #ff2060;
  --black-russian: #14142b;
  --hokey-pokey: #c59c37;
  --floral-white: #fffaee;
  --jen-font-sans-serif: 'DM Sans', sans-serif;
  --jen-font-inter: 'Inter', sans-serif;
  --jen-font-title: 'DM Serif Display', serif;
  --jen-font-shadow-into-light: 'Shadows Into Light', sans-serif;
  --jen-red-hat-display: 'Red Hat Display', sans-serif;
  --jen-font-dm-sans-serif : "DM Serif Display", serif;
  --jen-font : "DM Serif Display";
  --colour1: #f1c40f;
  --colour2: #2c3e50;
  --speed: 4s;
  --black: #000000;
  --dark-bg: #14142b;
  --freehand: 'Freehand', cursive;
}
