.lead-features-list h4 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 48px;
}
.lead-features-list .description {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-bottom: 40px;
}
.lead-features-list ul {
  list-style-image: url(../../../../static/images/challenge/icons/tick-black.svg);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.lead-features-list li {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.lead-features-list li p {
  margin-left: 13px;
}
.features-list-row {
  display: flex;
}
@media (max-width: 767px) {
  .features-list-row {
    flex-direction: column;
  }
  .lead-features-list h4 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  .lead-features-list li {
    font-size: 17px;
    line-height: 24px;
  }
  .lead-features-list ul {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lead-features-list li p {
    margin-left: 13px;
  }
}
