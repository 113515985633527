.dispaly-row {
  padding-top: 88px;
  padding-bottom: 64px;
}

.free-trial-row {
  margin-left: 190px;
  margin-right: 190px;
}

.free-div .free-trial-para {
  margin-bottom: 88px;
  margin-top: 40px;
  font-size: 15px;
  line-height: 28px;
  color: #14142b;
}

.spend-div {
  padding-top: 80px;
  padding-bottom: 80px;
}

.spend-section {
  background-color: #ecfffe;
  margin-bottom: 88px;
}

.payment-cards {
  padding-bottom: 88px !important;
}

.faq-row {
  padding-top: 80px;
  padding-bottom: 80px;
}

.faq-row h3 {
  font-family: var(--jen-font-title);
  font-size: 40px;
  line-height: 55px;
  color: #2c3659;
}

.free-trial-icon {
  margin-right: 21px;
}

.payment-page .banner-section {
  margin-bottom: 88px;
}

.display-div h1 {
  font-family: var(--jen-font-title);
  align-items: center;
  padding: 0px 0px 0px 0px;
  margin-bottom: 24px;
  font-style: normal;
  font-size: 32px;
  line-height: 44px;
  font-weight: 400;
  letter-spacing: -0.02rem;
  color: #14142b;
}

.display-div p {
  font-size: 20px;
  margin-bottom: 24px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  line-height: 32px;
  color: #2c3659;
}

.display-div .ipad-view .sign-up-button-new {
  width: 350px;
  max-width: 100%;
  padding: 12px 28px;
  font-family: 'Avenir', sans-serif;
  letter-spacing: 0.1em;
  line-height: 24px;
}

.free-div h3 {
  font-family: var(--jen-font-title);
  align-items: center;
  padding: 0px 0px 0px 0px;
  margin-bottom: 32px;
  font-style: normal;
  font-size: 32px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.02rem;
  color: #14142b;
}

.free-div p {
  font-size: 20px;
  margin-bottom: 32px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  line-height: 32px;
}

.free-div .trial-steps .step-text p {
  font-size: 14px;
  line-height: 20px;
}

.free-div .step-icons {
  min-width: 34px;
  width: 34px;
  height: 34px;
}

.sticky-free-trial-button {
  position: fixed;
  bottom: 0;
  z-index: 122;
  background: white;
  left: 0;
  padding: 24px 16px 0px 16px;
  box-shadow: 2px -5px 17px 0px #54575c1a;
  width: 100%;
  animation: slideIn 0.5s ease;
}

.free-div .subscription-selector-wrapper {
  margin-top: 56px;
}

.free-div .subscription-selector-wrapper .subscription-selector {
  margin-bottom: 48px !important;
}

.free-div .free-trial-para1 {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
}

.public-view .profile-image-icon {
  display: none;
}

.form-check-input:checked[type='radio'] {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.free-trail-work {
  margin-top: 20px;
}

.landing-banner {
  width: 100%;
  background: var(--primary);
  display: flex;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 250px;
  flex-wrap: wrap;
}

.banner-offer-text {
  font-family: var(--jen-font-sans-serif);
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.2em;
  text-align: left;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 5px;
  white-space: nowrap;
}

.banner-discount-text {
  font-family: var(--jen-font-sans-serif);
  font-size: 125px;
  line-height: 120px;
  font-weight: 800;
  letter-spacing: -0.05em;
  text-align: left;
  color: var(--white);
  margin-bottom: 5px;
  white-space: nowrap;
}

.banner-sub-text {
  font-family: var(--jen-font-sans-serif);
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--white);
  margin-bottom: 0px;
  white-space: nowrap;
}

.image-block {
  display: flex;
  height: inherit;
  overflow: hidden;
  align-items: center;
  position: relative;
  padding-left: 140px;
}

.image-block>img {
  max-height: 250px;
  position: absolute;
  left: 0px;
}

.landing-banner button {
  background: var(--white);
  color: var(--primary);
  font-weight: 700;
}

.banner-button {
  margin-left: 10px;
}

.price-block {
  background-color: #eec87b;
  text-align: center;
  width: 413px;
  min-width: 413px;
  height: 413px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actual-price {
  font-family: DM Serif Display;
  font-size: 40px;
  font-weight: 400;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
  top: 0;
  background: linear-gradient(to top left,
      #eec87b calc(50% - 3px),
      #e14d53,
      #eec87b calc(50% + 1px));
}

.current-price {
  font-family: League Spartan;
  font-size: 110px;
  font-weight: 700;
  line-height: 101px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
}

.text-block .banner-button {
  display: none;
}

.full-body-low-impact-page .free-trail-work {
  margin-top: 0;
}

.full-body-low-impact-page .subscription-selector {
  width: 600px;
}

.banner-section .screen-wrap {
  width: 1070px;
}

@media (max-width: 1457px) {
  .landing-banner {
    flex-direction: column;
  }

  .banner-offer-text {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
  }

  .banner-discount-text {
    font-size: 56px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: -0.05em;
  }

  .banner-sub-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    white-space: inherit;
  }

  .banner-button {
    display: none;
  }

  .text-block .banner-button {
    display: block;
    margin: 10px auto;
  }
}

@media (min-width: 1200px) {
  .payment-screen-wrap {
    position: relative;
    width: 1085px;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 88px;
  }

  .payment-screen-wrap img {
    margin: 0 12px;
  }

  .image-block>img {
    left: -10px;
  }
}

@media (max-width: 1024px) {
  .display-div h1 {
    font-size: 40px;
    line-height: 54px;
  }

  .payment-screen-wrap {
    position: relative;
    width: 1180px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
  }

  .payment-screen-wrap img {
    width: 23%;
    margin: 0 1%;
  }

  .payment-page .banner-section {
    padding-bottom: 0;
  }

  .dispaly-row {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .spend-div .left-div {
    padding: 0;
  }

  .spend-div .left-div h1 {
    font-size: 32px;
    line-height: 44px;
    margin-top: 0;
  }

  .spend-div .left-div .save-cost-text {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .faq-row .left-div {
    padding: 0;
  }

  .free-div .subscription-selector-wrapper {
    margin-top: 64px;
  }

  .card-section .section-title-home {
    margin-bottom: 40px;
  }

  .free-div .free-trial-para {
    margin-bottom: 64px;
  }

  .spend-section {
    margin-bottom: 64px;
  }

  .payment-cards {
    padding-bottom: 64px !important;
  }

  .price-block {
    width: 300px;
    min-width: 300px;
    height: 300px;
  }

  .current-price {
    font-size: 90px;
  }

  .full-body-low-impact-page .subscription-selector {
    width: 100%;
  }

  .banner-section .ipad-view .app-store-btn {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .payment-page .banner-section {
    margin-bottom: 0;
  }

  .dispaly-row .left-div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .dispaly-row {
    padding: 0 16px;
  }

  .free-trial-para {
    font-size: 15px !important;
    line-height: 24px !important;
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }

  .spend-div .left-div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .spend-div .left-div h1 {
    margin-top: 0 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    display: inline;
  }

  .spend-div .left-div .save-cost-text {
    font-size: 24px !important;
    line-height: 32px !important;
    display: inline;
    margin-left: 5px;
  }

  .spend-div {
    padding: 32px 24px;
  }

  .free-trial-icon {
    margin-right: 8px;
  }

  .free-trial-para1 {
    font-size: 15px !important;
    line-height: 24px !important;
    margin-bottom: 0 !important;
    margin-top: 16px;
  }

  .spend-section {
    margin-bottom: 48px;
  }

  .card-section {
    padding-bottom: 40px;
  }

  .faq-row {
    padding-top: 32px;
    padding-bottom: 54px;
  }

  .accordion-toggler p {
    font-weight: 700 !important;
  }

  .display-div {
    padding-top: 32px;
    padding-bottom: 32px;
    margin: 0 auto;
    width: 100%;
  }

  .display-div h1 {
    font-size: 28px;
    line-height: 40px;
  }

  .display-div p {
    font-size: 17px;
    line-height: 28px;
  }

  .payment-images {
    margin: 12px 0;
    width: calc(100% - 20px);
  }

  .payment-screen-wrap {
    padding: 0 10px;
    margin: 27px 0;
    width: auto;
  }

  .payment-screen-wrap img {
    width: 100%;
    margin: 12px 0;
  }

  .faq-row h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .free-div h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .free-div .trial-steps .step-text p {
    line-height: 24px;
  }

  .free-div .subscription-selector-wrapper {
    margin-top: 32px;
  }

  .free-div .subscription-selector-wrapper .subscription-selector .s-d-t {
    margin-left: 13px;
    margin-right: 13px;
  }

  .card-section .block-:last-of-type {
    margin-bottom: 0;
  }

  .card-section .section-title-home {
    margin-bottom: 88px;
  }

  .image-block {
    flex-direction: column-reverse;
    padding: 0;
    align-items: inherit;
    padding-right: 60px;
  }

  .image-block>img {
    position: static;
    z-index: 2;
    transform: rotateY(180deg);
    max-height: 110px;
    padding-right: 60px;
  }

  .price-block {
    min-width: 148px;
    width: 148px;
    height: 148px;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  .actual-price {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0em;
    top: 20px;
  }

  .current-price {
    font-size: 40px;
    line-height: 37px;
    letter-spacing: 0em;
  }

  .banner-button {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .landing-banner {
    height: 200px;
    max-height: 200px;
    padding: 0 0 0 15px;
  }

  .landing-banner button {
    max-width: 206px;
    font-size: 16px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .landing-banner .d-flex {
    width: 100%;
  }

  .text-block {
    max-width: 206px;
  }

  .banner-discount-text {
    font-size: 52px;
  }

  .full-body-low-impact-page .subscription-selector {
    padding: 20px 0 0;
  }

  .banner-section .ipad-view .app-store-btn {
    margin-top: 0;
  }
}

@media (max-width: 360px) {
  .price-block {
    width: 124px;
    height: 124px;
    min-width: 124px;
  }
}

@media (max-width: 280px) {
  .price-block {
    width: 100px;
    height: 100px;
    min-width: 100px;
  }

  .banner-offer-text {
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0em;
  }

  .banner-discount-text {
    font-size: 38px;
    line-height: 40px;
  }

  .banner-sub-text {
    font-size: 13px;
    line-height: 17px;
  }

  .landing-banner button {
    max-width: 270px;
    font-size: 13px !important;
  }

  .text-block {
    max-width: 170px;
  }

  .landing-banner {
    padding: 0 0 0 10px;
  }

  .current-price {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0em;
  }

  .actual-price {
    font-size: 17px;
    line-height: 24px;
  }

  .landing-banner {
    height: 175px;
    max-height: 175px;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(150px);
  }

  100% {
    transform: translateY(0px);
  }
}