.time-bubble {
  background-color: #e14d53;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
  justify-content: center;
  color: var(--white);
}
.timer-value {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: center;
}
.timer-unit {
  font-family: var(--jen-font-sans-serif);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: center;
}
.text-live {
  text-transform: uppercase;
  background-color: #e51735;
  border-radius: 6px;
  font-family: var(--jen-font-sans-serif);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  padding: 0 2px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  margin-left: 16px;
}
.text-live .dot {
  min-width: 9px;
  width: 9px;
  height: 9px;
  background-color: var(--white);
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px;
}

@media (max-width: 1024px) {
  .label-wrapper {
    padding: 0 40px;
  }
}

@media (max-width: 767px) {
  .time-bubble {
    width: 40px;
    height: 40px;
    margin: 0 4px;
  }
  .timer-value {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }
  .timer-unit {
    font-size: 8px;
    line-height: 14px;
    letter-spacing: -0.02em;
    font-weight: 400;
  }
  .text-live {
    margin-left: 0;
  }
  .label-wrapper {
    padding: 0;
  }
}
