.checkout-card {
  display: flex;
  gap: 4px 16px;
  position: relative;
  font-family: var(--jen-font-sans-serif);
}

.checkout-card .checkout-card .remove-deal {
  cursor: pointer;
  width: 20px;
}

.checkout-card h4 {
  font-size: 17px;
  line-height: 24px;
  color: #14142b;
  padding-right: 0;
  margin-bottom: 0;
}

.checkout-card span {
  font-size: 17px;
  line-height: 28px;
  color: #14142b;
}

.checkout-card p {
  font-size: 13px;
  line-height: 20px;
  color: #14142b;
  margin-bottom: 0;
}

.checkout-card>img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
}

.checkout-card .price-cut {
  background: linear-gradient(-15deg,
      transparent 47.75%,
      #e14d53 49.5%,
      #e14d53 50.5%,
      transparent 52.25%);
}

.checkout-card .card-detail {
  flex: 1;
}

@media screen and (max-width: 767px) {
  .checkout-card {
    gap: 4px 12px;
  }

  .checkout-card h4 {
    padding-right: 10px;
  }
}