.label-wrapper {
  padding: 20px 40px;
  background-color: #14142b;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.label-wrapper .text-header {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
}
.label-wrapper .text-description {
  font-family: var(--jen-font-sans-serif);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.join-now-btn {
  display: none;
}
.join-now-button {
  border: 1px solid var(--primary);
  color: var(--primary);
  background: var(--black);
  border-radius: 4px;
  min-width: 200px;
  margin-left: 3rem;
}
.timer-block {
  display: flex;
}

@media (max-width: 1024px) {
  .timer-block {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;
  }
  .join-now-btn {
    display: block;
    background-color: #14142b;
    color: #06bab2;
    font-size: 16px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 10px;
    border: 1px solid #06bab2;
    border-radius: 4px;
    width: 120px;
  }
  .label-wrapper {
    font-size: 17px;
    line-height: 24px;
  }
  .join-now-button {
    min-width: 130px;
    margin-left: 2rem;
    height: 40px;
  }
}

@media (max-width: 767px) {
  .label-wrapper {
    padding: 10px;
    flex-direction: column;
  }
  .label-wrapper .text-description {
    display: none;
  }
  .label-wrapper .timer-block {
    padding: 0;
  }
  .join-now-button {
    margin-left: 8px;
  }
}
