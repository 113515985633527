.payment-modal-wrapper {
  overflow: hidden;
}

.payment-modal {
  text-align: center;
}

.payment-modal,
.subscription-modal {
  height: 100%;
}

.payment-modal .modal-content,
.subscription-modal .modal-content {
  border-radius: 8px;
}

.payment-modal .modal-content {
  top: 50%;
  transform: translateY(-50%);
}

.subscription-modal .modal-content {
  top: 50%;
  transform: translateY(-50%);
}

.payment-modal [class*='close'] {
  padding: 0;
  border: unset;
  background: unset;
  position: absolute;
  right: 16px;
  top: 0;
  font-size: 32px;
  color: var(--black-russian);
}

.payment-modal [class*='close'] .sr-only {
  display: none;
}

.payment-modal [class*='create-folder-back-btn'] {
  top: 25px;
}

.payment-modal .modal-body {
  padding: 0px 40px 0px 40px !important;
}

.payment-modal .modal-header {
  padding: 0 !important;
}

.payment-modal .modal-footer {
  padding: 0px 40px 40px 40px !important;
}

.subscription-modal .modal-footer,
.payment-modal .modal-footer {
  border-top: none;
}

.start-free-trail {
  background-color: var(--bg-floral);
  padding: 14px 16px;
  border-radius: 8px;
  /* margin-top: 16px; */
}

.trial-steps {
  display: flex;
  justify-content: flex-start;
  text-align: start;
}

.step-icons {
  height: 56px;
  width: 56px;
  display: flex;
  margin-right: 13px;
  position: relative;
  border-radius: 50%;
}

.step-icons > img {
  margin: auto;
  border-radius: 50%;
  /* background-color: var(--white); */
}

.trial-steps .step-icons:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 47px;
  background-color: #b1a695;
  left: 50%;
  margin-left: -2px;
  top: 37px;
  z-index: 2;
  border-radius: 10px;
}

.trial-steps:nth-child(1) {
  margin-bottom: 30px;
}

.trial-steps:nth-child(2) {
  margin-bottom: 25px;
}

.trial-steps:nth-child(2) .trial-steps {
  padding-right: 10px;
}

.trial-steps:nth-child(3) {
  margin-bottom: 15px;
}

.trial-steps:nth-child(3) .step-icons:before {
  display: none;
}

.payment-modal .payment-modal-title {
  font-size: 32px;
  color: var(--black-russian);
  font-family: var(--jen-font-title);
  margin-top: 48px;
  margin-bottom: 16px;
}

.view-all-plans {
  color: var(--primary);
  text-decoration: underline;
  font-size: 20px;
  margin-bottom: 14px;
  cursor: pointer;
  font-family: var(--jen-font-sans-serif);
  font-weight: 500;
  margin-top: 17px;
}

.step-text h5 {
  font-family: var(--jen-font-title);
  font-size: 17px;
  line-height: 28px;
  margin: 4px 0 8px;
  color: #14142b;
}

.step-text p {
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  font-family: var(--jen-font-sans-serif);
}

.free-trail-text {
  font-family: var(--jen-font-title);
  font-size: 20px;
  line-height: 28px;
}

.subscription-block {
  padding: 40px 40px 18px 40px;
}

.subscription-block div:last-child {
  margin-bottom: 0 !important;
}

.subscription-block h3 {
  font-family: var(--jen-font-title);
  font-size: 28px;
  margin-bottom: 24px;
}

.subscription-block__ h3 {
  font-family: var(--jen-font-title);
  font-size: 24px;
  margin-top: 30px;
  line-height: 30px;
  margin-bottom: 32px;
  text-align: center;
  margin-left: 25px;
  margin-right: 25px;
}

.start-free-trail-btn {
  min-width: 351px;
  letter-spacing: 1.2px;
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 8px 32px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--jen-font-sans-serif);
  margin: auto;
  text-transform: uppercase;
  box-shadow: 0px 10px 20px rgba(6, 186, 178, 0.2);
  height: 48px;
}

.subscription-modal .start-free-trail-footer {
  margin-bottom: 32px;
  margin-top: 40px;
  padding: 0;
}

.start-free-trail-footer {
  border-top: unset;
  padding-top: unset;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  padding-bottom: 0 !important;
}

.subscription-detail-boxs {
  min-height: 80px;
}

.subscription-selector {
  text-align: start;
  width: 100%;
  margin: 0;
  margin-bottom: 36px;
}

.plan-radio-group {
  flex: 90%;
  display: flex;
  padding: 20px 16px;
  width: 324px;
  box-sizing: border-box;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
  border-radius: 8px;
  height: 96px;
}

.h-t-c {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 8px;
  font-family: var(--jen-font-sans-serif);
}

.c-p-t {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-family: var(--jen-font-sans-serif);
  margin-bottom: 0px;
}

.subscription-detail-boxs .plan-radio-group {
  border: 1px solid transparent;
}

.subscription-detail-boxs .plan-radio-group:first-child {
  margin-right: 9px;
}

/* .subscription-detail-boxs .plan-radio-group:nth-child(2) {
  margin-right: 9px;
  margin-left: 9px;
  background-color: #ecfffe;
  border: 1px solid #06bab2;
  z-index: 3;
} */

.subscription-detail-boxs .plan-radio-group:nth-child(3) {
  margin-left: 9px;
}

.subscription-detail-boxs .plan-radio-group.selected-plan {
  /* background-color: #ecfffe;
  border: 2px solid #06bab2; */
  margin-right: 9px;
  margin-left: 9px;
  background-color: #ecfffe;
  border: 1px solid #06bab2;
  z-index: 3;
}

.month-price {
  flex: 1;
}

.selector-cut-price {
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    #e14d53 49.5%,
    #e14d53 50.5%,
    transparent 52.25%
  );
  font-family: var(--jen-font-sans-serif);
  font-size: 15px !important;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.type-price .h5 {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #14142b;
}

.type-price h5.cut-price {
  font-size: 15px;
  background: linear-gradient(
    -15deg,
    transparent 47.75%,
    #e14d53 49.5%,
    #e14d53 50.5%,
    transparent 52.25%
  );
}

.type-price + span {
  font-family: var(--jen-font-sans-serif);
  font-size: 14px;
  line-height: 24px;
  padding-right: 60px;
  display: inline-block;
}

.cut-price-div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.s-d-t {
  font-family: var(--jen-font-sans-serif);
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;
  display: block;
}

.subscription-modal .modal-body {
  padding: 0px 34px 0px 34px !important;
}

.subscription-modal [class*='close'] {
  width: 24px;
  height: 24px;
  padding: 0;
  border: unset;
  background: unset;
  position: absolute;
  right: 16px;
  top: 30%;
  font-size: 32px;
  margin-top: -12px;
}

.subscription-modal [class*='close'] .sr-only {
  display: none;
}

.subscription-modal [class*='close'] span:first-child {
  line-height: 24px;
  display: block;
  font-size: 36px;
}

.subscription-modal [class*='create-folder-back-btn'] {
  top: 50%;
  margin-top: -10px;
}

.subscription-modal .payment-modal-title {
  font-size: 32px;
  line-height: 43.87px;
  color: var(--black-russian);
  font-family: var(--jen-font-title);
  margin-bottom: 16px;
}

.subscription-modal .modal-header {
  background-color: #fff;
  min-height: 104px;
  position: relative;
}

.subscription-modal .modal-header {
  background-color: #f9f7f1;
}

.text-start {
  font-size: 17px;
  line-height: 28px;
}

.payment-modal.modal-dialog,
.subscription-modal.modal-dialog {
  margin: 0 auto;
}

.off_tag {
  background-color: #e14d53;
  color: #fff;
  position: absolute;
  right: -10px;
  bottom: 18px;
  height: 24px;
  font-size: 17px;
  padding: 0px 10px;
  line-height: 24px;
}

.off_tag span {
  display: inline-block;
}

.off_tag span:before {
  content: '';
  border-top: 10px solid #af2b30;
  border-right: 10px solid transparent;
  position: absolute;
  right: 0;
  bottom: -10px;
}

.off_tag:before {
  content: '';
  border-top: 12px solid #e14d53;
  border-left: 10px solid transparent;
  position: absolute;
  top: 0;
  right: 100%;
}

.off_tag:after {
  content: '';
  border-bottom: 12px solid #e14d53;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: 0;
  right: 100%;
}

.free-div .sign-up-button-new {
  padding: 12px 28px;
  font-family: 'Avenir', sans-serif;
  letter-spacing: 0.1em;
  line-height: 24px;
  width: 350px;
  max-width: 100%;
}

.form-check-input:checked[type='radio'] {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.cut-price-main {
  background: linear-gradient(
    -15deg,
    transparent 47.75%,
    #e14d53 49.5%,
    #e14d53 50.5%,
    transparent 52.25%
  );
}

.payment-modal-wrapper .payment-modal {
  margin-block: 20px;
  height: calc(100% + 50px) !important;
}

.payment-modal-wrapper .payment-modal .modal-content {
  top: 0;
  transform: none;
}

.off-tag-ribbon {
  margin-top: 60px;
}

.off-tag-ribbon span {
  background-color: #e14d53;
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  height: 24px;
  display: inline-block;
  padding: 0px 5px;
  font-family: var(--jen-font-sans-serif);
  position: relative;
}

.off-tag-ribbon .left::before {
  content: '';
  border-top: 12px solid #e14d53;
  border-left: 10px solid transparent;
  position: absolute;
  top: 0;
  right: 100%;
}

.off-tag-ribbon .left::after {
  content: '';
  border-bottom: 12px solid #e14d53;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: 0;
  right: 100%;
}

.off-tag-ribbon .right::before {
  content: '';
  border-top: 12px solid #e14d53;
  border-right: 10px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
}

.off-tag-ribbon .right::after {
  content: '';
  border-bottom: 12px solid #e14d53;
  border-right: 10px solid transparent;
  position: absolute;
  bottom: 0;
  left: 100%;
}

.free-trail-text {
  width: 300px;
  margin: 5px auto 0;
  max-width: 100%;
}

.free-trail-text .offer-price {
  font-size: 32px;
}

.free-trail-text .per-year {
  font-size: 24px;
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .payment-modal .modal-body,
  .subscription-modal .modal-body {
    /* height: 300px; */
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .payment-modal {
    min-width: 656px;
  }

  .subscription-modal {
    min-width: 720px;
  }
}

@media (max-width: 1024px) {
  .payment-modal .modal-body,
  .subscription-modal .modal-body {
    flex: unset;
    padding: 0px 32px 0px 32px !important;
    padding-bottom: 8px;
  }

  .start-free-trail-btn {
    font-size: 17px;
    line-height: 24px;
  }

  .payment-modal .payment-modal-title,
  .subscription-block h3 {
    font-size: 20px;
    line-height: 27.42px;
    text-align: center;
  }

  .payment-modal .modal-content,
  .subscription-modal .modal-content {
    top: 13%;
    transform: translateY(0%);
  }

  .subscription-modal .modal-body,
  .subscription-modal .modal-footer {
    background-color: #fff;
  }

  .subscription-modal.modal-dialog {
    width: 100% !important;
  }

  .start-free-trail {
    padding-bottom: 16px;
  }

  .step-icons {
    flex: 0 0 33px;
    height: 33px;
    width: 33px;
  }

  .step-icons > img {
    padding: 0;
    width: 20px;
  }

  .subscription-block {
    padding: 0;
  }

  .create-folder-back-btn {
    left: 20px;
  }

  .subscription-modal .payment-modal-title {
    font-size: 24px;
    line-height: 28px;
  }

  .view-all-plans,
  .h-t-c {
    font-size: 16px;
    line-height: 28px;
  }

  .h-t-c {
    margin-bottom: 0;
  }

  .payment-modal .modal-content,
  .subscription-modal .modal-content {
    border-radius: none;
  }

  .payment-modal .payment-modal-title {
    margin-top: 80px;
    margin-bottom: 31px;
  }

  .trial-steps:nth-child(1) {
    margin-bottom: 17px;
  }

  .trial-steps:nth-child(3) {
    margin-bottom: 25px;
  }

  .free-trail-text {
    margin-bottom: 0;
  }

  .view-all-plans {
    margin-top: 15px;
  }

  .payment-modal .modal-footer {
    padding: 0 !important;
    margin-bottom: 32px;
  }

  .start-free-trail-footer {
    padding-top: 16px;
    margin-top: 40px;
  }

  .subscription-block h3 {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .s-d-t {
    font-weight: 500;
    /* margin-left: 23px; */
  }

  .payment-modal-wrapper .payment-modal {
    margin-block: 20px;
    height: calc(100% + 20px) !important;
  }

  .payment-modal .modal-footer {
    display: block;
  }

  .type-price + span {
    font-size: 14px;
    padding-right: 40px;
  }

  .step-text h5 {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .subscription-detail-boxs .plan-radio-group:nth-child(2),
  .subscription-detail-boxs .plan-radio-group:nth-child(3) {
    margin-top: 24px;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .modal-dialog.subscription-modal .modal-content {
    background-color: #f9f7f1;
  }

  .subscription-modal .modal-body {
    border-radius: 32px 32px 0px 0px;
  }

  .payment-modal .modal-body,
  .subscription-modal .modal-body {
    height: calc(100vh - 186px);
    overflow-y: auto;
    padding: 0px 32px 72px 32px !important;
  }

  .subscription-modal .modal-body {
    padding: 0px 24px 58px 24px !important;
  }

  .subscription-block__ h3 {
    font-size: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .subscription-detail-boxs {
    min-height: 184px;
  }

  .subscription-modal .modal-header {
    max-height: 88px;
  }

  .payment-modal .modal-content,
  .subscription-modal .modal-content {
    border-radius: 0;
    height: 100%;
    top: 0%;
    transform: translateY(0%);
  }

  .subscription-modal .close {
    display: none;
  }

  .payment-modal,
  .subscription-modal {
    min-width: 100%;
  }

  .plan-radio-group {
    width: auto;
    margin-right: 18px;
  }

  .subscription-modal .modal-content {
    overflow-y: auto;
  }

  .payment-modal,
  .subscription-modal {
    max-width: 100%;
  }

  .payment-modal .modal-footer,
  .subscription-modal .modal-footer {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1);
    background: #ffffff;
  }

  .subscription-modal .modal-footer {
    margin: 0;
  }

  .start-free-trail-btn {
    min-width: initial;
    width: 100%;
  }

  .subscription-detail-boxs .plan-radio-group:nth-child(1),
  .subscription-detail-boxs .plan-radio-group:nth-child(2),
  .subscription-detail-boxs .plan-radio-group:nth-child(3) {
    margin-left: 0;
    margin-right: 0;
  }

  .subscription-selector {
    margin-bottom: 0;
  }

  .payment-modal .payment-modal-title {
    margin-top: 56px;
    margin-bottom: 16px;
  }

  .payment-modal .modal-footer {
    margin-bottom: 0px;
  }

  .step-text h5 {
    margin: 0 0 4px;
  }

  .subscription-detail-boxs .plan-radio-group .form-check input {
    border: 1px solid #4fbbb3;
  }

  .free-trail-text {
    font-size: 18px;
  }

  .free-trail-text .offer-price {
    font-size: 30px;
  }

  .free-trail-text .per-year {
    font-size: 22px;
  }
}

@media (max-width: 376px) {
  .start-free-trail-btn {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .start-free-trail-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 9px;
  }

  .type-price + span {
    font-size: 14px;
    line-height: 18px;
  }

  .subscription-modal .modal-body {
    padding: 0px 12px 0px 12px !important;
  }

  .payment-modal .modal-footer,
  .subscription-modal .modal-footer {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-bottom: 0;
    margin-top: 0;
  }

  .payment-modal .modal-body,
  .subscription-modal .modal-body {
    flex: unset;
    padding: 0px 12px 0px 12px !important;
    padding-bottom: 8px;
  }

  .start-free-trail {
    padding: 14px 6px;
  }

  .payment-modal .modal-body,
  .subscription-modal .modal-body {
    height: calc(100vh - 180px);
    border-radius: 30px 30px 0px 0px;
  }
}
