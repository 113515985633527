.full-body-mobility {
  background-color: #7e9e9f;
}

.full-body-mobility .container {
  max-width: 1440px !important;
}

.full-body-mobility .banner-left {
  position: relative;
  padding: 80px 130px;
}

.full-body-mobility .sign-up-button-new {
  background: var(--primary) !important;
  color: #ffffff !important;
  margin-top: 6px !important;
  margin-left: 0 !important;
}

.full-body-div {
  width: 480px;
  max-width: 100%;
}

.full-body-div h2 {
  font-family: var(--jen-font-title);
  text-align: left;
  align-items: center;
  padding: 0px 0px 0px 0px;
  margin-bottom: 16px;
  font-style: normal;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.02rem;
  color: var(--black-russian);
}

.full-body-div p {
  font-size: 20px;
  margin-bottom: 24px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  line-height: 32px;
  color: var(--black-russian);
}

.full-body-mobility .full-body-div > h5 {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 26px;
  color: #ffffff;
  margin-right: 8px;
}

.full-body-div h3 {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
  color: #ffffff;
}

.full-body-div h4 {
  font-family: var(--jen-font-sans-serif);
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.full-body-div .img-fluid {
  height: 32px;
  width: 32px;
  margin-bottom: 26px;
  margin-right: 8px;
}

.full-body-div .d-flex .d-flex .img-fluid {
  height: 16px;
  width: 16px;
  margin-bottom: 33px;
  margin-right: 8px;
}

.text-black {
  color: #14142b;
}

.sep {
  border-right: 1px dotted #ffffff;
  color: #ffffff;
  width: 0px;
  flex: 0 0 1px;
  height: 48px;
  margin-right: 3rem;
  margin-left: 3rem;
}

.full-body-mobility .full-body-div .sign-up-button-new {
  /* width: 180px; */
  max-width: 100%;
}

.full-body-mobility img {
  object-fit: cover;
}

.full-body-price {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: 52px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.full-body-para {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 0 !important;
  margin-left: 24px;
}

.full-body-para-div {
  display: block;
}

.full-body-mobility .vid-wrapper .join-full-body-video {
  min-height: 576px;
  min-width: 100%;
  object-fit: cover;
}

.full-body-mobility .vid-wrapper .img-fluid {
  max-height: 576px;
  min-height: 576px;
}

.full-body-mobility .type-price p {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #14142b;
}

@media (min-width: 1050px) and (max-width: 1440px) {
  .full-body-mobility .banner-left {
    padding: 36px 130px;
  }
}

@media (max-width: 1024px) {
  .full-body-mobility .banner-left {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0 80px;
  }

  .full-body-div {
    padding: 30px 48px;
    max-width: 824px;
    margin: 0 auto;
  }

  .full-body-div h2 {
    font-size: 40px;
    line-height: 48px;
  }

  .full-body-div p {
    font-size: 20px;
    line-height: 32px;
  }

  .full-body-mobility .screen-wrap {
    display: flex !important;
  }

  .full-body-mobility .vid-wrapper .img-fluid {
    max-height: 672px;
    min-height: 672px;
  }
}

@media (max-width: 767px) {
  .full-body-div h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
  }

  .full-body-mobility .banner-left {
    padding: 42px 32px;
  }

  .full-body-div {
    padding: 40px 22px;
  }

  .full-body-div p {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .full-body-div h5,
  .full-body-div .img-fluid,
  .full-body-div .d-flex .d-flex .img-fluid {
    margin-bottom: 0;
  }

  .sep {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .rev-descr {
    margin-top: 16px;
  }

  .full-body-mobility {
    position: relative;
    z-index: 9;
  }

  .full-body-mobility .full-body-div .sign-up-button-new {
    margin: 0 !important;
    margin-top: 16px !important;
    width: auto;
  }

  .full-body-mobility img {
    max-height: 320px;
    display: none;
  }

  .full-body-price {
    font-size: 32px !important;
    line-height: 42px !important;
  }

  .full-body-para {
    margin-left: 12px;
    margin-top: 8px;
  }

  .full-body-para:nth-of-type(2) {
    margin-left: 2px;
  }

  .full-body-para-div {
    display: flex;
  }

  .full-body-mobility .vid-wrapper .join-full-body-video {
    display: none;
  }
}

@media (max-width: 360px) {
  .full-body-div h2 {
    font-size: 24px;
    line-height: 28px;
  }

  .full-body-mobility .banner-left {
    padding: 22px 12px;
  }
}
