.circular-images-section-row {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.circular-images-section-row:nth-child(odd) {
  flex-direction: row-reverse;
}
.circular-images-section-row > div {
  width: 50%;
}
.circular-images-text-block {
  padding: 20px;
}

@media (max-width: 767px) {
  .circular-images-section-row,
  .circular-images-section-row:nth-child(odd) {
    flex-direction: column;
  }
  .circular-images-section-row > div {
    width: 100%;
  }
  .circular-images-section .img-fluid {
    max-height: 240px;
  }
  .circular-images-section-row .text-block {
    max-width: 100%;
  }
}
