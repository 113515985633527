.join-now-wrapper {
  min-height: 80px;
  background-color: #14142b;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  padding: 12px 0;
}
.join-now-btn {
  display: none;
}
.join-now-button {
  border: 1px solid var(--primary);
  color: var(--primary);
  background: var(--black);
  border-radius: 4px;
  margin-left: 3rem;
  height: auto !important;
  min-width: unset;
}
.timer-block {
  display: flex;
}
.mobility-coupon {
  position: relative;
  border: 1px dotted white;
  background-color: #2a3535;
}
.mobility-coupon::after {
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: -8px;
  top: calc(50% - 8px);
  border: 1px dotted white;
  background-color: #14142b;
  border-radius: 100%;
}
.mobility-coupon::before {
  content: '';
  position: absolute;
  height: 16px;
  width: 8px;
  left: -8px;
  top: calc(50% - 8px);
  background-color: #14142b;
  border-radius: 100%;
  z-index: 2;
}
.mobility-coupon .coupon-code {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  cursor: pointer;
}
.coupon-code > p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}
.coupon-code > h5 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #d5b5a6;
  margin-bottom: 0;
}
.coupon-info {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  background: #192020;
  border: 1px solid #516262;
  border-radius: 0px 2px 2px 0px;
  padding: 12px 11px 12px 22px;
}

@media (max-width: 1024px) {
  .timer-block {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;
  }
  .join-now-btn {
    display: block;
    background-color: #14142b;
    color: #06bab2;
    font-size: 16px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 10px;
    border: 1px solid #06bab2;
    border-radius: 4px;
    width: 120px;
  }
  .join-now-wrapper {
    font-size: 17px;
    line-height: 24px;
  }
  .join-now-button {
    margin-left: 2rem;
  }
}

@media (max-width: 767px) {
  .coupon-info {
    font-size: 14px;
    line-height: 24px;
    padding: 8px 8px 8px 14px;
  }
  .coupon-code > p {
    font-size: 12px;
    line-height: 24px;
  }
  .coupon-code > h5 {
    font-size: 14px;
    line-height: 24px;
  }
  .mobility-page .join-now-button {
    width: 120px;
    min-height: 40px !important;
    padding: 0 12px;
  }
  .mobility-page .timer-block {
    padding: 0 0 0 16px;
  }
  .mobility-page .join-now-wrapper {
    padding: 24px 0 16px 0;
  }
  .mobility-page .mobility-div {
    margin-bottom: 12px;
  }
}
