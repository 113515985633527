.features-section-mobility {
  background: #d8b091;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.features-section-mobility h4 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 48px;
}
.features-section-mobility ul {
  list-style-image: url(../../../../static/images/challenge/icons/tick-black.svg);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.features-section-mobility li {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.features-section-mobility li p {
  margin-left: 13px;
}
.ps-tag {
  background-color: #f6dcc8;
  padding: 8px 16px;
  border-radius: 100px;
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
}
.features-section-mobility .sign-up-button-new {
  margin-bottom: 0 !important;
  padding: 16px 20px;
}

@media (max-width: 1024px) {
  .features-section-mobility {
    padding: 80px 30px;
  }
}

@media (max-width: 767px) {
  .features-section-mobility {
    padding: 40px 14px;
  }
  .features-section-mobility h4 {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  .features-section-mobility li {
    font-size: 17px;
    line-height: 24px;
  }
  .features-section-mobility ul {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .features-section-mobility li p {
    margin-left: 13px;
  }
  .ps-tag {
    padding: 8px 12px;
    font-size: 15px;
    line-height: 24px;
    margin-top: 16px;
    text-align: left;
    margin-left: 18px;
    margin-right: 18px;
    background: rgba(255, 255, 255, 0.5);
  }
  .features-section-mobility .sign-up-button-new {
    width: calc(100% - 36px);
  }
  .mobility-page .features-section-mobility .sign-up-button-new {
    margin-top: 8px !important;
  }
}
