.sample-section {
  background-color: #c3f2fe;
  padding: 80px 40px;
  text-align: center;
}
.sample-section > p {
  font-family: var(--jen-font-title);
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
.sample-section > h2 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
}
.sample-section .here-banner {
  width: 480px;
  max-width: 100%;
}
.sample-text-block {
  flex: 1;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 50%;
}
.sample-text-block li {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.sample-text-block img {
  max-width: 260px;
}
.sample-section .vid-wrapper img {
  object-fit: cover;
  min-height: 576px;
  max-height: 576px;
}

@media (max-width: 1024px) {
  .sample-text-block {
    padding: 40px 24px;
  }
}

@media (max-width: 767px) {
  .sample-section {
    flex-direction: column;
  }
  .sample-text-block {
    padding: 32px;
  }
  .sample-text-block p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;
  }
  .sample-section img {
    display: none;
  }
}
