.read-hide-ref {
  cursor: pointer;
  color: var(--primary);
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
  margin-left: 7px;
}

.desc-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.desc-list ul li {
  margin-bottom: 16px;
  font-size: 17px;
  line-height: 28px;
}
.desc-list p div {
  display: inherit;
}
