.pl45 {
  padding-left: 45px;
}

.pr45 {
  padding-right: 45px;
}

.avator {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.logo-text {
  font-family: var(--jen-font-title);
  line-height: 32px;
  cursor: pointer;
}

.dropdown-toggle.avator:after {
  display: none;
}

.dropdown-toggle.avator:focus {
  outline: none;
  box-shadow: none !important;
}

.header-search {
  width: 296px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.header-search .btn-outline-success:hover,
.header-search .btn-outline-success:active {
  background-color: transparent;
  border-color: 0;
}

.header-search .btn-outline-success:focus,
.header-search .btn-outline-success:active:focus {
  box-shadow: none;
}

.header-search button {
  background-color: transparent;
  background-image: url(../../static/images/search.png);
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  background-size: 16px;
  position: absolute;
  width: 40px;
  height: 100%;
  left: 0px;
  top: 0;
  padding: 0;
  z-index: 2;
}

.header-search input {
  position: relative;
  padding: 0.6rem 0.75rem;
  padding-left: 40px;
  padding-right: 35px;
  background-color: #eef0f5;
  border: 0;
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--jen-font-sans-serif);
}

.jen-navbar.navbar {
  padding: 0;
  z-index: 2;
  height: 80px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.025);
}

.jen-navbar.navbar .container {
  align-items: normal;
  padding-left: 30px;
}

.jen-navbar.navbar .navbar-collapse {
  align-items: inherit;
}

.navbar-light .navbar-brand {
  margin-right: 29px !important;
  margin-left: 30px;
}

.site-logo {
  width: 60px;
}

.jen-navbar.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.9);
  background-repeat: no-repeat;
  background-position: 0.25rem center;
  background-size: 15px;
  padding-left: 36px;
  padding-right: 10px;
  font-family: var(--jen-font-sans-serif);
  font-size: 18px;
  transition: ease all 1s;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 30px;
}

.jen-navbar.navbar-light .navbar-nav .nav-link:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: transparent;
  transition: ease all 1s;
}

.jen-navbar.navbar-light .navbar-nav .nav-link:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.active {
  font-weight: 600;
  background-color: #cef3f1;
  border-radius: 4px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
}

.jen-navbar.navbar-light .navbar-nav .nav-link {
  background-position: 10px center;
}

.jen-navbar.navbar-light .navbar-nav .nav-link.home {
  background-image: url(../../static/images/home-hover.svg);
}

/* .jen-navbar.navbar-light .navbar-nav .nav-link.challenge {
  background-image: url(../../static/images/challenge-hover.svg);
} */

.jen-navbar.navbar-light .navbar-nav .nav-link.folders {
  background-image: url(../../static/images/folders-hover.svg);
}

.jen-navbar.navbar-light .navbar-nav .nav-link.home:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.home.active {
  background-image: url(../../static/images/home-hover.svg);
}

/* .jen-navbar.navbar-light .navbar-nav .nav-link.challenge:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.challenge.active {
  background-image: url(../../static/images/challenge-hover.svg);
} */

.jen-navbar.navbar-light .navbar-nav .nav-link.folders:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.folders.active {
  background-image: url(../../static/images/folders-hover.svg);
}

.jen-navbar.navbar-light .navbar-nav .nav-link.podcasts,
.jen-navbar.navbar-light .navbar-nav .nav-link.podcasts:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.podcasts.active {
  background-image: url(../../static/images/mic-icon.svg);
  background-size: 18px;
}

.jen-navbar.navbar-light .navbar-nav .nav-link.blogs,
.jen-navbar.navbar-light .navbar-nav .nav-link.blogs:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.blogs.active {
  background-image: url(../../static/images/blog-icon.svg);
  background-size: 18px;
}

.jen-navbar.navbar-light .navbar-nav .nav-link.gift,
.jen-navbar.navbar-light .navbar-nav .nav-link.gift:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.gift.active {
  background-image: url(../../static/images/gift-icon.svg);
  background-size: 18px;
}
.jen-navbar.navbar-light .navbar-nav .nav-link.course,
.jen-navbar.navbar-light .navbar-nav .nav-link.course:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.course.active {
  background-image: url(../../static/images/challenge-hover.svg);
  background-size: 18px;
}

.jen-navbar.navbar-light .navbar-nav .nav-link.discover,
.jen-navbar.navbar-light .navbar-nav .nav-link.discover:hover,
.jen-navbar.navbar-light .navbar-nav .nav-link.discover.active {
  background-image: url(../../static/images/discover-icon.svg);
  background-size: 18px;
}

.searchIcon {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}

.searchIcon img {
  width: 24px;
}

.header-search .form-control {
  margin-left: 0px;
  margin-right: 0;
  font-size: 14px;
  font-family: var(--jen-font-sans-serif);
}

.header-search input.form-control {
  height: 40px;
  border: 1px solid var(--black-russian) !important;
  cursor: pointer;
}

.nav-text {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.04px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--black-russian);
}

.list-page-title {
  position: relative;
}

.goback-btn {
  position: absolute;
  left: 16px;
  z-index: 1000 !important;
}

.user-avatar-icon {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
}

.profile-image-icon {
  width: 40px;
  border-radius: 50%;
}

.logo {
  width: 50px;
}

@media (max-width: 1366px) {
  .pl45 {
    padding-left: 5px;
  }

  .pr45 {
    padding-right: 5px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1279px) {
  .jen-navbar.navbar-light .navbar-nav .nav-link {
    background-position: 7px center;
    padding-left: 30px;
    margin-right: 25px;
  }

  .jen-navbar.navbar-light .navbar-nav .nav-link .nav-text {
    font-size: 16px;
  }

  .jen-navbar.navbar-light .navbar-nav .nav-link.blogs,
  .jen-navbar.navbar-light .navbar-nav .nav-link.blogs:hover,
  .jen-navbar.navbar-light .navbar-nav .nav-link.blogs.active {
    background-size: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .jen-navbar.navbar-light .navbar-nav .nav-link {
    background-position: 3px center;
    background-size: 14px;
    padding-left: 22px;
    margin-right: 10px;
  }

  .jen-navbar.navbar-light .navbar-nav .nav-link .nav-text {
    font-size: 16px;
  }

  .jen-navbar.navbar-light .navbar-nav .nav-link.blogs,
  .jen-navbar.navbar-light .navbar-nav .nav-link.blogs:hover,
  .jen-navbar.navbar-light .navbar-nav .nav-link.blogs.active {
    background-size: 14px;
  }

  .jen-navbar.navbar-light .header-search {
    width: 230px;
  }

  .profile-page .folder-wrapper {
    width: calc(100% - 60px);
  }
}

@media (max-width: 1024px) {
  .header-search button {
    background-size: 40px;
    left: 30px;
  }

  .header-search input.form-control {
    padding-left: 100px;
    font-size: 24px;
  }

  .header-search {
    width: 100%;
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .header-search input.form-control {
    height: 96px;
    border: none !important;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
  }

  .goback-btn {
    left: 30px;
  }

  .jen-navbar.navbar {
    z-index: 3;
  }
}

@media (max-width: 991px) {
  .jen-navbar.navbar .navbar-collapse {
    position: absolute;
    width: 100%;
    left: 0;
    top: 79px;
    z-index: 3;
    background-color: #fff;
    padding: 1rem 1rem 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  .jen-navbar.navbar .container {
    align-items: center;
  }

  .navbar.jen-navbar .navbar-collapse .navbar-nav .nav-link {
    font-size: 17px;
    font-weight: 400;
    margin-block: 10px;
    margin-inline: 0;
    padding-inline: 30px;
  }

  .navbar.jen-navbar .navbar-collapse .navbar-nav .nav-link.active {
    background: transparent;
    box-shadow: none;
    font-weight: 600;
  }

  .navbar.jen-navbar .navbar-collapse .navbar-nav .nav-link.login-menu {
    background: #f9f7f1;
    padding: 12px 30px;
    border-radius: 8px;
    font-size: 17px;
    color: #06bab2;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: none;
  }

  .navbar.jen-navbar .navbar-collapse .bttm-nav-btn {
    width: 100%;
    padding: 12px 0;
  }

  .navbar.jen-navbar .navbar-collapse .bttm-nav-btn .stroke-btn {
    border-radius: 8px;
    border: 1px solid #06bab2;
    padding: 11px 16px;
    color: #06bab2;
    font-size: 17px;
    font-weight: 400;
    font-family: var(--jen-font-sans-serif);
    text-transform: uppercase;
    letter-spacing: 1.7px;
    width: 100%;
    background: transparent;
  }

  .navbar.jen-navbar .navbar-collapse .bttm-nav-btn .logout {
    font-family: var(--jen-font-sans-serif);
    font-size: 17px;
    color: #e14d53;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .header-search button {
    background-size: 16px;
    left: 0px;
  }

  .header-search input.form-control {
    padding-left: 40px;
    font-size: 14px;
  }

  .jen-navbar.navbar {
    box-shadow: 0px 10px 40px 0px #0000000f;
    height: 76px;
  }

  .navbar-light .navbar-brand {
    width: 60px;
    display: flex;
  }

  .jen-navbar.navbar-light {
    height: 70px;
  }

  .pl45 {
    padding-left: 0;
  }

  .header-search input.form-control {
    height: 48px;
    border: none !important;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.3);
  }

  .header-search {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .goback-btn {
    left: 14px;
  }

  .navbar.jen-navbar {
    z-index: 99;
  }

  .navbar.jen-navbar .navbar-collapse {
    height: calc(100% - 70px) !important;
    position: fixed;
    width: 314px;
    top: 70px;
    left: -314px;
    transition: all 0.3s ease-in-out;
    display: block;
    border-top: 1px solid #ddd;
    padding-bottom: 80px;
  }

  .navbar.jen-navbar .navbar-collapse.show {
    left: 0;
    transition: all 0.3s ease-in-out;
  }

  .navbar.jen-navbar .navbar-collapse .navbar-nav .nav-link {
    padding-inline: 5px;
  }

  .navbar.jen-navbar .navbar-collapse .navbar-nav .nav-link.login-menu {
    padding: 12px 16px;
  }

  .navbar.jen-navbar .navbar-collapse .bttm-nav-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 26px;
  }

  /* .navbar.jen-navbar .navbar-toggler {
    border: 0;
    box-shadow: none;
    width: 28px;
    height: 18px;
    position: relative;
    margin: 0 auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-right: 15px;
  }

  .navbar.jen-navbar .navbar-toggler>span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #141414;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  .navbar.jen-navbar .navbar-toggler span:nth-child(1) {
    top: 0px;
  }

  .navbar.jen-navbar .navbar-toggler span:nth-child(2),
  .navbar.jen-navbar .navbar-toggler span:nth-child(3) {
    top: 8px;
  }

  .navbar.jen-navbar .navbar-toggler span:nth-child(4) {
    top: 16px;
  }

  .navbar.jen-navbar .navbar-toggler.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }

  .navbar.jen-navbar .navbar-toggler.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  .navbar.jen-navbar .navbar-toggler.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .navbar.jen-navbar .navbar-toggler.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  } */
}

.table-4 thead tr th:nth-child(1) {
  width: 20%;
}

.table-4 thead tr th:nth-child(2) {
  width: 20%;
}

.table-4 thead tr th:nth-child(3) {
  width: 20%;
}

.table-4 tbody tr td:nth-child(1) {
  width: 20%;
}

.table-4 tbody tr td:nth-child(2) {
  width: 20%;
}

.table-4 tbody tr td:nth-child(3) {
  width: 20%;
}

.table-3 thead tr th:nth-child(1) {
  width: 20%;
}

.table-3 thead tr th:nth-child(2) {
  width: 20%;
}

.table-3 tbody tr td:nth-child(1) {
  width: 20%;
}

.table-3 tbody tr td:nth-child(2) {
  width: 20%;
}

@media (max-width: 767px) {
  .profile-image-icon {
    width: 30px;
  }

  .logo {
    margin-left: 8px;
    margin-right: 18px;
  }
}
