.footer {
  background-color: #f7f5ef;
  position: relative;
  z-index: 1;
  padding-top: 40px;
  border-bottom: 30px solid var(--primary);
}

.footer .container {
  max-width: 1153px !important;
}

.footer-bottom {
  /* background-color: var(--primary);
  min-height: 30px; */
  margin-top: 30px;
}

.footer-content h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  font-family: var(--jen-font-sans-serif);
  color: var(--black-russian);
}

.footer-content p,
.social-media-wrapper span {
  font-size: 15px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  color: var(--black-russian);
}

.footer-links li {
  margin-bottom: 5px;
}

.footer-links li:last-child {
  margin-bottom: 0;
}

.footer-links li a {
  font-family: var(--jen-font-sans-serif);
  font-size: 15px;
  line-height: 32px;
  color: #14142b;
  font-weight: 700;
  text-decoration: none;
  transition: ease all 0.5s;
}

.footer-links li a:hover {
  opacity: 0.7;
}

.social-media li {
  margin-right: 15px;
  width: 34px;
}

.social-media li:last-child {
  margin-right: 0;
}

.social-media li a {
  display: block;
}

.app-btn {
  margin-top: 30px;
}

.about-jen-div {
  display: flex;
}

.footer .sap {
  height: 88px;
  margin-right: 3rem;
  margin-left: 3rem;
}

.mtc {
  justify-content: flex-start;
}

.app-store-foot img {
  width: 120px;
}

@media (max-width: 767px) {
  /* .social-media-wrapper {
    position: absolute;
    width: 100%;
    top: calc(100%);
    left: 0;
    text-align: center;
    background-color: #f7f5ef;
  } */

  .social-media {
    justify-content: flex-start;
  }

  .mtc {
    margin-top: 80px;
  }

  .mtc img {
    height: 48px;
  }

  .footer {
    padding-top: 30px;
  }

  .about-jen-div {
    display: block;
  }

  .footer .sap {
    width: 311px;
    height: 1px;
    border-bottom: 1px dotted var(--black-russian);
    margin-left: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .footer-bottom {
    bottom: 0;
    position: fixed;
  }

  .social-media-wrapper {
    text-align: left;
    /* padding: 0 32px; */
    padding-bottom: 32px;
  }

  .social-media li {
    margin-right: 27px;
  }

  .app-store-foot img {
    width: 200px;
    height: auto !important;
  }
}
