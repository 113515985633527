.modal-dialog.create-folder-model {
  width: 495px !important;
  top: 50%;
  transform: translate(0,-50%) !important;
}
.modal-dialog.trymore-folder-model {
  width: 480px !important;
}
.modal-dialog.create-folder-model .close {
  background-color: var(--white);
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.modal-dialog.trymore-folder-model .close {
  background-color: transparent;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.modal-dialog.create-folder-model .modal-header,
.modal-dialog.trymore-folder-model .modal-header {
  padding-top: 32px !important;
}
.modal-dialog.create-folder-model .form-control {
  border: 0 !important;
  font-family: var(--jen-font-title);
  font-size: 17px;
  line-height: 24px;
  color: #12121d;
  /* color: rgba(18, 18, 29, 0.2); */
  height: 56px;
  position: relative;
  background-color: rgba(18, 18, 29, 0.05);
  border-radius: 8px !important;
}
.modal-dialog.create-folder-model .folder-select {
  margin-bottom: 28px;
}
.modal-dialog.create-folder-model .folder-select-style {
  position: relative;
  margin-top: 40px;
}
.modal-dialog.create-folder-model .select-folder .label-text {
  position: absolute;
  font-size: 15px;
  line-height: 20px;
  top: -30px;
  left: 0;
  font-family: var(--jen-font-sans-serif);
  font-display: swap;
  font-weight: 500;
  color: var(--black-russian);
}

.modal-dialog.create-folder-model .folder-select .label-text {
  color: #12121d;
  font-size: 15px;
  line-height: 20px;
  top: -30px;
  left: 0;
  font-family: var(--jen-font-sans-serif);
  font-display: swap;
  font-weight: 500;
  color: var(--black-russian);
  margin-top: 16px;
}
.modal-dialog.create-folder-model .folder-select-style:before {
  content: '';
  pointer-events: none;
  width: 50px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-dialog.create-folder-model .folder-select-style:after {
  content: '';
  pointer-events: none;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-1s2u09g-control,
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .custom-dropdown-select__control--is-focused {
  height: 56px;
  font-family: var(--jen-font-title);
  font-size: 17px;
  color: #12121d;
  background-color: #f3f3f3;
  border-radius: 16px;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-1s2u09g-control
  .custom-dropdown-select__value-container {
  padding: 2px 20px;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-1pahdxg-control {
  box-shadow: 0 0 0 0.25rem rgb(6 186 178 / 20%) !important;
  border-color: transparent;
  /* box-shadow: 0 0 1px #f00; */
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-1pahdxg-control:hover,
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-1pahdxg-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(6 186 178 / 20%) !important;
  border-color: transparent;
  /* border-color: #f00; */
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-14el2xx-placeholder {
  color: var(--black-russian);
}

.modal-dialog.create-folder-model
  .folder-select
  .form-group
  input::-webkit-input-placeholder {
  color: var(--black-russian) !important;
  opacity: 1 !important;
}

.modal-dialog.create-folder-model
  .folder-select
  .form-group
  input:-ms-input-placeholder {
  color: var(--black-russian) !important;
  opacity: 1 !important;
}

.modal-dialog.create-folder-model
  .folder-select
  .form-group
  input::placeholder {
  color: var(--black-russian) !important;
  opacity: 1 !important;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .custom-dropdown-select__menu {
  font-family: var(--jen-font-title);
  font-size: 17px;
  color: #12121d;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .custom-dropdown-select__indicator {
  background-image: url(../../static/images/select-dd-arrow.png);
  background-repeat: no-repeat;
  background-size: 14px;
  width: 46px;
  height: 56px;
  background-position: center;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .custom-dropdown-select__indicator-separator {
  display: none;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .css-1s2u09g-control {
  border: 0;
}
.modal-dialog.create-folder-model
  .folder-select
  .form-group
  .custom-dropdown-select__indicator
  svg {
  display: none;
}
.modal-dialog.create-folder-model .folder-save-btn {
  font-size: 20px;
  font-weight: 700;
  font-family: var(--jen-font-sans-serif);
  box-shadow: 0px 15px 15px rgba(19, 191, 184, 0.21);
  min-height: 56px;
  white-space: nowrap;
}
.modal-dialog.create-folder-model .folder-save-btn img {
  margin-left: 15px;
}
.modal-dialog.create-folder-model .folder-save-btn.crt-fldr {
  min-width: 230px;
  width: auto;
  padding: 11.5px 20px;
}
.modal-dialog.create-folder-model .new-folder {
  font-family: var(--jen-font-title);
  margin-top: 0px;
  color: #c59c37;
  /* font-family: var(--jen-font-sans-serif); */
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  /* margin-bottom: 36px; */
  width: unset;
  margin-bottom: 24px;
}

.modal-dialog.create-folder-model .new-folder:before {
  width: 100%;
  height: 1px;
  background-color: #c59c37;
  content: '';
  position: absolute;
  top: calc(100% + 2px);
}

.create-folder-model .modal-content {
  border-radius: 24px 24px 24px 24px;
  background-repeat: no-repeat;
  z-index: -1;
}
.trymore-folder-model .modal-content {
  border-radius: 8px;
  background-repeat: no-repeat;
  z-index: -1;
}
.create-folder-model .modal-header button span:first-child {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 0px;
  line-height: 26px;
  font-weight: 600;
  background-image: url(../../static/images/x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}
.trymore-folder-model .modal-header button span:first-child {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 0px;
  line-height: 26px;
  font-weight: 600;
  background-image: url(../../static/images/x-white.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
}
.create-folder-model .modal-body {
  padding: 0rem 32px 32px 32px !important;
}
.create-folder-model .modal-body p {
  font-family: var(--jen-font-sans-serif);
  font-size: 15px;
  font-weight: 700;
}
.folder-h2 {
  color: var(--black-russian);
  font-family: var(--jen-font-title);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: -20px;
}

.back-button {
  height: 14px;
  width: 14px;
  border-radius: 0px;
  margin-left: 0px;
  cursor: pointer;
}
.create-folder-model .modal-header {
  display: flex;
  font-size: 24px;
  font-family: var(--jen-font-title);
  line-height: 32px;
  justify-content: center;
}
.create-folder-model [class*='modal-header'] > button {
  background: unset;
  border: unset;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 15px;
  border: 1px solid #fff;
}

.trymore-folder-model [class*='modal-header'] > button {
  background: unset;
  border: unset;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 15px;
  border: 0;
}

.create-folder-model [class*='modal-header'] > button > span:last-child,
.trymore-folder-model [class*='modal-header'] > button > span:last-child {
  display: none;
}
.saved-tick {
  height: 24px;
  width: 24px;
  background: var(--primary);
  border-radius: 50%;
}
.saved-modal .modal-content {
  width: fit-content !important;
  margin: auto !important;
}
.modal-dialog.create-folder-model.saved-modal .modal-body {
  padding: 1rem 2rem !important;
}
.modal-dialog.create-folder-model.saved-modal .modal-content {
  border-radius: 8px;
}
.modal-dialog.create-folder-model.saved-modal .saved-folder h4 {
  color: var(--black-russian);
  font-family: var(--jen-font-title);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}
.modal-dialog.create-folder-model.saved-modal .saved-folder img {
  border-radius: 50%;
  box-shadow: 0px 15px 15px rgba(19, 191, 184, 0.21);
}

.create-folder-model [class*='modal-header'] > button {
  right: -55px;
  top: -35px;
}
.trymore-folder-model [class*='modal-header'] > button {
  right: -55px;
  top: -35px;
}
.select-header {
  font-family: var(--jen-font-title);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
}
.delete-folder {
  max-width: 335px;
  margin: 0 auto;
}
.delete-para {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 19px;
}
.select-folder-wrapper {
  width: 431px;
  margin: 0 auto;
}
.modal-dialog.create-folder-model .modal-content .modal-footer {
  padding: 16px 16px;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.maxWidth526 {
  max-width: 526px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .modal-dialog.trymore-folder-model {
    /* width: 311px !important; */
    width: 495px !important;
  }
  .trymore-folder-model [class*='modal-header'] > button {
    right: -51px;
    top: -38px;
  }
  .modal-dialog.create-folder-model .close {
    display: none;
  }
  .delete-para {
    margin-top: 24px;
    margin-bottom: 0;
  }
  .modal-dialog.create-folder-model {
    width: 311px !important;
  }
  .modal-dialog.trymore-folder-model {
    width: 311px !important;
  }
  .modal-dialog.create-folder-model .folder-select {
    margin-bottom: 12px;
  }
  .modal-dialog.create-folder-model .folder-select .label-text {
    margin-top: 32px;
  }
  .modal-dialog.create-folder-model .folder-save-btn {
    font-size: 20px;
    font-weight: 700;
    padding: 16px 30px;
  }
  .modal-dialog.create-folder-model .new-folder {
    margin-bottom: 40px;
  }
}

@media (max-width: 992px) {
  .modal-dialog.trymore-folder-model .modal-body {
    padding: 0px 24px 44px 24px !important;
  }
}

@media (max-width: 767px) {
  .maxWidth526 {
    margin-top: 32px;
  }
  .delete-para {
    margin-top: 16px;
    margin-bottom: 0px;
    line-height: 24px;
  }
  .trymore-folder-model [class*='modal-header'] > button {
    right: -11px;
    top: -48px;
  }
  .create-folder-model [class*='modal-header'] > button > span:last-child {
    display: block;
    font-family: var(--jen-font-sans-serif);
    color: var(--white);
    font-size: 14px;
    text-transform: uppercase;
    bottom: -38px;
    position: absolute;
    left: 50%;
    margin-left: -24.5px;
  }
  .modal-dialog.create-folder-model .close {
    left: 50%;
    bottom: -80px;
    margin-left: -22px;
    top: auto;
  }
  .modal-dialog.trymore-folder-model {
    width: 311px !important;
    /* width: 495px !important; */
  }
  .create-folder-model .modal-body {
    padding: 0px 24px 24px 24px !important;
  }
}
@media (max-width: 576px) {
  .modal-dialog.create-folder-model {
    width: 312px !important;
    margin: 0 auto;
  }
  .modal-dialog.create-folder-model .folder-select {
    margin-bottom: 16px;
  }
  .modal-dialog.create-folder-model .new-folder {
    margin-bottom: 40px;
  }
  .list-page-title.lg {
    font-size: 24px;
    line-height: 28px;
  }
  .modal-dialog.create-folder-model .folder-select.mb {
    margin-bottom: 40px;
  }
  .modal-dialog.create-folder-model .folder-select .label-text {
    margin-top: 21px;
  }
}

@media (max-width: 360px) {
  .create-folder-model .modal-header {
    font-size: 22px;
    line-height: 24px;
  }
  .trymore-folder-model .modal-content {
    width: 90%;
  }
  .modal-dialog.create-folder-model .folder-save-btn {
    font-size: 17px;
  }
  .list-page-title.lg {
    font-size: 20px;
    line-height: 24px;
  }
}
