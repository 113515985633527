.Loader-Wrap {
  z-index: 1301;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.Loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
