.auth-modal .modal-header {
  display: flex;
  justify-content: flex-end;
}
.auth-modal .modal-header button {
  background-color: transparent;
  padding: 0;
  border: 0;
}
.auth-modal .modal-content {
  /* transform: translateY(-50%);
  border-radius: 0.5rem; */
}
.auth-modal .modal-header button span:first-child {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 40px;
  line-height: 26px;
  vertical-align: top;
  color: #000;
}
.auth-modal .modal-header button span:first-child:hover {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 40px;
  line-height: 26px;
  vertical-align: top;
  color: #000;
}
.auth-modal .modal-header button span:last-child {
  display: none;
}
.Modal-body {
  /* width: 416px; */
  position: relative !important;
}

.modal-dialog {
  /* width: 316px; */
}
.modal-back-button {
  position: absolute;
  left: 25px;
  top: 18px;
  min-height: 20px;
}
.create-folder-back-btn {
  top: 37px;
  min-height: 15px;
  left: 2rem;
}

@media (max-width: 767px) {
  .Modal-body {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .modal-dialog.survey-modal,
  .modal-dialog.success-modal {
    width: 90% !important;
  }
}
