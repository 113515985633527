.modal-dialog.switch-plan-model {
  width: 495px !important;
  top: 50%;
  transform: translate(0, -50%) !important;
}
.modal-dialog.switch-plan-model .close {
  background-color: var(--white);
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.modal-dialog.switch-plan-model .modal-header {
  padding-top: 8px !important;
}
.switch-plan-model .modal-content {
  border-radius: 24px 24px 24px 24px;
  background-repeat: no-repeat;
  z-index: -1;
}
.switch-plan-model .modal-header button span:first-child {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 0px;
  line-height: 26px;
  font-weight: 600;
  background-image: url(../../static/images/x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}
.trymore-folder-model .modal-header button span:first-child {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 0px;
  line-height: 26px;
  font-weight: 600;
  background-image: url(../../static/images/x-white.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
}
.switch-plan-model .modal-body {
  padding: 32px 60px !important;
}
.switch-plan-model .modal-body p {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 32px;
  margin-left: 3rem;
  margin-right: 3rem;
}
.switch-plan-model .modal-body a {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  color: var(--primary);
  cursor: pointer;
}
.switch-h4 {
  font-family: var(--jen-font-title);
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #14142b;
  margin-bottom: 16px;
}
.switch-plan-model [class*='modal-header'] > button {
  background: unset;
  border: unset;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 15px;
  border: 1px solid #fff;
}
.switch-plan-model [class*='modal-header'] > button > span:last-child {
  display: none;
}
.switch-plan-model [class*='modal-header'] > button {
  right: -55px;
  top: -35px;
}
.switch-plan-model .start-free-trail-btn {
  margin-bottom: 1rem;
}
.color-primary {
  color: var(--primary);
}
.switch-plan-model .start-free-trail-btn {
  height: auto;
  min-height: 48px;
}
@media (max-width: 767px) {
  .switch-plan-model [class*='modal-header'] > button > span:last-child {
    display: block;
    font-family: var(--jen-font-sans-serif);
    color: var(--white);
    font-size: 14px;
    text-transform: uppercase;
    bottom: -38px;
    position: absolute;
    left: 50%;
    margin-left: -24.5px;
  }
  .modal-dialog.switch-plan-model .close {
    display: none;
  }
  .switch-plan-model .modal-body {
    padding: 24px !important;
  }
  .switch-h4 {
    font-size: 20px;
    line-height: 32px;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .switch-plan-model .modal-body p {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;
  }
  .switch-plan-model .start-free-trail-btn {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 576px) {
  .modal-dialog.switch-plan-model {
    width: 312px !important;
    margin: 0 auto;
  }
}

@media (max-width: 360px) {
  .switch-plan-model .modal-header {
    font-size: 22px;
    line-height: 24px;
  }
  .modal-dialog.switch-plan-model {
    width: 270px !important;
  }
}
