.jen-health-challenge-section {
  background-color: #14142b;
  padding: 56px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
}
.jen-health-challenge-section > h1 {
  font-family: var(--jen-font-title);
  font-size: 64px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.jen-health-challenge-section > p {
  color: #ffffff;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 32px;
}
.singup-btn {
  display: block;
  background-color: #cfd6de;
  color: #000000;
  font-family: var(--jen-font-sans-serif);
  font-size: 22px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  padding: 14px 30px;
  border: 1px solid #000000;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 32px;
}
.sign-up-button-new {
  /* margin-top: 32px; */
}
.jen-health-challenge-section .time-bubble {
  width: 72px;
  height: 72px;
}
.doc-jen-block {
  padding: 80px 15px;
  width: 635px;
}
.jen-health-price {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 64px !important;
  font-weight: 700 !important;
  line-height: 84px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff;
  margin-bottom: 0;
}
.jen-health-price-cut {
  font-family: var(--jen-font-sans-serif) !important;
  color: #ffffff;
  font-size: 32px !important;
  font-weight: 400 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-right: 8px;
  margin-bottom: 0;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    #e14d53 49.5%,
    #e14d53 50.5%,
    transparent 52.25%
  );
}
.jen-health-para {
  font-family: var(--jen-font-sans-serif) !important;
  font-size: 32px !important;
  color: #ffffff;
  font-weight: 400 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 0 !important;
  margin-left: 24px;
}
.bare-foot-para {
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}
.bare-foot-price {
  margin-top: 24px;
  font-weight: 700 !important;
  font-size: 24px !important;
  margin-bottom: 0 !important;
}
.mobility-page .jen-health-challenge-section fieldset > legend {
  background-color: #7e9e9f !important;
}
.mobility-page .jen-health-challenge-section fieldset {
  border-color: black !important;
}

@media (max-width: 1024px) {
  .jen-health-challenge-section > h1 {
    font-size: 54px;
  }
}

@media (max-width: 767px) {
  .jen-health-challenge-section {
    padding: 40px 32px 48px;
  }
  .jen-health-challenge-section > h1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0em;
  }
  .jen-health-challenge-section > p {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .singup-btn {
    font-family: var(--jen-font-sans-serif);
    font-style: normal;
    font-weight: 800;
    font-size: 15.7143px;
    line-height: 17px;
    padding: 10px 21px;
  }
  .jen-health-challenge-section .time-bubble {
    width: 56px;
    height: 56px;
  }
  .jen-health-challenge-section .time-bubble .timer-value {
    font-size: 24px;
  }
  .jen-health-challenge-section .time-bubble .timer-unit {
    font-size: 12px;
  }
  .jen-health-challenge-section > p {
    margin-bottom: 16px;
  }
  .jen-health-price {
    font-size: 32px !important;
    line-height: 42px !important;
  }
  .jen-health-para {
    margin-left: 12px;
    margin-top: 8px;
    font-size: 20px !important;
    line-height: 32px !important;
  }
  .jen-health-para:nth-of-type(2) {
    margin-left: 2px;
  }
  .jen-health-para-div {
    display: flex;
  }
  .jen-health-price-cut {
    font-size: 20px !important;
    line-height: 32px !important;
    margin-top: 8px;
    background: linear-gradient(
      to left top,
      transparent 47.75%,
      #e14d53 49.5%,
      #e14d53 50.5%,
      transparent 52.25%
    );
  }
  .bare-foot-para {
    font-size: 20px !important;
    line-height: 32px !important;
  }
  .bare-foot-price {
    font-size: 18px !important;
  }
}

@media (max-width: 360px) {
  .jen-health-challenge-section {
    padding: 40px 0 48px;
  }
  .jen-health-challenge-section > h1 {
    font-size: 20px;
    line-height: 32px;
  }
  .jen-health-challenge-section > p {
    font-size: 14px;
    line-height: 20px;
  }
  .doc-jen-block {
    padding: 44px 12px;
  }
  .doc-jen-block p:first-of-type {
    font-size: 17px;
    line-height: 24px;
  }
  .doc-jen-block p:nth-of-type(2) {
    font-size: 14px;
    line-height: 20px;
  }
}
