.greetings-section {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 760px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 40px 110px;
  background-size: 100% 100%;
  position: relative;
}
.greetings-section:before {
  content: '';
  background: linear-gradient(to top, #fff, transparent);
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
}
.greetings-section > div {
  position: relative;
}
.greetings-section > div::before {
  content: '';
  background: radial-gradient(#fff 50%, transparent 70%);
  position: absolute;
  width: 150%;
  height: 200%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.greetings-section > div * {
  position: relative;
}
.greetings-section h2 {
  font-family: var(--jen-font-title);
  font-size: 40px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 24px;
}
.greetings-section h3 {
  font-family: var(--jen-font-title);
  font-size: 64px;
  font-weight: 400;
  line-height: 75px;
  letter-spacing: -0.02em;
  text-align: center;
}
.greetings-section h3 span {
  background-image: url(../../images/circle.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--primary);
  font-family: var(--jen-font-sans-serif);
  font-style: italic;
  font-size: 46px;
  padding: 9px 15px 3px;
}
.greetings-section h4 {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 54px;
}
.greetings-section h5 {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 54px;
  margin-bottom: 20px;
}
.greetings-section p {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  width: 800px;
  margin: 0 auto 15px;
}
.low-back-pain-page .video-wrapper .offers-box fieldset {
  border: 1px solid #14142b;
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
}
.low-back-pain-page .video-wrapper .offers-box fieldset .dark {
  position: absolute;
  top: -16px;
  left: 15px;
  background: #ffffff !important;
  width: auto;
  padding: 0 6px;
}

@media screen and (max-width: 1024px) {
  .greetings-section {
    padding: 80px 40px 110px;
  }

  .greetings-section h3 {
    font-size: 42px;
    line-height: 60px;
  }
  .greetings-section h4 {
    margin-top: 34px;
  }
  .greetings-section p {
    width: 100%;
  }
  .greetings-section p br {
    display: none;
  }
  .low-back-pain-page .video-wrapper .full-body-video-h2 {
    font-size: 30px;
    line-height: 36px;
  }
}

@media screen and (max-width: 767px) {
  .greetings-section {
    max-height: 820px;
    padding: 50px 20px;
  }
  .greetings-section h2 {
    font-size: 28px;
    line-height: 20px;
  }
  .greetings-section h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .greetings-section h3 span {
    font-size: 24px;
  }
  .greetings-section h3 br {
    display: none;
  }
  .greetings-section {
    padding: 80px 40px 40px;
  }
  .greetings-section p {
    font-size: 14px;
    line-height: 24px;
  }
}
