.payment-progress {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1056;
  background-color: black;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.payment-progress-quote {
  background: linear-gradient(360deg, #ffffff 27.05%, #06bab2 114.93%);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 104px 32px 64px 32px;
  height: 100%;
}
.block {
  max-width: 432px;
}
.block > h2 {
  font-family: var(--jen-font-sans-serif);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 104px;
}
.loader {
  height: 4px;
  background-color: rgba(6, 186, 178, 0.25);
  margin-bottom: 80px;
  position: relative;
}
.loader > div {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  animation: progress-bar-fill 3s ease;
}
.block > h1 {
  font-family: var(--jen-font-title);
  font-size: 48px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--primary);
  margin-bottom: 104px;
}

@media (max-width: 1024px) {
  .payment-progress-quote {
    height: 90%;
  }
  .block > h2 {
    font-size: 42px;
    line-height: 50px;
  }
  .block > h1 {
    font-size: 62px;
    line-height: 70px;
    margin-bottom: 70px;
  }
  .block {
    max-width: 550px;
  }
}

@media (max-width: 767px) {
  .payment-progress-quote {
    height: 100%;
  }
  .block > h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .block > h1 {
    font-size: 48px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  .block {
    max-width: 313px;
  }
}
@keyframes progress-bar-fill {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
