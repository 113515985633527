fieldset > legend {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  background-color: inherit !important;
}

fieldset.dark-mode {
  border-color: black !important;
}

fieldset.light-mode {
  border-color: var(--white) !important;
}

fieldset > * [class*='dark-mode'] {
  color: black;
}

fieldset > * [class*='light-mode'] {
  color: var(--white);
}

fieldset > legend.dark-mode {
  color: black;
}

fieldset > legend.light-mode {
  color: var(--white);
}
