.modal-dialog.email-modal {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: unset;
  width: auto !important;
}
.modal-dialog.email-modal h2 {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
.email-modal .modal-content {
  max-width: 416px;
  border-radius: 8px;
  top: 20%;
  transform: translateY(-50%);
}
.email-address input {
  height: 56px;
}
.email-modal .folder-save-btn {
  margin-top: 40px;
}
.email-modal .close {
  background-color: var(--white);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  right: -55px;
  top: -35px;
  position: absolute;
  border: 1px solid #fff;
}
.email-modal .modal-header > button > span:first-child {
  display: block;
  font-family: var(--jen-font-sans-serif);
  font-size: 0px;
  line-height: 26px;
  font-weight: 600;
  background-image: url(../../static/images/x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
}
.email-modal .sr-only {
  display: none;
}
.logout-icon {
  position: absolute;
  bottom: -95px;
  background: var(--white);
  padding: 12px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media (max-width: 1024px) {
  .email-modal .modal-content {
    max-width: 495px;
    border-radius: 24px;
  }
  .email-modal .modal-content .modal-body {
    padding: 2rem;
  }
  .email-modal .folder-save-btn {
    margin-top: 48px;
  }
}

@media (max-width: 767px) {
  .email-modal .modal-content {
    max-width: 311px;
    border-radius: 16px;
  }
  .email-modal .modal-content .modal-body {
    padding: 0.5rem;
  }
  .email-modal .folder-save-btn {
    margin-top: 24px;
  }
  .modal-dialog.email-modal h2 {
    font-size: 20px;
    line-height: 32px;
  }
  .email-modal .modal-content .modal-header {
    padding: 0 0 0 !important;
  }
}
