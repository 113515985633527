@media (max-width: 1024px) {
  .mobile-navbar.navbar {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 0;
    z-index: 2;
    height: 80px;
    padding-top: 10px;
    -webkit-box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
  }
  .mobile-navbar.navbar .container {
    align-items: center;
  }
  .site-logo {
    width: 60px;
  }
  .mobile-navbar.navbar-light .navbar-nav {
    flex-direction: row;
  }
  .mobile-navbar.navbar-light .navbar-nav .nav-link {
    color: #a0a3b1;
    background-repeat: no-repeat;
    background-position: 0.25rem center;
    background-size: 21px;
    font-family: var(--jen-font-sans-serif);
    font-size: 18px;
    transition: ease all 1s;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 1rem;
    flex-direction: column;
    margin: auto;
  }
  .mobile-navbar.navbar-light .navbar-nav .nav-link:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;
    transition: ease all 1s;
  }
  .mobile-navbar.navbar-light .navbar-nav .nav-link:hover,
  .mobile-navbar.navbar-light .navbar-nav .nav-link.active {
    font-weight: 600;
  }
  .mobile-footer-img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
  }
  .profile-pic {
    border-radius: 50%;
  }
}

@media (max-width: 768px) {
  .mobile-navbar.navbar-light .navbar-nav .nav-link {
    font-size: 12px;
  }
  .mobile-footer-img {
    width: 24px;
    height: 24px;
  }
  .profile-pic {
    border-radius: 50%;
  }
}
