.react-multi-carousel-list.doctors-advice {
  overflow: inherit;
  padding: 0;
  margin: 0 -15px;
}
.doctors-advice:before {
  content: '';
  width: 50vw;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  right: 100%;
  z-index: 2;
}
.doctors-advice:after {
  content: '';
  width: 50vw;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 100%;
  z-index: 2;
}
.doctors-advice ul li {
  padding-left: 15px;
  padding-right: 15px;
}
.doctors-advice .item {
  background-color: var(--white);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  padding: 40px 30px 30px 30px;
  border-radius: 16px;
  height: 100%;
}
.doctors-advice .item .itemInner {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.doctors-advice .item .itemInner .doctor {
  width: 104px;
  height: 104px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 15px;
}
.doctors-advice .item .itemInner .advice {
  font-size: 20px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  color: var(--black-russian);
  line-height: 28px;
  text-align: left;
}
.doctors-advice .item .itemInner .name {
  font-size: 28px;
  font-family: var(--jen-font-title);
  font-weight: 400;
  color: var(--black-russian);
  line-height: 38px;
  text-align: left;
}
.doctors-advice .item .itemInner .deg {
  font-size: 17px;
  line-height: 24px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 400;
  color: var(--black-russian);
  text-align: left;
}
.doctors-advice {
  overflow: inherit;
  position: relative;
}
.doctors-advice .react-multi-carousel-dot-list {
  display: none;
}

@media (max-width: 1024px) {
  .react-multi-carousel-list.doctors-advice {
    margin-bottom: 40px;
  }

  .react-multi-carousel-list.doctors-advice:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .react-multi-carousel-list.doctors-advice {
    margin-bottom: 4px;
  }
  .doctors-advice .item .itemInner .advice {
    font-size: 17px;
  }
  .doctors-advice .item .itemInner .name {
    font-size: 20px;
    line-height: 28px;
  }
  .doctors-advice .item .itemInner .doctor {
    width: 80px;
    height: 80px;
    transform: translateX(-50%) translateY(-50%);
  }
  .doctors-advice .item {
    padding: 0 30px 30px 30px !important;
  }
}

@media (max-width: 767px) {
  .doctors-advice .item .itemInner .advice {
    font-size: 20px;
    line-height: 24px;
  }
  .doctors-advice .item .itemInner .name {
    font-size: 24px;
    line-height: 28px;
  }
  .doctors-advice .item .itemInner .deg {
    font-size: 24px;
    line-height: 28px;
  }
}
