.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-yt9ioa-option:active,
.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-yt9ioa-option:hover {
  background-color: #f00 !important;
}
.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .custom-dropdown-select__menu-list
  .css-9gakcf-option {
  background-color: transparent !important;
  color: var(--black-russian);
}

.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-1n7v3ny-option:active,
.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-1n7v3ny-option:hover,
.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-1n7v3ny-option {
  background-color: transparent !important;
  padding: 8px 42px;
}

.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-4ljt47-MenuList {
  padding-top: 16px;
  padding-bottom: 16px;
  border: 0;
}
/* 
.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-4ljt47-MenuList::-webkit-scrollbar {
  width: 8px;
}


.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-4ljt47-MenuList::-webkit-scrollbar-track {
  border-radius: 10px;
}

.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background: #a0a3b1;
  border-radius: 10px;
}

.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-4ljt47-MenuList::-webkit-scrollbar-thumb:hover {
  background: #a0a3b1;
} */
.modal-dialog.create-folder-model .select-folder .form-group .css-26l3qy-menu {
  border-radius: 16px;
  box-shadow: 0px 0px 40px rgba(57, 40, 166, 0.15);
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
}
.custom-dropdown-select__option {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 24px;
  font-weight: 400;
  color: var(--black-russian);
}
.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-yt9ioa-option {
  padding: 8px 42px;
}

.modal-dialog.create-folder-model
  .select-folder
  .form-group
  .css-9gakcf-option {
  padding: 8px 42px;
}
