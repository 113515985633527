.selfAssessment-block {
  width: 328px;
  cursor: pointer;
  box-shadow: 0px 32px 40px rgba(17, 17, 17, 0.08);
  border-radius: 16px;
}
.selfAssessment-block .video-box {
  border-radius: 18px;
  border-radius: 0px 0px 16px 16px;
  height: 100%;
  background-color: #fff;
}
.selfAssessment-block .video-box .content {
  padding: 24px;
}
.selfAssessment-block .video-box .video {
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
}
.selfAssessment-block .video-box.inactive {
  opacity: 0.5;
}
.selfAssessment-block .video-box .video [alt='poster'] {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.selfAssessment-block .video-box .video:before {
  content: '';
  width: 100%;
  height: 50%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 0.6979166666666667) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  border-radius: 18px 18px 0px 0px;
  -webkit-border-radius: 18px 18px 0px 0px;
  -moz-border-radius: 18px 18px 0px 0px;
}
.selfAssessment-block .video-box .video:after {
  content: '';
  width: 100%;
  height: 50%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 37%,
    rgba(0, 0, 0, 0.6979166666666667) 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.selfAssessment-block .video-box .video .over-content-top {
  top: 0;
  left: 0;
}
.selfAssessment-block .video-box .video .time {
  background: #14142b;
  border-radius: 3px;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  z-index: 1;
  font-family: var(--jen-font-sans-serif);
  padding: 0px 5px 1px 5px;
}
.selfAssessment-block .video-box .video .progressbar {
  height: 3px !important;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.selfAssessment-block .video-box .video .progressbar.complete {
  width: 100%;
  background-color: var(--completed);
}
.selfAssessment-block .video-box .video .progressbar.in_progress {
  width: 50%;
  background-color: var(--in-progress);
}
.selfAssessment-block .video-box .video .progressbar.not_started {
  width: 0%;
  background-color: #13bfb8;
}
.selfAssessment-block .video-box .video .completed {
  background-color: var(--completed);
  font-size: 12px;
  height: 24px;
  width: 80px;
  text-align: center;
  padding: 2px;
  font-family: var(--jen-font-sans-serif);
}
.selfAssessment-block .video-box .video .inprogress {
  background-color: var(--not-started);
  font-size: 12px;
  padding: 2px 4px;
  font-family: var(--jen-font-sans-serif);
}
.selfAssessment-block .video-box .content {
  min-height: 144px;
}
.selfAssessment-block .video-box .content h3 {
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--black-russian);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 56px;
}
.selfAssessment-block .video-box .content .best p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22.5px;
  font-family: var(--jen-font-sans-serif);
}
.selfAssessment-block .video-box .content .best2 p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  font-family: var(--jen-font-sans-serif);
}

.selfAssessment-block .video-box .content p span.org {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.selfAssessment-block .video-box .content .equip {
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.videoPlay {
  width: 26px;
  height: 26px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.self-badge {
  background-color: var(--black-russian);
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-radius: 50% !important;
  display: inline;
  vertical-align: middle;
  color: #fff;
  margin-left: 5px;
  font-weight: normal !important;
  padding: 0 !important;
  font-family: var(--jen-font-sans-serif);
  font-size: 11px !important;
  line-height: 20px !important;
  vertical-align: middle !important;
  position: absolute;
  right: 0;
  bottom: 0px;
}
.best {
  /* min-height: 46px; */
  position: relative;
  max-height: 46px;
  overflow: hidden;
  margin-bottom: 16px;
}
.best2 {
  position: relative;
  max-height: 24px;
  overflow: hidden;
  margin-bottom: 16px;
}

@media (max-width: 1024px) {
  .selfAssessment-block .video-box .content h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .selfAssessment-block .video-box .content .best p {
    font-size: 17px;
  }
}

@media (max-width: 360px) {
  .selfAssessment-block .video-box .content h3 {
    font-size: 17px;
    line-height: 20px;
    min-height: 42px;
  }
  .selfAssessment-block .video-box .content .best p {
    font-size: 14px;
  }
}
