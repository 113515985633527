.offers-box {
  display: flex;
  margin-top: 80px;
  justify-content: center;
}
.offers-box fieldset {
  border: 1px solid #14142b;
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
}
.offers-box fieldset .dark {
  position: absolute;
  top: 15px;
  left: 15px;
  width: auto;
  padding: 0 6px;
  background-color: inherit !important;
}
.offers-box fieldset .text-wrapper {
  text-align: left;
}
.offers-box .sign-up-button-new {
  min-height: 74px;
  margin: 0 0 0 24px !important;
}
.offers-box .sign-up-button-new {
  text-transform: uppercase;
  min-width: 240px;
  font-family: var(--jen-font-sans-serif);
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  background: var(--primary);
  box-shadow: 0px 15px 15px rgba(19, 191, 184, 0.21);
  border-radius: 4px;
  border: unset;
  padding: 24px;
  width: fit-content !important;
}
@media (max-width: 767px) {
  .subscription-selector {
    padding: 0 25px;
  }
  .offers-box {
    flex-direction: column;
    padding: 0 10px;
    align-items: center;
  }
  .offers-box .sign-up-button-new {
    margin: 20px 0 10px !important;
    min-width: calc(100% - 30px);
  }
}
