.sign-up-button {
  width: 290px;
  transition: ease all 1s;
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 32px;
  margin-left: 100px;
  text-align: center;
  text-decoration: none;
  font-family: var(--jen-font-sans-serif);
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: ease all 0.5s;
  box-shadow: 0px 15px 15px rgba(130, 187, 152, 0.47);
}

.sign-up-button::after {
  content: '\a';
  white-space: pre;
}
.folder-save-btn {
  height: 56px;
  width: 230px;
  transition: ease all 1s;
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 11.5px 32px;
  text-decoration: none;
  cursor: pointer;
  font-family: var(--jen-font-sans-serif);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
  margin-bottom: 0px;
}
.folder-save-btn.done {
  width: 160px;
}
.folder-cancel-btn {
  height: 36px;
  width: 80px;
  transition: ease all 1s;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  color: var(--black-russian);
  text-decoration: none;
  cursor: pointer;
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  margin-bottom: 0px;
}
.folder-delete-btn {
  height: 36px;
  width: 80px;
  float: right;
  transition: ease all 1s;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  color: #ff0000;
  text-decoration: none;
  cursor: pointer;
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  margin-bottom: 0px;
}
.sign-up-button-new {
  width: 290px;
  transition: ease all 1s;
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 13px 28px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
  transition: ease all 0.5s;
  box-shadow: 0px 10px 20px rgba(6, 186, 178, 0.2);
  font-family: var(--jen-font-sans-serif);
  font-weight: 500;
}
.sign-up-button-new.large {
  width: 352px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding: 16px 32px;
  font-family: var(--jen-font-sans-serif);
}
.name-fill-button {
  background-color: var(--primary);
  height: 56px;
  width: 352px;
  left: 256px;
  top: 584px;
  border-radius: 0px;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  border: none;
  border-radius: 5px;
  color: white;
}

.sign-up-button-new-transparent {
  width: 290px;
  transition: ease all 1s;
  background-color: transparent;
  border: 1px solid #2c3659;
  border-radius: 4px;
  color: #2c3659;
  padding: 11.5px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  transition: ease all 0.5s;
  font-family: var(--jen-font-sans-serif);
  font-weight: 500;
}

.sign-up-button-white {
  width: 290px;
  transition: ease all 1s;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  color: var(--primary);
  padding: 13px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  transition: ease all 0.5s;
  font-family: var(--jen-font-sans-serif);
  font-weight: 500;
  box-shadow: 0px 12px 8px rgba(19, 191, 184, 0.1);
}

.OtpButton {
  background-color: var(--primary);
  box-shadow: 0px 15px 15px rgba(19, 191, 184, 0.21);
  border: none;
  border-radius: 4px;
  color: white;
  font-family: var(--jen-font-sans-serif);
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  padding: 16px 26px;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: ease all 1s;
  margin-top: 32px;
  width: 100%;
  min-height: 56px;
}
.verify-button {
  width: 100%;
  align-items: center;
  background-color: var(--primary);
  min-height: 48px;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  font-family: var(--jen-font-sans-serif);
  transition: ease all 1s;
}
.verify-button:hover {
  color: #ffff;
}
.sign-up-button-new.what-next {
  border-radius: 30px;
  font-size: 17px;
  line-height: 24px;
  border: 1px solid var(--primary);
  background-color: var(--white);
  color: var(--primary);
  font-weight: 500;
  box-shadow: none;
  font-family: var(--jen-font-sans-serif);
}
.sign-up-button-new.create-plan {
  border-radius: 30px;
  font-size: 17px;
  line-height: 24px;
  box-shadow: none;
}
.survey-button {
  width: 100%;
  align-items: center;
  background-color: var(--primary);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-family: var(--jen-font-sans-serif);
  transition: ease all 0.3s;
}
.survey-button:hover {
  color: #ffff;
}
.survey-button.neck {
  min-width: 268px;
}
button:disabled {
  opacity: 0.4;
  cursor: context-menu;
}

@media (max-width: 1024px) {
  .sign-up-button-new {
    width: 316px;
    /* margin-right: 24px; */
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.1em;
    padding: 13px 20px;
  }
  .sign-up-button-new.login-in-button {
    width: 184px;
    margin-top: 0;
  }
  .folder-cancel-btn,
  .folder-delete-btn {
    height: 31px;
    font-size: 20px;
    width: auto;
  }
}
@media (max-width: 767px) {
  .sign-up-button-new,
  .sign-up-button-white {
    padding: 11.5px 15px;
    font-size: 22px;
  }
  .sign-up-button-new.create-plan,
  .sign-up-button-new.what-next {
    font-size: 14px;
    line-height: 20px;
  }
  .sign-up-button,
  .sign-up-button-new,
  .sign-up-button-new.auto {
    width: 100%;
  }
  .sign-up-button-new.large {
    width: 100%;
    font-size: 22px;
    line-height: 24px;
    font-weight: 800;
  }
  .sign-up-button-new.login-in-button {
    width: 100%;
    margin-top: 20px;
  }
  .OtpButton {
    padding: 16px 6px;
    margin-top: 40px;
  }
  .name-fill-button {
    letter-spacing: 0.1em;
  }
  .folder-cancel-btn {
    width: 85px;
  }
  .folder-delete-btn {
    width: 85px;
  }
  .sign-up-button {
    padding: 11.5px 32px;
  }
  .survey-button.neck {
    min-width: 253px;
  }
  .sign-up-button-white {
    width: 245px;
  }
  .sign-up-button-new,
  .sign-up-button-white {
    padding: 11.5px 5px;
    font-size: 18px;
  }
  .folder-save-btn {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding: 11.5px 26px;
  }
}

@media (max-width: 360px) {
  .OtpButton {
    padding: 10px 1px;
    font-size: 17px;
    line-height: 20px;
  }
  .survey-button {
    width: 85%;
    margin-bottom: 20px;
    padding: 12px 15px;
  }
  .folder-save-btn {
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    padding: 11.5px 20px;
  }
}
