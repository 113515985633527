.plan-begins {
  background: #5e848d;
  padding: 80px;
}
.plan-begins fieldset {
  background: #5e848d;
}
.plan-begins .pb-head {
  color: #fff;
  font-size: 32px;
  width: 635px;
  margin: 0 auto 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 500;
}
.plan-begins .pb-head p {
  margin: 0 18px 40px 0;
  color: black;
}
.upper-back-and-neck-page .plan-begins .pb-head p {
  font-family: var(--jen-font-sans-serif);
}
.plan-begins .pb-head h3 {
  font-size: 180px;
  line-height: 140px;
  text-transform: uppercase;
  font-weight: 900;
  text-shadow: 0 10px 32px #7c97ac;
  position: relative;
}
.plan-begins .pb-head h3 img {
  position: absolute;
  top: -143px;
  right: -45px;
  width: 365px;
}
.plan-begins .pb-head h3 span {
  margin-left: 120px;
}
.upper-back-and-neck-page .plan-begins .pb-head h3 span {
  margin-left: 90px;
}

@media (max-width: 767px) {
  .upper-back-and-neck-page .plan-begins .pb-head h3 {
    font-size: 90px;
    line-height: 13.53px;
    margin-top: 32px;
  }
  .upper-back-and-neck-page .plan-begins .pb-head {
    width: 100%;
  }
  .upper-back-and-neck-page .plan-begins {
    padding: 32px;
  }
  .upper-back-and-neck-page .plan-begins .pb-head p {
    margin: 0 0px 31px 0;
  }
  .upper-back-and-neck-page .plan-begins .pb-head h3 span {
    margin-left: 35px;
  }
  .upper-back-and-neck-page .plan-begins .offers-box {
    margin-top: 32px;
  }
  .upper-back-and-neck-page .plan-begins .pb-head h3 img {
    width: 144px;
  }
  .upper-back-and-neck-page .plan-begins .pb-head p {
    font-family: var(--jen-font-sans-serif);
    font-size: 20px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 42px;
  }
  .upper-back-and-neck-page .plan-begins .pb-head h3 img {
    top: -108px;
    right: 25px;
  }
  .upper-back-and-neck-page .plan-begins {
    padding: 48px 24px;
  }
}
