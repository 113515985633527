.profile-page-container {
  border-radius: 10px;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  overflow: hidden;
}

.profile-page-container .profile-left {
  width: 33.483%;
  flex: 0 0 33.483%;
}

.profile-page-container .profile-right {
  width: auto;
  flex: 1;
}

.profile-page {
  padding-bottom: 50px;
}

.profile-page .folder-wrapper {
  margin-top: -220px !important;
  border-radius: 32px 32px 0 0;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.block-lists-wrapper {
  background: #fff;
  position: relative;
  border-radius: 20px;
}

.profile-form-div {
  padding-right: 56px;
  padding-left: 56px;
  padding-top: 56px;
  padding-bottom: 40px;
}

.profile-form-div .form-group {
  margin-bottom: 15px;
}

.profile-form-div .form-control {
  height: 56px;
  border: 1px solid var(--black-russian);
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

.profile-form-div .form-label {
  font-size: 15px;
  font-weight: 500;
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  line-height: 24px;
  color: var(--black-russian);
}

.profile-form-div .form-control::-webkit-input-placeholder {
  color: var(--black-russian) !important;
  opacity: 1 !important;
}

.profile-form-div .form-control:-ms-input-placeholder {
  color: var(--black-russian) !important;
  opacity: 1 !important;
}

.profile-form-div .form-control::placeholder {
  color: var(--black-russian) !important;
  opacity: 1 !important;
}

.profile-sidebar-subdiv-2 {
  background: #ffffff;
  border-radius: 0px 0px 0px 10px;
  padding-bottom: 30px;
  min-height: 380px;
}

.profile-text {
  margin: 50px;
  font-size: 21px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: '';
}

.gender .form-control-lg {
  font-size: 1rem;
}

.profile-save-btn {
  color: #ffffff;
  width: 152px;
  transition: ease all 1s;
  background-color: var(--primary) !important;
  border: none !important;
  border-radius: 5px;
  padding: 13px 16px !important;
  text-align: center;
  text-decoration: none;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px !important;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  transition: ease all 0.5s;
  box-shadow: 0px 10px 15px rgba(6, 186, 178, 0.47);
  line-height: 24px;
  height: 56px;
  text-transform: uppercase;
}

.profile-save-btn.cnf {
  padding: 9px 16px !important;
  min-width: 313px;
  height: 48px;
  text-transform: uppercase;
}

.profile-save-btn:hover {
  background-color: var(--primary) !important;
  color: #fff;
}

.profile-save-btn::after {
  content: '\a';
  white-space: pre;
}

.phone-input-group {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.phone-input-group input:disabled {
  width: 100%;
  background-color: #f4f5f7;
}

.phone-input-group .flag-dropdown {
  background-color: #f4f5f7 !important;
}

.phone-input-group [class*='selected-flag']::before {
  top: 0;
  height: 100%;
}

.help-support {
  padding-left: 49px;
  padding-top: 56px;
  padding-right: 56px;
}

.help-support h4 {
  font-size: 24px;
  line-height: 40px;
  color: var(--black-russian);
  margin-bottom: 0;
  font-weight: 500;
}

.help-support p {
  font-size: 20px;
  line-height: 40px;
  color: var(--black-russian);
  font-weight: 400;
}

.help-support a {
  font-size: 20px;
  line-height: 40px;
  color: var(--completed);
  text-decoration: none;
}

.help-support a:hover {
  text-decoration: none;
  color: var(--completed);
}

.profile-header-block {
  min-height: 88px;
  background-color: var(--bg-floral);
}

.form-control[type='tel'] {
  background-color: #fff;
}

.profile-header-block h5 {
  font-family: var(--jen-font-title);
  font-size: 24px;
  line-height: 28px;
}

.discard-changes {
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.discard-changes button {
  width: 50px;
}

.payment-block_inner {
  padding-right: 56px;
  padding-left: 56px;
  padding-top: 56px;
  padding-bottom: 40px;
}

.payment-block>h4 {
  font-weight: normal;
}

.free-trail-block {
  min-height: 160px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #13bfb8;
  box-sizing: border-box;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trial-text {
  font-family: DM Sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #00a3ed;
  width: fit-content;
  background: #e9f3f8;
  border-radius: 4px;
  padding: 4px 9px;
}

.charge-date {
  border-radius: 8px;
  background: rgba(19, 191, 108, 0.08);
  border-radius: 8px;
  width: fit-content;
  padding: 2px 9px;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #13bf6c;
}

.subscription-details-block {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  box-sizing: border-box;
  border-radius: 8px;
}

.payment-info {
  height: 96px;
  padding: 24px 32px;
}

.cancel-payment,
.change-payment {
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
}

.change-payment {
  background: #eafefd;
  box-shadow: 0px 32px 64px 0px #11111114;
  border-radius: 0px 0px 8px 8px;
  padding: 24px 32px 24px 35px;
  line-height: 24px;
  color: #07b9b1;
}

.cancel-payment {
  line-height: 28px;
  color: #ff0000;
  padding: 24px 32px 24px 23px;
  background: #ffffff;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  margin-top: 32px;
  border-radius: 8px;
  width: 100%;
  border: unset;
}

.card-number {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plan_txt {
  font-size: 15px;
  line-height: 19.53px;
  font-family: var(--jen-font-sans-serif);
  color: #14142b;
  margin: 0;
}

.color-black-russian {
  color: var(--black-russian);
}

.fw-medium {
  font-weight: 500;
}

.fz24bold {
  font-size: 24px;
  font-weight: 700;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz20medium {
  font-size: 20px;
  font-weight: 500;
}

.cancel-subscription-footer {
  padding: 0 !important;
}

.cancel-subscription-footer>div {
  min-height: 80px;
  margin: 0;
}

.cancel-subscription-btn {
  width: 50%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  border: unset;
  background: transparent;
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}

.cancel-subscription-btn:first-child {
  background: #ffefef;
  border-bottom-left-radius: 24px;
  color: #f26f5a;
}

.cancel-subscription-btn:last-child {
  background: #eefffe;
  border-bottom-right-radius: 24px;
  color: #06bab2;
}

.subscription-info {
  padding: 32px;
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 0px 0px 8px 8px;
}

.card-breaker {
  border: 1px solid #a1a4b2;
}

.fz16.bold {
  font-weight: 700;
}

.payment-note {
  background: #f5f6fa;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.payment-note>img {
  margin-right: 8px;
}

.bold {
  font-weight: 700;
}

.billing-date {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
}

.billing-amt {
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  margin-top: 36px;
}

.no-subs-div h2 {
  margin-top: 160px;
  margin-bottom: 63px;
}

.profile-page .payment-block .payment-modal-title {
  margin-bottom: 24px;
}

.profile-page .payment-block .start-free-trail-btn {
  margin-top: 24px;
}

.subs-cancelled-text {
  margin-top: 111px;
}

.subs-cancelled-text h5 {
  font-family: var(--jen-font-title);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 64px;
}

.lgt-font {
  font-weight: 400;
}

.my-orders-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 32px 56px;
  height: 100%;
  max-height: 826px;
  overflow: auto;
}

.my-orders-card {
  width: 100%;
  /* max-width: 343px; */
  background: #ffffff;
  box-shadow: 0px 32px 64px 8px rgba(17, 17, 17, 0.08);
  border-radius: 8px;
}

.my-orders-card:not(:last-child) {
  margin-bottom: 24px;
}

.my-orders-card .transaction-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background: #f9f7f1;
  border-radius: 8px 8px 0px 0px;
}

.my-orders-card .transaction-header>span {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.my-orders-card .transaction-body {
  display: flex;
  padding: 12px 24px;
  gap: 12px;
}

.my-orders-card .transaction-body .order-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin: 12px 0;
}

.my-orders-card .transaction-body .info-block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 12px 0;
  flex: 1;
}

.my-orders-card .transaction-body .info-block>p:first-child {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 13px;
}

.my-orders-card .transaction-body .info-block>p:last-child {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  margin-bottom: 0;
}

.no-deals-block {
  background: transparent;
  padding: 0;
}

.no-deals-block .cart-full p {
  font-family: var(--jen-font-sans-serif);
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 1024px) {
  .profile-page .profile-sidebar-subdiv {
    background-color: var(--bg-floral);
  }

  .profile-form-div .form-label {
    margin-bottom: 16px;
  }

  .profile-form-div .form-group {
    margin-bottom: 48px;
  }

  .profile-form-div .form-control {
    height: 96px;
    border: 1px solid var(--black-russian);
    font-family: var(--jen-font-sans-serif);
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    border-radius: 4px;
    padding-left: 27px;
  }

  .profile-header-block h5 {
    font-family: var(--jen-font-title);
    font-size: 40px;
    line-height: 48px;
  }

  .profile-page .profile-sidebar-subdiv-2 {
    background-color: #fff;
    border-radius: 48px 48px 0 0;
    margin-top: -48px;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  .profile-header-block {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 64px;
    padding-bottom: 64px;
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0;
    margin: 0 !important;
    z-index: 2;
  }

  .profile-page-container .profile-left {
    width: 100%;
    flex: 0 0 100%;
  }

  .profile-page {
    position: relative;
  }

  .profile-form-div-outer {
    margin-top: 176px;
  }

  .profile-save-btn.mobile-view {
    font-size: 32px !important;
    line-height: 22px !important;
    background-color: transparent !important;
    color: var(--black-russian);
    box-shadow: none;
    padding: 5px !important;
    position: fixed;
    top: 64px;
    right: 88px;
    font-weight: 400;
    width: auto;
    z-index: 3;
  }

  .profile-form-div-outer {
    background-color: var(--bg-floral);
  }

  .profile-form-div-outer .profile-form-div {
    padding: 88px 96px 88px 96px;
  }

  .profile-form-div {
    border-radius: 48px 48px 0 0;
    background-color: var(--white);
  }

  .profile-page-container {
    box-shadow: none;
  }

  .profile-save-btn.cnf {
    min-width: 213px;
    height: 56px;
  }

  .profile-sidebar-subdiv-2 {
    padding-top: 47px;
  }

  .profile-form-div .label-text {
    font-size: 24px;
  }

  .help-support {
    padding-left: 65px;
    padding-top: 110px;
    padding-right: 64px;
  }

  .help-support h4 {
    font-weight: 700 !important;
  }

  .help-support h4,
  .help-support p,
  .help-support a {
    font-size: 17px;
    line-height: 28px;
  }

  .profile-page .bg-floral {
    display: none;
  }

  .profile-page .folder-wrapper {
    margin: 0 !important;
  }

  .help-support-wrapper {
    background-color: var(--bg-floral);
  }

  .help-support {
    background-color: #fff;
    border-radius: 48px 48px 0 0;
    margin-top: 176px;
    padding: 96px 80px 96px 80px;
  }

  .payment-block {
    margin-top: 176px;
    background-color: var(--bg-floral);
  }

  .payment-block_inner {
    border-radius: 48px 48px 0 0;
    background-color: var(--white);
  }

  .payment-block_inner {
    padding: 96px;
  }

  .charge-date {
    font-size: 17px;
  }

  .no-subs-div h2 {
    margin-top: 295px;
    margin-bottom: 90px;
  }

  .profile-page .payment-block {
    background-color: var(--bg-floral);
  }

  .profile-page .payment-block .payment-modal-title {
    margin-top: 48px;
    margin-bottom: 32px;
  }

  .profile-page-container .profile-right {
    display: none;
  }
}

@media (max-width: 767px) {
  .payment-block_inner {
    border-radius: 32px 32px 0 0;
    background-color: var(--white);
  }

  .profile-form-div {
    border-radius: 32px 32px 0 0;
  }

  .help-support {
    background-color: #fff;
    border-radius: 32px 32px 0 0;
  }

  .profile-form-div .form-group {
    margin-bottom: 24px;
  }

  .profile-form-div .form-control {
    height: 56px;
    font-size: 17px;
  }

  .profile-form-div .label-text {
    font-size: 12px;
  }

  .profile-header-block {
    padding-bottom: 29px;
    padding-top: 26px;
  }

  .profile-header-block h5 {
    font-family: var(--jen-font-title);
    font-size: 24px;
    line-height: 28px;
  }

  .profile-page .jen-navbar {
    display: none;
  }

  .profile-page .profile-sidebar-subdiv {
    padding-bottom: 100px;
    background-color: var(--bg-floral);
  }

  .profile-page .profile-sidebar-subdiv-2 {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    margin-top: -30px;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  .profile-form-div {
    padding: 16px;
  }

  .profile-sidebar-subdiv-2 {
    min-height: 350px;
  }

  .my-profile-text {
    display: none !important;
  }

  .profile-header-block {
    padding-left: 32px;
    padding-right: 32px;
  }

  .profile-save-btn.mobile-view {
    font-size: 17px !important;
    line-height: 22px !important;
    background-color: transparent !important;
    color: var(--black-russian);
    box-shadow: none;
    padding: 5px !important;
    top: 16px;
    right: 16px;
    font-weight: 400;
    width: auto;
  }

  .profile-form-div-outer .profile-form-div {
    padding: 32px;
  }

  .profile-form-div-outer {
    margin-top: 88px;
  }

  .help-support {
    margin-top: 88px;
    padding: 55px 64px 55px 64px;
  }

  .cancel-payment {
    margin-top: 24px;
  }

  .payment-block {
    margin-top: 88px;
  }

  .payment-block_inner {
    padding: 32px 24px 0 24px;
    z-index: 999;
  }

  .card__ {
    width: 51px;
  }

  .plan_txt {
    font-size: 14px;
  }

  .trial-text,
  .charge-date {
    font-size: 15px;
  }

  .fz24bold {
    font-size: 20px;
  }

  .fz16 {
    font-size: 14px;
  }

  .fz20medium {
    font-size: 15px;
  }

  .fz20medium {
    font-size: 17px;
  }

  .cancel-payment,
  .change-payment {
    height: auto;
    padding: 16px 20px;
    font-size: 16px;
  }

  .subscription-details-block {
    height: auto;
    min-height: initial;
  }

  .payment-info {
    padding: 16px 24px;
  }

  .subscription-info {
    padding: 24px;
  }

  .cancel-subscription-btn {
    width: 100%;
    padding: 24px;
  }

  .cancel-subscription-btn:first-child {
    border-radius: 0;
  }

  .cancel-subscription-btn:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .profile-page .payment-block {
    background-color: var(--bg-floral);
  }

  .profile-page .payment-block .start-free-trail {
    background-color: #fff;
  }

  .profile-page .payment-block .start-free-trail-btn {
    width: 81%;
  }

  .profile-page .payment-block .payment-modal-title {
    margin-top: 56px;
    margin-bottom: 16px;
  }

  .my-orders-wrapper {
    margin-top: 88px;
  }
}

@media (max-width: 360px) {
  .profile-form-div-outer .profile-form-div {
    padding: 16px;
  }

  .fz17 {
    font-size: 14px;
  }

  .fz24bold {
    font-size: 17px;
  }

  .billing-date {
    font-size: 14px;
    line-height: 17px;
  }

  .create-folder-model .modal-content {
    width: 90%;
  }

  .payment-block_inner {
    padding: 24px 12px 12px 12px;
  }

  .payment-info {
    padding: 16px 14px;
  }

  .billing-amt {
    font-size: 20px;
  }

  .profile-save-btn {
    font-size: 17px !important;
    line-height: 20px;
  }
}