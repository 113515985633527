.lead-section-row {
  display: flex;
}
.lead-section-row:nth-child(even) {
  flex-direction: row-reverse;
}
.lead-section-row > div {
  width: 50%;
}
.lead-section-row .lead-text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
}
.lead-section-row img {
  object-fit: cover;
}
.low-back-page .back-pain-section,
.low-back-pain-page .back-pain-section {
  background: #fffcf5;
}
.low-back-pain-page .back-pain-section .lead-text-block > div {
  width: 504px;
  max-width: 100%;
}
@media (max-width: 1024px) {
  .lead-section-row .lead-text-block {
    padding: 56px 40px 40px 40px;
  }
}
@media (max-width: 767px) {
  .lead-section-row,
  .lead-section-row:nth-child(even) {
    flex-direction: column;
  }
  .lead-section-row > div {
    width: 100%;
  }
  .lead-section-row .lead-text-block {
    padding: 56px 20px 20px 20px;
    max-width: 100%;
  }
}
