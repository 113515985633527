.off-canvas {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  z-index: 99;
  display: flex;
}

.off-canvas-backdrop {
  background: linear-gradient(
    0deg,
    rgba(20, 20, 43, 0.8),
    rgba(20, 20, 43, 0.8)
  );
  flex: 1;
}

.off-canvas-body {
  height: calc(100% - 10px);
  overflow: hidden;
  max-width: 480px;
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  position: relative;
}

.off-canvas-body .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 20px 32px 0;
}

.off-canvas-title {
  font-family: 'DM Serif Display';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #14142b;
}

.off-canvas-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 32px;
}

.off-canvas-container > p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  padding-top: 8px;
}

.image-cross {
  width: 56px;
  height: 56px;
  position: absolute;
  left: -56px;
  top: 0;
  cursor: pointer;
}

.off-canvas-body .cart-icon {
  position: relative;
  background-color: transparent !important;
}

.off-canvas-body .cart-icon .items-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--primary);
  color: var(--white);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-items-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 20px 32px;
}

.off-canvas .checkout-wrapper {
  padding: 20px 32px;
  display: flex;
  gap: 8px;
}

.off-canvas .checkout-wrapper > button {
  width: 50% !important;
  text-transform: uppercase;
}

.back-icon {
  display: none;
}

.off-canvas-body .checkout-card {
  border-bottom: 1px dashed #141414;
  padding: 20px 0;
}

.off-canvas-body .checkout-card:last-child {
  border-bottom: 0;
}

.off-canvas-body .checkout-card > img {
  width: 88px;
  height: 120px;
}

.off-canvas-body .checkout-card .jen-logo {
  width: 88px;
  height: auto;
}

.off-canvas-body .checkout-card h4 {
  font-size: 20px;
  min-height: 48px;
}

.off-canvas-body .checkout-card p {
  font-size: 15px;
  margin-top: 10px;
}

.off-canvas-body .checkout-card .currency-amt {
  font-size: 17px;
  font-family: var(--jen-font-sans-serif);
}

.view-button {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: var(--primary);
  background-color: var(--white);
  border-color: var(--primary);
  border-radius: 5px;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
}

@media (max-width: 768px) {
  .back-icon {
    display: block;
  }

  .view-button {
    /* display: none; */
    padding: 10px 20px;
  }
  .sign-up-button-new {
    padding: 15px 20px !important;
  }
  .off-canvas .checkout-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px !important;
  }

  .off-canvas .checkout-wrapper > button {
    width: 100% !important;
    text-transform: uppercase;
    /* margin-bottom: 80px !important; */
  }
}
