.folder-block {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #f8f3e6;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px 12px 12px 12px;
  position: relative;
}
.folder-block .content h3 {
  right: 29.58%;
  margin: 0px 0px 8px 0px;
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: var(--black-russian);
}
.folder-block .content p {
  margin-bottom: 0;
  bottom: 24.4%;
  font-size: 20px;
  line-height: 32px;
  font-family: var(--jen-font-sans-serif);
  font-display: swap;
  font-weight: 500;
  color: var(--black-russian);
  display: flex;
  align-items: center;
}
.folder-block .folder-side-strip {
  /* position: absolute; */
  left: 2px;
  top: 0;
  background: #c59c37;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 15px;
  /* height: 100%; */
}
.dotted-btn {
  height: 29.9996337890625px;
  width: 6px;
  margin-left: 798px;
  margin-top: -88px;
  border-radius: 0px;
  cursor: pointer;
}
.folder-block img {
  cursor: pointer;
}
.folders-popover {
  max-width: 180px;
  max-height: 90px;
  background-color: var(--bs-white);
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 8px;
  z-index: 1;
}
.folders-popover img {
  margin-right: 10px;
}
.folders-popover span {
  font-size: 17px !important;
  font-weight: 500;
}
.folders-popover .popover-body > div:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 1024px) {
  .folders-popover span {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .folder-block .content h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
  .folder-block .content p {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 32px;
  }
  /* .info-block {
    margin-left: 40px;
  } */
  .bg-floral {
    height: 330px;
  }
}

@media (max-width: 767px) {
  .folder-block .content h3 {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 16px 0;
  }
  .folder-block .content p {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 20px;
  }
  .folder-block .folder-side-strip {
    width: 10px;
  }
  .dots-div img {
    width: 36px;
  }
}

@media (max-width: 767px) {
  .folder-block .content h3 {
    font-size: 17px;
    line-height: 20px;
  }
  .folder-block .content p {
    line-height: 18px;
  }
}
