html,
body,
body > #root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast--success {
  background-color: var(--primary);
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.body {
  min-height: calc(100vh - 100px);
}

.row {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.row > * {
  padding-left: 16px;
  padding-right: 16px;
}

.w-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.w-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.w-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.w-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.w-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.w-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.w-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.w-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.w-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.w-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.w-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.w-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.spinner-border {
  width: 1.2rem !important;
  height: 1.2rem !important;
  border: 0.15em solid !important;
  border-right: 0.15em solid transparent !important;
}

.bg-whisper {
  background-color: #e5e5e5;
}

.bg-hint-red {
  background-color: #f0eeec;
}

.bg-floral-white {
  background-color: var(--bg-floral);
}

.bg-white {
  background-color: #e5e5e5;
}

.bg-pale-rose {
  background-color: var(--bg-pale-rose);
}

.bg-blue {
  background-color: var(--primary);
}

.error-text {
  position: absolute;
  font-size: 13px;
  font-family: var(--jen-font-sans-serif);
}

button:hover {
  cursor: pointer;
}

.quick-tips-player .video-js .vjs-tech {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50.5% !important;
  transform: translateX(-50%);
  margin: auto;
  min-height: 59%;
  width: 100% !important;
  height: inherit !important;
  object-fit: cover;
}

@media (min-width: 1366px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px !important;
  }
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 0.25rem rgba(6, 186, 178, 0.4) !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--primary) !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(6, 186, 178, 0.2) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-h3,
.sign-in-h3,
.otp-h3 {
  color: #14142b;
  font-size: 20px;
  line-height: 32px;
  font-style: normal;
  font-weight: 400;
  font-family: var(--jen-font-sans-serif);
}

.login-h2,
.sign-in-h1,
.otp-h2 {
  color: #14142b;
  font-family: var(--jen-font-title);
  font-size: 40px;
  font-weight: 400;
  font-style: normal;
}

.otp-h3 span {
  font-weight: 700;
  float: right;
}

.modal-header {
  border-bottom: 0 !important;
}

.fz20 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

.modal-backdrop {
  background: linear-gradient(
    0deg,
    rgba(20, 20, 43, 1),
    rgba(20, 20, 43, 1)
  ) !important;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
}

.react-tel-input .country-list {
  width: 350px !important;
  padding: 0px 15px !important;
  margin-top: 2px !important;
}

.react-tel-input .country-list li {
  border-bottom: 2px dotted #ccc;
}

.react-tel-input .country-list .country {
  padding: 9px 0px !important;
}

.modal-header {
  padding: 1rem 1rem 0 !important;
}

.main {
  position: relative;
  overflow-x: hidden;
}

.circle1 {
  background: var(--bg-floral);
  width: 60vw;
  height: 60vw;
  right: -24vw;
  top: -7vw;
  z-index: 0;
}

.circle2 {
  background: rgba(130, 187, 152, 0.47);
  opacity: 0.2;
  width: 60vw;
  height: 60vw;
  right: -24vw;
  top: 33vw;
  z-index: 0;
}

.circle3 {
  width: 20vw;
  height: 20vw;
  background: rgba(130, 187, 152, 0.47);
  opacity: 0.2;
  bottom: -15vw;
  left: 1vw;
}

.react-tel-input .flag-dropdown {
  border: 1px solid var(--black-russian) !important;
  border-right: 0 !important;
  background-color: #fff !important;
  border-radius: 5px 0px 0px 5px !important;
}

.react-tel-input .form-control {
  border: 1px solid var(--black-russian) !important;
  padding-left: 78px !important;
  font-family: var(--jen-font-sans-serif);
  font-size: 17px !important;
  cursor: pointer;
}

.react-tel-input .form-control.error {
  border: 1px solid #f00 !important;
}

.react-tel-input .flag-dropdown {
  top: 2px !important;
  bottom: 2px !important;
  left: 1px !important;
  border: 0 !important;
}

::-webkit-input-placeholder {
  color: var(--black-russian) !important;
  opacity: 0.3 !important;
}

:-ms-input-placeholder {
  color: var(--black-russian) !important;
  opacity: 0.3 !important;
}

::placeholder {
  color: var(--black-russian) !important;
  opacity: 0.3 !important;
}

.react-tel-input .selected-flag .arrow {
  margin-top: -2px;
  left: 32px !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top: 6px solid #444 !important;
}

.react-tel-input .selected-flag .arrow:before {
  content: '';
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-top: 5px solid #fff !important;
  position: absolute;
  left: -4px;
  top: -7px;
}

.react-tel-input .selected-flag .arrow.up {
  margin-top: -2px;
  left: 32px !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top: 6px solid #444 !important;
  border-bottom: 0 !important;
}

.react-tel-input .selected-flag .arrow.up:before {
  content: '';
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-top: 5px solid #fff !important;
  position: absolute;
  left: -4px;
  top: -7px;
}

.react-tel-input .selected-flag {
  width: 63px !important;
}

.react-tel-input .selected-flag:before {
  content: '';
  width: 1px;
  height: 34px;
  background-color: var(--black-russian);
  position: absolute;
  left: 100%;
  top: 10px;
}

.section-title {
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 43.87px;
  color: var(--black-russian);
}

.seeAll {
  font-family: var(--jen-font-sans-serif);
  font-weight: normal;
  color: var(--primary);
  height: 40px;
  font-size: 24px;
  padding-right: 15px;
  background-image: url(./static/images/right-arrow.png);
  background-position: right 10px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 10px;
}

.seeAll:hover {
  color: var(--primary);
}

.block-lists .plan-block {
  padding-bottom: 40px;
}

.list-page-title {
  font-family: var(--jen-font-title);
  font-size: 32px;
  color: var(--black-russian);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-page-sub-title {
  font-family: var(--jen-font-title);
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: var(--black-russian);
}

.list-text {
  font-size: 20px;
  color: var(--black-russian);
  font-family: var(--jen-font-sans-serif);
}

.list-pagination .pagination .page-item {
  margin-right: 2px;
  margin-left: 2px;
}

.list-pagination .pagination .page-item a span.sr-only,
.list-pagination .pagination .page-item span.sr-only {
  display: none;
}

.list-pagination .pagination .page-item {
  margin-right: 2px;
  margin-left: 2px;
}

.list-pagination .pagination .page-item a span.sr-only,
.list-pagination .pagination .page-item span.sr-only {
  display: none;
}

.list-pagination .pagination .page-item.active .page-link {
  background-color: var(--black-russian);
  color: #fff;
  border-radius: 4px;
}

.list-pagination .pagination .page-item .page-link {
  font-family: var(--jen-font-sans-serif);
  border: 0;
  color: #6e7a95;
  font-size: 14px;
  transition: ease all 1s;
}

.list-pagination .pagination .page-item .page-link:hover {
  background-color: var(--black-russian);
  color: #fff;
  border-radius: 4px;
}

.list-pagination
  .pagination
  .page-item:first-child
  .page-link
  span:first-child {
  background-image: url(../src/static/images/prevP.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
}

.list-pagination .pagination .page-item:last-child .page-link span:first-child {
  background-image: url(../src/static/images/nextP.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
}

.list-pagination
  .pagination
  .page-item:first-child
  .page-link:hover
  span:first-child {
  background-image: url(../src/static/images/prevP-hover.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
  background-color: transparent;
}

.list-pagination
  .pagination
  .page-item:last-child
  .page-link:hover
  span:first-child {
  background-image: url(../src/static/images/nextP-hover.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
}

.list-pagination .pagination .page-item:first-child .page-link:hover {
  background-color: transparent;
}

.list-pagination .pagination .page-item:last-child .page-link:hover {
  background-color: transparent;
}

.col-breadcrumb .list-breadcrumb {
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #bcc1cd;
}

.list-breadcrumb .breadcrumb .breadcrumb-item {
  font-size: 15px;
  line-height: 24px;
  font-family: var(--jen-font-sans-serif);
  font-weight: 700;
  color: var(--primary);
}

.list-breadcrumb .breadcrumb .breadcrumb-item a {
  color: var(--primary);
  text-decoration: none;
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
  font-family: var(--jen-font-sans-serif);
}

.list-breadcrumb .breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
}

.list-breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.list-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: '' !important;
  background-image: url(../src/static/images/sap.svg);
  background-size: 7px;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 40px;
  padding-right: 0;
}

.list-breadcrumb {
  padding-top: 32px;
  padding-bottom: 32px;
}

.list-breadcrumb hr {
  background-color: #bcc1cd;
  margin-top: 20px !important;
}

.list-pagination .pagination .page-item.active .page-link {
  background-color: var(--black-russian);
  color: #fff;
  border-radius: 4px;
}

.list-pagination .pagination .page-item .page-link {
  font-family: var(--jen-font-sans-serif);
  border: 0;
  color: #6e7a95;
  font-size: 14px;
  transition: ease all 1s;
}

.list-pagination .pagination .page-item .page-link:hover {
  background-color: var(--black-russian);
  color: #fff;
  border-radius: 4px;
}

.list-pagination
  .pagination
  .page-item:first-child
  .page-link
  span:first-child {
  background-image: url(../src/static/images/prevP.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
}

.list-pagination .pagination .page-item:last-child .page-link span:first-child {
  background-image: url(../src/static/images/nextP.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
}

.list-pagination
  .pagination
  .page-item:first-child
  .page-link:hover
  span:first-child {
  background-image: url(../src/static/images/prevP-hover.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
  background-color: transparent;
}

.list-pagination
  .pagination
  .page-item:last-child
  .page-link:hover
  span:first-child {
  background-image: url(../src/static/images/nextP-hover.svg);
  background-repeat: no-repeat;
  background-position: left center;
  text-indent: -9999px;
  display: block;
  width: 20px;
}

.list-pagination .pagination .page-item:first-child .page-link:hover {
  background-color: transparent;
}

.list-pagination .pagination .page-item:last-child .page-link:hover {
  background-color: transparent;
}

.list-breadcrumb .breadcrumb {
  margin-bottom: 0;
}

.list-breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.list-breadcrumb hr {
  background-color: #bcc1cd;
}

.d_c .react-multiple-carousel__arrow,
.d_c + .video-control .react-multiple-carousel__arrow {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border-radius: 50%;
  border: 2px solid var(--primary);
  transition: ease all 1s;
  -webkit-transition: ease all 1s;
  -moz-transition: ease all 1s;
  background-color: #fff !important;
  z-index: 1000;
}

.d_c .react-multiple-carousel__arrow:hover,
.d_c + .video-control .react-multiple-carousel__arrow:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  background-color: var(--primary) !important;
  cursor: pointer;
}

.d_c .react-multiple-carousel__arrow:hover:before,
.d_c + .video-control .react-multiple-carousel__arrow:hover:before {
  color: var(--white);
}

.d_c .react-multiple-carousel__arrow--left,
.d_c + .video-control .react-multiple-carousel__arrow--left {
  left: -78px;
}

.d_c .react-multiple-carousel__arrow--left:before,
.d_c + .video-control .react-multiple-carousel__arrow--left:before {
  color: var(--primary);
}

.d_c .react-multiple-carousel__arrow--right,
.d_c + .video-control .react-multiple-carousel__arrow--right {
  right: -78px;
}

.d_c .react-multiple-carousel__arrow--right:before,
.d_c + .video-control .react-multiple-carousel__arrow--right:before {
  color: var(--primary);
}

.d_c + .video-control {
  top: 50%;
}

.page-back {
  top: 50%;
  margin-top: -10px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: not-allowed !important;
}

.status-completed {
  background-color: var(--completed) !important;
}

.status-in-progress {
  background-color: var(--in-progress) !important;
}

.status-not-started {
  background-color: var(--not-started) !important;
}

.main-body {
  display: flex;
}

.main-body > div {
  flex: 1;
}

.dm-sans {
  font-family: var(--jen-font-sans-serif);
}

.mt-92 {
  margin-top: 92px;
}

.white-border {
  border: 1px solid var(--white);
}

.tour-popover {
  border-radius: 8px;
  font-family: var(--jen-font-sans-serif);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.tour-popover .close-button {
  background-color: transparent;
  border: none;
  outline: none !important;
}

@media (min-width: 1025px) {
  .main-body {
    min-height: 90vh;
    display: flex;
  }
}

@media (max-width: 1024px) {
  .d_c .react-multiple-carousel__arrow {
    display: none;
  }

  .main-body {
    min-height: calc(100% - 80px);
    max-height: calc(100% - 100px);
    overflow-y: auto;
    flex-direction: column;
  }

  .main-body > div {
    flex: 1;
    overflow-y: auto;
  }

  .video-box-inner .video-js .vjs-control-bar {
    margin-bottom: 48px;
  }

  .section-title {
    font-size: 32px;
    line-height: 44px;
    font-family: var(--jen-font-title);
    font-weight: 500;
  }
}

@media (max-width: 991px) {
  .w-1,
  .w-2,
  .w-3,
  .w-4,
  .w-5,
  .w-6,
  .w-7,
  .w-8,
  .w-9,
  .w-10,
  .w-11,
  .w-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .main-body {
    max-height: calc(100% - 80px);
  }

  .seeAll {
    font-size: 18px;
    font-weight: bold;
  }

  .d_c .react-multiple-carousel__arrow--left {
    left: 0px;
  }

  .d_c .react-multiple-carousel__arrow--right {
    right: 0px;
  }
}

@media (max-width: 767px) {
  .d-sm-none {
    display: none;
  }

  .otp-h2 {
    display: none;
  }

  .otp-h3 span {
    float: unset;
  }

  .circle1,
  .circle3 {
    display: none;
  }

  .circle2 {
    width: 200vw;
    height: 200vw;
    right: -81vw;
    top: -125vw;
  }

  .mobility .section-title {
    font-size: 17px;
    line-height: 28px;
  }

  .section-title {
    font-size: 32px;
    line-height: 43.87px;
  }

  .seeAll {
    font-weight: bold;
    height: 20px;
    font-size: 18px;
    padding-right: 10px;
    background-position: right 8px;
    background-size: 7px;
  }

  .list-page-title {
    font-size: 24px;
    line-height: 28px;
  }

  .list-text {
    font-size: 17px;
  }

  .d_c .react-multiple-carousel__arrow {
    display: none;
  }

  .list-breadcrumb .breadcrumb .breadcrumb-item {
    display: none;
  }

  .otp-h3 {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .video-box-inner .video-js .vjs-control-bar {
    margin-bottom: 31px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .vjs-has-started .vjs-control-bar,
  .vjs-audio-only-mode .vjs-control-bar {
    margin-bottom: 23px;
  }
}

@media (max-width: 360px) {
  .fz20 {
    font-size: 16px;
    line-height: 20px;
  }

  .otp-h3 {
    font-size: 14px;
    line-height: 18px;
  }

  .section-title {
    font-size: 20px;
    line-height: 24px;
  }
}

.video-js button.vjs-chromecast-button {
  display: block !important;
}

/* .vjs-chromecast-button .vjs-icon-placeholder {
  background: url(/src/components/VideoPlayer/image/chromecast_idle.png) center
    center no-repeat;
  background-size: contain;
  display: inline-block;
  width: 16px;
  height: 16px;
} */

.people_saying_main .slick-track {
  display: inline-flex;
}

.people_saying_main .slick-slide {
  height: unset;
}

.people_saying_main .slick-slide > div {
  height: 100%;
}
.people_saying_main .doctors-advice {
  padding-bottom: 20px !important;
}

.people_saying_main .doctors-advice ul li {
  padding-left: 0;
  padding-right: 0;
}

.people_saying_main .slick-dots li.slick-active button {
  background: rgba(197, 156, 55, 0.2) !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.people_saying_main .slick-dots li button:before {
  content: '' !important;
  width: 8px !important;
  height: 8px !important;
  background-color: #c4c4c4 !important;
  border-radius: 50% !important;
  position: unset !important;
  display: inline-block;
}

@media (max-width: 1024px) {
  .people_saying_main .slick-arrow.slick-next,
  .people_saying_main .slick-arrow.slick-prev {
    display: none !important;
  }
}

.people_saying_main .slick-list {
  overflow: inherit !important;
}

/* .people_saying_main .slick-arrow.slick-next,
.people_saying_main .slick-arrow.slick-prev {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 2px solid var(--primary);
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: ease all 1s;
  background-color: #fff !important;
  z-index: 1000;
} */

/* .people_saying_main .slick-arrow.slick-prev:before {
  content: '\e824';
  font-size: 20px;
  color: var(--primary);
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
} */

/* .people_saying_main .slick-arrow.slick-next:before {
  content: '\e825';
  font-size: 20px;
  color: var(--primary);
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
} */

button.slick-arrow.slick-prev.slick-disabled,
button.slick-arrow.slick-next.slick-disabled {
  display: none !important;
}
@media (max-width: 768px) {
  .people_saying_main .slick-slide {
    padding: 0 15px;
  }
}
