.block-lists .folder-block {
  width: 100%;
  margin-bottom: 32px;
}
.block-lists .folder-block .content {
  padding: 32px 72px 32px 33px;
}
.bg-floral {
  background-color: var(--bg-floral);
  height: 376px;
}
.listpagetitle-gap {
  padding-top: 80px;
  padding-bottom: 32px;
}
.plan-title {
  font-family: var(--jen-font-title);
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}
.plan-para {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 23px;
}
.folder-page .folder-wrapper {
  background: #fff;
  position: relative;
  border-radius: 32px 32px 0px 0px;
  margin-top: -220px !important;
  padding: 56px 112px 60px 112px;
  display: block;
  align-items: center;
  justify-content: center;
}
.folder-wrapper {
  min-height: 366px;
}
.profile-save-btn.cnf {
  color: #ffffff;
  width: 152px;
  transition: ease all 1s;
  background-color: var(--primary) !important;
  border: none !important;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px !important;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  transition: ease all 0.5s;
  box-shadow: 0px 10px 15px rgb(6 186 178 / 47%);
  line-height: 24px;
  text-transform: uppercase;
  padding: 9px 16px !important;
  min-width: 313px;
  height: 48px;
}

@media (max-width: 1024px) {
  .folder-page .folder-wrapper {
    padding: 48px 64px;
    height: calc(100vh - 230px);
  }
  .bg-floral {
    height: 366px;
  }
  .listpagetitle-gap {
    padding-top: 64px;
    padding-bottom: 32px;
  }
  .folder-page .folder-wrapper {
    border-radius: 48px 48px 0px 0px;
  }
  .plan-para {
    font-size: 20px;
    margin-bottom: 48px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .folder-page .folder-wrapper {
    margin-top: -260px !important;
    padding: 48px 64px 48px 64px;
  }
  .folder-page .folder-wrapper .folder-add-outer .folder-add {
    align-items: center;
  }
  .create-folder-wrapper {
    height: calc(100vh - 174px);
  }
  .listpagetitle-gap {
    padding-top: 40px;
    padding-bottom: 24px;
  }
  .bg-floral {
    height: 330px;
  }
  .folder-add-outer {
    height: 100%;
    display: flex;
    padding: 0px 16px;
  }
  .folder-add-outer .folder-add {
    margin-top: -24px;
  }
  .folder-add-outer .folder-add .plan-title {
    height: initial;
  }
  .block-lists .folder-block .content {
    padding: 32px 48px;
  }
}
@media (max-width: 767px) {
  .folder-page .folder-wrapper {
    margin-top: -270px !important;
  }
  .listpagetitle-gap {
    padding-top: 40px;
    padding-bottom: 24px;
  }
  .bg-floral {
    height: 300px;
  }
  .folder-wrapper {
    min-height: 206px;
  }
  .block-lists .folder-block {
    margin-bottom: 24px;
  }
  .block-lists .folder-block .content {
    padding: 16px 12px 16px 16px;
  }
  .plan-para {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 44px;
    text-align: left;
  }
  .folder-page .folder-wrapper {
    padding: 24px;
  }
}

@media (max-width: 360px) {
  .folder-page .folder-wrapper {
    height: calc(100vh - 430px);
    border-radius: 24px 24px 0px 0px;
  }
}
