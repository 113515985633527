.modal-body {
  padding: 0rem 2rem 2rem !important;
}
.modal-dialog {
  /* width: 416px;
  height: 100% !important;
  position: relative;
  margin-left: auto;
  margin-right: auto; */
}
.modal-content {
  /* top: 50%;
  transform: translateY(-60%); */
}
.Modal-body {
  /* width: 100%;
  position: absolute; */
}
.react-tel-input .form-control {
  height: 56px;
  width: 100%;
}
@media (max-width: 480px) {
  .modal-dialog {
    /* width: 340px !important;
    margin: auto !important; */
  }
  .modal-body {
    padding: 0px 1rem 1.3rem !important;
  }
  .react-tel-input .country-list {
    width: 306px !important;
  }
}
