.lead-price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 92px;
}
.price-text-block {
  margin-left: 32px;
}
.lead-price-section > div > div {
  width: 50%;
}
@media (max-width: 1024px) {
  .lead-price-section {
    padding: 92px 30px;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .lead-price-section {
    flex-direction: column;
    padding: 32px 24px;
  }
  .price-text-block {
    max-width: 100%;
    margin-left: 0;
  }
  .lead-price-section > div > div {
    width: 100%;
  }
}
