.features-section {
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.features-section {
  background: #c3f2fe;
}
.ps-tag {
  background-color: #e8faff;
  padding: 8px 16px;
  border-radius: 100px;
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .features-section {
    padding: 80px 30px;
  }
}
@media (max-width: 767px) {
  .features-section {
    padding: 40px 14px;
    background: #c3f2fe;
  }
  .ps-tag {
    padding: 8px 12px;
    font-size: 15px;
    line-height: 24px;
    margin-top: 16px;
    text-align: left;
    margin-left: 18px;
    margin-right: 18px;
  }
}
