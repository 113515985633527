.success-modal.modal-dialog {
  width: 544px !important;
  max-width: 550px;
}
.success-modal .modal-content:before {
  content: '';
  background-color: #ede8dd;
  background-image: url(../../../../static/images/WaiListBack.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 48%;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0px 0px;
  z-index: -1;
}

.success-modal .modal-header button {
  position: absolute;
  background-color: transparent;
  padding: 0;
  border: 0;
  right: 16px;
  top: 14px;
  z-index: 2;
  cursor: pointer;
}
.success-modal .modal-header button span:last-child {
  display: none;
}
.success-modal .modal-header button span:first-child {
  display: block;
  font-size: 40px;
  line-height: 26px;
  vertical-align: top;
  color: #000;
  font-family: var(--jen-font-sans-serif);
}
.success-modal .modal-body {
  padding: 1.2rem 5rem !important;
}
.success-modal .modal-body h3 {
  font-family: var(--jen-font-title);
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 30px;
  color: #3f2c13;
}
.success-modal .modal-body img.img-fluid {
  max-height: 224px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 8px;
}
.success-modal .modal-body h2 {
  font-family: var(--jen-font-title);
  font-weight: bold;
  font-size: 32px;
}
.success-modal .modal-body p {
  font-size: 17px;
  font-weight: 500;
  color: #3f2c13;
  margin: 24px 0 10px;
}
.gift-img-box {
  min-height: 238px;
}
.gift-img {
  height: 238px !important;
  object-fit: cover;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .waitlist-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .success-modal.modal-dialog {
    width: 100% !important;
  }
  .success-modal .modal-content {
    top: 60%;
  }
  .success-modal .survey-button {
    padding: 12px 16px;
  }
}

@media (max-width: 480px) {
  .success-modal .modal-body {
    padding: 1.2rem 2rem 1.5rem !important;
  }
  .success-modal .survey-button {
    padding: 12px 10px;
    font-size: 12px;
  }
}
