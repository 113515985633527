.col-breadcrumb {
  max-width: 1120px;
  margin: 0 auto;
}
.body-col {
  width: 1045px;
  margin: 0 auto;
}
.body-col-2 {
  width: 1053px;
  margin: 0 auto;
  min-height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
}
.body-col-3 {
  width: 1120px;
  margin: 0 auto;
}
.body-col-4 {
  width: 1053px;
  margin: 0 auto;
}
.body-col_ {
  width: 1045px;
  margin: 0 auto;
}
.max-width-1013 {
  max-width: 1013px;
  margin: 0 auto;
}
.filter-section-bg {
  background-color: var(--bg-floral);
  height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.filter-btn {
  color: var(--black-russian) !important;
  border: 1px solid #000000 !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  font-family: var(--jen-font-sans-serif);
  white-space: nowrap;
  padding: 0.25rem 0.75rem !important;
  flex: 0 0 auto;
  height: 40px;
}
.filter-btn:hover {
  color: var(--black-russian) !important;
  border: 1px solid #000000 !important;
}
.result {
  border-radius: 30px;
  background-color: var(--primary);
  color: #fff;
  padding: 0.25rem 1.5rem;
  font-size: 18px;
  line-height: 32px;
  margin-right: 12px;
  font-weight: 700;
  font-family: 'Avenir', sans-serif;
}
.options-wrapper {
  /* flex: 1; */
  white-space: nowrap;
}
.filter-options-wrapper {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(177, 166, 149, 0.24);
  border-radius: 0px 0px 8px 8px;
  z-index: 1002;
  max-height: calc(100vh - 205px);
  overflow-y: auto;
  top: 0px;
  position: absolute;
  transition: ease all 0.5s;
}
.filter-options-wrapper ul li {
  width: 20%;
  flex: 0 0 20%;
}
.filter-checkbox .form-check {
  position: relative;
  padding-left: 0;
  margin-bottom: 20px;
  margin-right: 20px;
}
.filter-checkbox .form-check .form-check-input + label {
  color: var(--black-russian);
  position: relative;
  z-index: 2;
  width: 100%;
  left: 0;
  top: 0;
  font-family: var(--jen-font-sans-serif);
  font-size: 18px;
  pointer-events: none;
}
.filter-checkbox
  .form-check
  .form-check-input:checked[type='checkbox']
  + label {
  pointer-events: none;
}

.filter-checkbox .form-check .form-check-label {
  display: flex;
  align-items: center;
}
.reset {
  border: 1px solid var(--primary) !important;
  border-radius: 4px !important;
  color: var(--primary) !important;
  background-color: var(--white) !important;
  padding: 8px 24px !important;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 700;
  font-style: normal;
  transition: ease all 1s;
}
.apply {
  border: 1px solid var(--primary) !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: var(--primary) !important;
  padding: 8px 24px !important;
  font-family: var(--jen-font-sans-serif);
  font-size: 20px !important;
  line-height: 28px !important;
  font-weight: 700 !important;
  font-style: normal;
  transition: ease all 1s;
  min-width: 230px;
}
.reset:hover {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  color: #fff !important;
}
.apply:hover {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  background-color: #fff !important;
}
.close-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  line-height: 16px;
}
.filter-title {
  font-weight: 500;
  font-family: var(--jen-font-sans-serif);
  font-size: 16px;
  line-height: 48px;
  color: #a0a3b1;
}
.filter-notification {
  padding: 0;
  min-width: 30px;
}
.filter-notification .badge {
  background-color: var(--radical-red);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute !important;
  top: -8px !important;
  right: 0;
}
.sap {
  color: var(--black-russian);
  width: 0px;
  border-right: 1px dotted var(--black-russian);
  flex: 0 0 1px;
  height: 40px;
}
.footer-logo {
  width: 50px;
  flex: 0 0 50px;
}
.footer-logo img {
  margin-top: -10px;
}
.filters-result-container {
  width: 100%;
}
.back-drop {
  position: fixed;
  top: 160px;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.8;
  z-index: 1001;
}
#body-filter-model {
  /* top: 20px; */
  top: 18px;
  overflow: hidden;
}
#body-filter-model.body-filter-model-open {
  height: 100vh;
}
#body-filter-model.body-filter-model-open .filter-options-wrapper {
  animation-name: banneranimate;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.filter-name h4 {
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

@keyframes banneranimate {
  0% {
    top: -100vh;
  }

  100% {
    top: 0px;
  }
}

#body-filter-model.body-filter-model-close .filter-options-wrapper {
  animation-name: banneranimate2;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes banneranimate2 {
  0% {
    top: 0;
  }

  100% {
    top: -100vh;
  }
}

.scp {
  padding-right: 16px;
}

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  @media (min-width: 320px) {
    .scp {
      padding-right: 0px;
    }
  }
}

.body-part-options {
  border: 2px solid var(--black-russian);
  color: var(--black-russian);
  background-color: var(--bs-white);
  border-radius: 40px;
  padding: 3px 24px;
  font-family: var(--jen-font-sans-serif);
  font-size: 24px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-right: 24px;
  margin-bottom: 24px;
  cursor: pointer;
}
.body-part-options.active,
.body-part-options-no-touch:hover {
  background-color: var(--primary);
  color: var(--white) !important;
  cursor: pointer;
  border: 2px solid var(--primary);
}
.fitness-h2_ {
  font-family: var(--jen-font-title);
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: var(--black-russian);
}
.title- {
  font-weight: 400;
  color: var(--black-russian);
  font-family: var(--jen-font-sans-serif);
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 1024px) {
  .reset,
  .apply {
    padding: 9px 24px !important;
  }
  .max-width-1013 {
    max-width: 928px;
  }
  .col-breadcrumb {
    max-width: 992px;
  }
  .back-drop {
    top: 170px;
  }
  .filter-options-wrapper {
    top: 14px;
  }
  .body-col,
  .body-col-2 {
    width: 960px;
    margin: 0 auto;
  }
  .body-col-3 {
    width: 100%;
  }
  .body-col-4 {
    width: 100%;
  }
  .body-col_ {
    width: 100%;
  }
  .max-width-1013 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .body-part-options {
    padding: 6px 24px;
  }
}
@media (max-width: 992px) {
  .body-col-2 {
    width: 100%;
  }
  .max-width-1013 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 812px) {
  .filter-options-wrapper ul li {
    width: 33.333%;
    flex: 0 0 33.333%;
  }
  .filter-options-wrapper {
    top: 7px;
  }
}

@media (max-width: 767px) {
  .select-text {
    flex: 1;
    padding-right: 16px;
  }
  .clear-all-btn {
    white-space: nowrap;
    flex: 0 0 73px;
    margin-top: 32px;
  }
  .filter-options-wrapper_inner {
    padding: 32px;
  }
  .filter-section-bg {
    height: 66px;
    padding-top: 18px;
    padding-bottom: 16px;
  }
  .filter-section-bg .col-breadcrumb {
    padding-right: 0;
  }
  .filter-btn {
    font-size: 13px;
    line-height: 24px;
    padding: 0.188rem 0.75rem !important;
  }
  .result {
    padding: 0.0001rem 1.5rem;
    font-size: 15px;
    line-height: 31px;
    height: 32px;
  }
  .sap {
    height: 32px;
  }
  .filters-wrap {
    flex: 1;
    overflow-x: auto;
  }
  .filter-name h4 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .filters-wrap::-webkit-scrollbar {
    display: none;
  }
  .filters-wrap {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .filters-wrap > span {
    position: absolute;
    top: 10px;
    left: 16px;
  }
  .filters-result-container {
    margin-left: -6px;
  }
  .filter-options-wrapper ul li {
    width: 50%;
    flex: 0 0 50%;
  }
  .filter-checkbox .form-check .form-check-input + label {
    font-size: 13px;
  }
  .filter-checkbox .form-check {
    margin-bottom: 0;
  }
  .filter-title {
    line-height: 18px;
  }
  .reset {
    font-size: 16px;
  }
  .apply {
    font-size: 16px;
  }
  .filter-options-wrapper {
    padding-bottom: 50px !important;
    top: 0px;
    height: 100vh;
    max-height: calc(100vh - 0px);
    margin-left: 0px;
    margin-right: 40px;
    position: fixed;
    left: 0;
    border-radius: 0;
    width: 90%;
  }
  #body-filter-model.body-filter-model-open .filter-options-wrapper {
    animation-name: banneranimate2;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  #body-filter-model.body-filter-model-close .filter-options-wrapper {
    animation-name: banneranimate3;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes banneranimate2 {
    0% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }
  @keyframes banneranimate3 {
    0% {
      left: 0%;
    }
    100% {
      left: -100%;
    }
  }
  .body-col-2 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .col-breadcrumb {
    padding-left: 16px;
    padding-right: 16px;
  }
  .filter-btn {
    font-size: 16px !important;
  }
  .filter-btn span {
    width: 16px;
  }
  .max-width-1013 {
    padding-left: 16px;
  }
  .scp {
    padding-right: 0;
  }
  .fitness-h2_ {
    font-size: 19px;
    line-height: 28px;
  }
  .options-checkbox .form-check {
    margin-bottom: 15px;
    margin-right: 15px;
  }
  .options-checkboxes {
    padding-left: 16px;
    padding-right: 16px;
  }
  .options-checkbox .form-check .form-check-input + label {
    padding: 2px 15px;
    font-size: 16px;
  }
  .title- {
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    font-weight: 500;
  }
  .body-part-options {
    padding: 2px 15px;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 480px) {
  .back-drop {
    top: 0px;
  }
  .filter-section-bg {
    position: relative;
    height: 66px;
  }
  .filter-options-wrapper_inner {
    padding: 19px;
  }
  .filter-name h4 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    line-height: 20px;
  }
  .filter-btn {
    font-size: 13px !important;
    line-height: 20px;
  }
  .result {
    font-size: 11px;
    line-height: 32px;
  }
}
