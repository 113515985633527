.lead-video-section {
  padding: 0 20px 20px 20px;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.lead-video-section .title-text {
  margin-top: 50px;
}
.video-iframe {
  height: 592px;
  width: 100%;
}
.title-text {
  font-family: var(--jen-font-title);
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 32px;
}
@media (max-width: 1024px) {
  .lead-video-section .title-text {
    line-height: 36px;
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .lead-video-section {
    /* padding: 64px 0 0 0; */
  }
  .video-iframe {
    height: 329px;
  }
  .title-text {
    font-size: 28px;
    line-height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 24px;
  }
  .lead-video-section .title-text {
    font-size: 26px;
  }
}
