.profile-sidebar-subdiv {
  border-radius: 8px 0px 0px 0px;
  overflow: hidden;
  position: relative;
  background-image: url(../../static/images/maskGroup.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 280px;
}

.profile-points-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 3px 15px;
}

.profile-points-box img {
  width: 16px;
  margin-right: 5px;
}

.profile-points-box p {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-russian);
  margin-bottom: 0;
}

.Optimal-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(86.21deg, #af8eff 4.8%, #13bfb8 102.42%);
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 44%;
  padding: 3px 15px;
}

.Optimal-box img {
  width: 20px;
}

.Optimal-box p {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
  margin-right: 6px;
}

.profile-title {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: var(--black-russian);
  margin-top: 30px;
  margin-bottom: 30px;
}

.profile-name {
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--black-russian);
}

.avator-outer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: auto;
  position: relative;
  margin-bottom: 16px;
}

.avator-outer h3 {
  margin-top: 20px;
  margin-bottom: 50px;
}

.avator-outer .avator {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.avator>img {
  object-fit: cover;
}

.avator-outer .edit-btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  right: 0;
  top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avator-outer .edit-btn img {
  width: 10px;
}

.profile-sidebar-div {
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
}

.sidebar-link-text.nav-link {
  color: var(--black-russian);
  font-size: 20px;
  font-family: var(--jen-font-sans-serif);
  font-style: normal;
  font-weight: 800;
  display: flex;
  align-items: center;
  line-height: 24px;
  padding: 28px 32px 28px;
  transition: ease all 0.5s;
  position: relative;
  font-weight: 400;
}

.sidebar-link-text.nav-link:before {
  content: '';
  width: 4px;
  height: 100%;
  background-color: transparent;
  position: absolute;
  left: 0px;
  top: 0;
  transition: ease all 0.5s;
}

.sidebar-link-text.nav-link:hover:before,
.sidebar-link-text.nav-link.current:before {
  background-color: #06bab2;
}

.sidebar-link-text.nav-link:not(:last-child)::after {
  content: '';
  width: 20px;
  height: 20px;
  background-image: url(../../static/images/chevron-right.png);
  background-repeat: no-repeat;
  background-size: 6px;
  background-position: center;
  position: absolute;
  right: 25px;
}

.sidebar-link-text.nav-link.current,
.sidebar-link-text.nav-link:hover {
  background-color: var(--bg-floral);
  font-weight: 700;
  color: var(--black-russian);
}

.sidebar-link-text span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 6px;
  margin-right: 16px;
}

.sidebar-link-text span img {
  width: 17px;
}

.sidebar-link-text span.user {
  background-color: #e1eff1;
}

.sidebar-link-text span.crown {
  background-color: #f3f2fe;
}

.sidebar-link-text span.mail {
  background-color: #e6f6fe;
}

.sidebar-link-text span.bell {
  background-color: #f8f2fd;
}

.sidebar-link-text span.unlock {
  background-color: var(--white);
}

.sidebar-link-text span.my-orders {
  background-color: #f7f2fc;
}

.sidebar-link-text span.about-jen {
  background-color: #ebfaf9;
}

.sidebar-link-text span.about-jen>img {
  width: unset;
}

.logout {
  color: #f00 !important;
}

.sidebar-link-text.logout span img {
  width: 22px;
}

.bg-floral {
  background-color: var(--bg-floral);
  height: 376px;
}

.navbar.jen-navbar .navbar-collapse .bttm-nav-btn .logout {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  color: #E14D53;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  padding: 12px 36px;
  display: inline-block;
  width: 100%;
}

@media (max-width: 1024px) {
  .sidebar-link-text.nav-link:not(:last-child)::after {
    background-size: 16px;
  }

  .avator-outer {
    margin-bottom: 32px;
  }

  .sidebar-link-text.nav-link {
    font-size: 32px;
    line-height: 24px;
    padding: 33px 96px;
    font-family: var(--jen-font-title);
    font-weight: 400;
  }

  .profile-sidebar-subdiv {
    border-radius: 8px 0px 0px 0px;
    overflow: hidden;
    position: relative;
    background-image: url(../../static/images/maskGroup2.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
  }

  .avator-outer {
    width: 112px;
    height: 112px;
  }

  .profile-name {
    font-size: 40px;
    line-height: 32px;
    font-family: var(--jen-font-title);
    font-weight: 400;
  }

  .sidebar-link-text span {
    width: 64px;
    height: 64px;
    margin-right: 40px;
  }

  .sidebar-link-text span img {
    width: 40px;
  }

  .sidemenu-separator {
    border-top: 1px solid #e1e8e8;
    margin: 20px 90px;
  }

  .sidebar-link-text.nav-link:not(:last-child)::after {
    width: 64px;
    height: 64px;
    right: 55px;
  }

  .profile-title {
    font-family: var(--jen-font-title);
    font-size: 40px;
    line-height: 28px;
    margin-top: 64px;
    margin-bottom: 128px;
  }
}

@media (max-width: 767px) {
  .profile-title {
    font-size: 24px;
    line-height: 28px;
    font-family: var(--jen-font-title);
    margin-top: 30px;
    margin-bottom: 34px;
  }

  .profile-name {
    font-size: 24px;
    line-height: 32px;
  }

  .profile-sidebar-subdiv {
    border-radius: 8px 0px 0px 0px;
    overflow: hidden;
    position: relative;
    background-image: url(../../static/images/maskGroup.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 280px;
  }

  .profile-sidebar-div {
    box-shadow: none;
  }

  .sidebar-link-text.nav-link {
    padding: 20px 32px;
    font-size: 20px;
    line-height: 24px;
  }

  .sidebar-link-text.nav-link:not(:last-child)::after {
    right: 45px;
  }

  .sidebar-link-text.nav-link:not(:last-child)::after {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(../../static/images/chevron-right.png);
    background-repeat: no-repeat;
    background-size: 6px;
    position: absolute;
    right: 25px;
  }

  .sidemenu-separator {
    height: 1px;
    width: 100%;
    background-color: #e1e8e8;
    margin: 24px 0;
  }

  .sidebar-link-text span img {
    width: 17px;
  }

  .sidebar-link-text span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 6px;
    margin-right: 16px;
  }

  .navbar.jen-navbar .navbar-collapse .bttm-nav-btn .logout {
    padding: 0;
  }
}

@media (max-width: 360px) {
  .sidebar-link-text.nav-link {
    padding: 16px;
  }

  .sidemenu-separator {
    margin: 16px 0;
  }

  .profile-title {
    font-family: var(--jen-font-sans-serif);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  }
}