.cookieConsent {
  background: #daedff !important;
  width: 500px !important;
  flex: auto;
  color: #14152b !important;
  margin: 10px;
  border-radius: 10px;
  display: grid !important;
}
.Wrapper_btn {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
.cookie_btn {
  width: 40%;
  background: #50bab2 !important;
  font-size: 20px !important;
  border-radius: 5px !important;
  color: #ffffff !important;
}
@media screen and (max-width: 425px) {
  .cookieConsent {
    width: 300px !important;
  }
}
