.skip-step-wrapper-mobile {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0;
  z-index: 2;
  height: 80px !important;
  box-shadow: 0px -10px 10px rgb(0 0 0 / 10%);
  flex: unset;
  display: flex;
  padding: 0 24px;
  background-color: var(--white);
}
.skip-step-wrapper-mobile > button {
  flex: 1;
}
.skip-btn-shadow {
  box-shadow: 0px -8px 20px rgba(161, 164, 178, 0.15);
  padding-top: 20px;
  padding-bottom: 20px;
}
.skip-btn-shadow .folder-save-btn {
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .skip-btn-shadow {
    padding-left: 24px;
    padding-right: 24px;
  }
}
