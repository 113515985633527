.home-testimonial p {
  font-family: var(--jen-font-sans-serif);
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.name-para {
  font-family: var(--jen-font-title) !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 33px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
}

@media (max-width: 767px) {
  .home-testimonial .doctors-advice .item {
    padding: 24px !important;
    box-shadow: 0px 42px 174px 9px rgba(0, 0, 0, 0.05),
      0px 8.4px 28.275px 9px rgba(0, 0, 0, 0.03);
  }
  .name-para {
    font-size: 20px !important;
    line-height: 27px !important;
    margin-bottom: 0;
  }
  .full-body-testimonials .doctors-advice .item {
    margin-bottom: 32px;
    position: relative;
  }
  .full-body-testimonials .doctors-advice .item .position-absolute {
    right: 20px !important;
    top: -15px !important;
  }
  .full-body-testimonials {
    padding-bottom: 0;
  }
}
